import classNames from "classnames";

const TestimonialAvatar = ({ src, className, imageClasses }) => {
  return (
    <div className={classNames(className)}>
      <img
        src={src}
        alt="Avatar"
        className={classNames("w-full h-full", imageClasses)}
      />
    </div>
  );
};

export default TestimonialAvatar;
