import React, { Fragment, useEffect, useState } from "react";
import * as Yup from "yup";
// form
import { Controller, useForm, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Dialog, Transition } from "@headlessui/react";
import {
  TrashIcon,
  ArrowLeftIcon,
  CalendarIcon,
  LinkIcon,
  HeartIcon,
  CodeBracketIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import Avatar from "./Avatar";
import VideoPlaceholder from "./videoPlaceholder";
import StarRating from "./StarRating";
import Button from "./Button";
import {
  RHFTextField,
  FormProvider,
  RHFAvatar,
  RHFSingleFileUpload,
  RHFTextArea,
  RHFDatePicker,
} from "./common/RHF";
import useIsMountedRef from "./hooks/isMountedRef";
import API from "API";
import Loader from "./Loader";
import {dataUrlToFile, formatVideoURL, videoDownload} from "utils/utils";

import useVideoToFrames, {
  VideoToFramesMethod,
} from "./hooks/useVideoToFrames";
import { toast } from "react-toastify";
import { fToNow } from "utils/dateTimeFormat";
import { isString } from "lodash";
import Swal from "sweetalert2";
import TestimonialStatusBadge from "./Testimonial/TestimonialStatusBadge";
import RHFStarRating from "./common/RHF/RHFStarRating";
import VideoThumbnail from "react-video-thumbnail";
import RHFFile from "./common/RHF/RHFFile";

export default function TestimonialSidePopover({
  open,
  setOpen,
  testimonial,
  updateList,
}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="relative z-[100]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-[100vw] lg:max-w-[70vw]">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-8 shadow-xl">
                    <TestimonialForm
                      testimonial={testimonial}
                      setOpen={setOpen}
                      updateList={updateList}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const TestimonialForm = ({ testimonial, setOpen, updateList }) => {
  const HandleDelete = async () => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "info",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        customClass: {
          actions: "my-actions",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          await toast.promise(API.deleteTestimonial(testimonial), {
            pending: "Loading",
            success: {
              render({ data }) {
                setOpen(false);
                updateList();
                return data.message;
              },
            },
            error: {
              render({ data }) {
                return data.message;
              },
            },
          });
        }
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  if (!testimonial) return <Loader />;
  return (
    <>
      {/* header */}
      <div className="flex border-b border-gray-200 items-center !pt-0 p-4">
        <button
            type="button"
            className="h-9 w-9 flex items-center justify-center rounded-lg hover:bg-gray-50 relative mr-5"
            onClick={() => setOpen(false)}
        >
          <span className="absolute -inset-2.5"/>
          <span className="sr-only">Close panel</span>
          <ArrowLeftIcon
              className="h-6 w-6"
              aria-hidden="true"
          />
        </button>
        <div className="flex-auto flex-row flex algin-middle">
          <div className="h-14 w-14 ">
            <Avatar imageSrc={testimonial?.image}/>{" "}
          </div>
          <div className="ml-5">
            <div className="flex align-middle md:flex-row flex-col">
              <h1 className="md:text-3xl text:xl font-semibold font-inter">
                {testimonial?.name}
              </h1>
              <TestimonialStatusBadge status={testimonial?.status}/>
              {testimonial?.category.toLowerCase() === "invited" && (
                  <span
                      className="inline-flex justify-center items-center rounded-full md:my-auto my-1 bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 font-inter">
                  Invited
                </span>
              )}
            </div>
            <p className="md:text-md  text-xs font-inter text-gray-600">
              {testimonial?.email}
            </p>
          </div>
        </div>
        <div className=" h-auto w-14">
          <button
              type="button"
              className="relative rounded-md text-gray-800 hover:bg-gray-200  flex-none p-3 border border-gray-200"
              onClick={() => HandleDelete(testimonial)}
          >
            <span className="absolute -inset-2.5"/>
            <span className="sr-only">Close panel</span>
            <TrashIcon className="h-5 w-5 text-red-700" aria-hidden="true"/>
          </button>
        </div>
      </div>
      {/* body */}
      {testimonial.status.toLowerCase() === "pending" ? (
          <TestimonialPendingBody testimonial={testimonial}/>
      ) : (
          <TestimonialApprovedBody
              testimonial={testimonial}
              updateList={updateList}
              setOpen={setOpen}
          />
      )}
    </>
  );
};

const TestimonialPendingBody = ({testimonial}) => {
  return (
      <div className="p-4 sm:p-6">
        <div className="grid grid-cols-1 gap-4 place-content-center  border border-gray-200 rounded-md min-h-[20vh]">
          <div className="place-self-center text-center">
          <span
              className="inline-flex   text-sm items-center rounded-md bg-gray-50 px-2 py-1 font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 font-inter">
            Invited{" "}
            <b className="ml-1 text-gray-800">{fToNow(testimonial.date)}</b>
          </span>
          <h1 className="text-lg font-semibold font-inter">Pending Response</h1>
          <p className="text-xs text-gray-600 font-inter">
            {testimonial.name} hasn’t responded to your invite yet.
          </p>
        </div>
      </div>
    </div>
  );
};

const TestimonialApprovedBody = ({ testimonial, updateList,setOpen }) => {
  const isMountedRef = useIsMountedRef();
  const EditTestimonialSchema = Yup.object().shape({
    type: Yup.string().required("Type is required"),
    name: Yup.string().required("name  is required"),
    email: Yup.string().email().required("Email is required"),
    position: Yup.string().required("Job title is required"),
    stars: Yup.number().required("Rating is required"),
    date: Yup.string().required("Date is required"),
    image: Yup.mixed().required(" user image is required"),
    testimonial: Yup.string().when("type", {
      is: (value) => value === "text",
      then: () => Yup.string().required("Testimonial is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
    video: Yup.string().when("type", {
      is: (value) => value === "video",
      then: () => Yup.string().required("Video URL is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
    thumbnail: Yup.string().when("type", {
      is: (value) => value === "text",
      then: () => Yup.mixed().required("Thumbnail is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
    allthumbnails: Yup.array().when("type", {
      is: (value) => value === "video",
      then: () =>
        Yup.array()
          .of(
            Yup.object().shape({
              url: Yup.string().required("url is required"),
              isSelected: Yup.boolean().required("requird"),
            })
          )
          .test(
            "isSelected",
            "Exactly one thumbnail must be selected",
            function (value) {
              if (!value) return true; // if value is undefined or null, skip this test
              const selectedCount = value.filter(
                (thumbnail) => thumbnail.isSelected
              ).length;
              return selectedCount === 1;
            }
          ),
      otherwise: () => Yup.array().notRequired(),
    }),
  });

  const defaultValues = {
    name: testimonial.name || "",
    type: testimonial.type || testimonial.video ? "video" : "text",
    email: testimonial.email || "",
    position: testimonial.position || "",
    image: testimonial.image,
    stars: testimonial.stars || 0,
    testimonial: testimonial.testimonial || " ",
    date: testimonial.date || "",
    video: testimonial.video || "",
    transcript: testimonial.transcript || "",
    thumbnail: testimonial.thumbnail || "",
    allthumbnails: testimonial.allthumbnails || [],
    ...testimonial,
  };
  const [progress,setProgress] = useState(0);
  const methods = useForm({
    // resolver: yupResolver(EditTestimonialSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  // submitting update testimonial
  const onSubmit = async (data) => {
    try {
      if (!isString(data.image)) {
        await API.uploadTestimonialFile(testimonial, "image", data.image);
      }
      if (!isString(data.thumbnail)) {
        await API.uploadTestimonialFile(
          testimonial,
          "thumbnail",
          data.thumbnail
        );
      }
      if (!isString(data.video)) {
        await API.uploadTestimonialFile(
            testimonial,
            "video",
            data.video,
            (progressEvent) => {
              const localProgress = (progressEvent.loaded / progressEvent.total) * 50;
              setProgress(localProgress);
            },
            (progressEvent) => {
              const localProgress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
              setProgress(localProgress);
            },
        );
      }
      if (data.allthumbnails.some(el => !isString(el.url))) {
        const result = await API.uploadTestimonialFiles(
            testimonial,
            "allthumbnails",
            data.allthumbnails.map(el => el.url),
            (progressEvent) => {
              const localProgress = (progressEvent.loaded / progressEvent.total) * 50;
              setProgress(localProgress+100);
            },
            (progressEvent) => {
              const localProgress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
              setProgress(localProgress+100);
            },
        );
        data.allthumbnails = result?.paths.map((el, index) => ({
          url: el,
          isSelected: data.allthumbnails.some((el, i) => el.isSelected ? i === index : false)
        }))
      }
      const response = await API.updateTestimonial(testimonial, data);
      if (isMountedRef.current || response) {
        toast.success(response.message);
        setOpen(false)
        updateList();
      }
    } catch (error) {
      console.log(error);
      reset();
      if (isMountedRef.current) {
        setError("afterSubmit", { ...error, message: error.message });
      }
      toast.error(error.message);
    }
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 h-full  ">
      <div className="border-r border-gray-200 col-span-2    ">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-5 grid grid-cols-1 gap-4 mx-auto  w-[80%]">
            <div className="col-span-full">
              <Dialog.Title className="text-xl font-semibold leading-6 font-inter text-gray-900">
                Edit testimonial
              </Dialog.Title>
            </div>
            {errors.afterSubmit && (
                <p className="text-red-500">{errors.afterSubmit.message}</p>
            )}
            <div className="col-span-full">
              <RHFTextField name="name" label={"Full name"}/>
            </div>
            <div className="col-span-full">
              <RHFTextField name="email" label={"Email address"}/>
            </div>
            <div className="col-span-full">
              <RHFTextField name="position" label={"Job title"}/>
            </div>
            <div className="col-span-full">
              <RHFAvatar
                  name="image"
                  label="Picture"
                  buttonText="Replace image"
              />
            </div>
            <div className="col-span-full">
              {/*<p className="font-inter font-semibold font-xs">Rating</p>*/}
              {/*<Controller*/}
              {/*  name="stars"*/}
              {/*  render={({ field, formState: { errors } }) => (*/}
              {/*    <>*/}
              {/*      <StarRating*/}
              {/*        rating={field.value}*/}
              {/*        onChange={(e) => field.onChange(e)}*/}
              {/*      />*/}
              {/*      {errors.stars && (*/}
              {/*        <p className="text-red-500">{errors.stars.message}</p>*/}
              {/*      )}*/}
              {/*    </>*/}
              {/*  )}*/}
              {/*/>*/}
              <RHFStarRating
                  name="stars"
                  label="Rating"
                  starClass="w-7 h-7"
                  required
              />
            </div>
            <div className="col-span-full">
              <RHFDatePicker
                  name="date"
                  label="Date"
                  type="date"
                  icon={CalendarIcon}
              />
            </div>
            {testimonial.type === "video" ? (
                <VideoTestimonialSection testimonial={testimonial}/>
            ) : (
                <TextTestimonialSection testimonial={testimonial}/>
            )}
            <div className="col-span-full mb-5">
              {isSubmitting && progress > 0 && testimonial.type === "video" && <progress max={200} value={progress}
                         className="w-full [&::-webkit-progress-value]:bg-brand-100 [&::-webkit-progress-bar]:bg-brand-600 [&::-webkit-progress-value]:rounded-md [&::-webkit-progress-bar]:rounded-md"/>}
              <Button type="submit" variant="primary" className={"w-full"} size="lg" isLoading={isSubmitting}>
                Save
              </Button>
            </div>
          </div>
        </FormProvider>
      </div>
      <div className="col-span-1 p-5">
        <ButtonSideBar testimonial={testimonial}/>
      </div>
    </div>
  );
};

const ButtonSideBar = ({testimonial}) => {
  const HandleThankYouMail = async () => {
    try {
      Swal.fire({
        title: "Send a thank you note?",
        text: `it will be sent to the testimonial owner email  at ${testimonial.email}  as a thank you note`,
        iconHtml: '<img src="/img/hearticon.svg" class="h-20 w-20  " />',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        customClass: {
          actions: "my-actions",
          confirmButton: "order-2",
          denyButton: "order-3",
          icon: "border-none bg-transparent",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          await toast
            .promise(API.sendthankyouemail(testimonial), {
              pending: "Loading",
              success: {
                render({ data }) {
                  return data.message;
                },
              },
              error: {
                render({ data }) {
                  console.log(data);
                  return data.message;
                },
              },
            })
            .then((res) => {})
            .catch((error) => {
              console.log(error);
            });
        }
      });
    } catch (error) {
      toast.error(error.message);
    }
  };
  const buttons = [
    {
      text: "Copy link to testimonial",
      type: "default",
      bgColor: "bg-[#F2F4F7] hover:bg-gray-200",
      icon: <LinkIcon className="h-4 w-4" />,
      onClick: () => {
        // copy link to clipboard
        navigator.clipboard.writeText(
          window.location.host + "/testimonial/view/" + testimonial._id
        );
        toast.success("Link copied to clipboard");
      },
    },
    {
      text: "Send a thank you note",
      type: "default",
      bgColor: "bg-pink-100 text-pink-700 hover:bg-pink-200",
      icon: <HeartIcon className="h-4 w-4" />,
      onClick: () => HandleThankYouMail(),
    },
    {
      text: "Embed video",
      type: "video",
      bgColor: " bg-green-100 text-green-700 hover:bg-green-200",
      icon: <CodeBracketIcon className="h-4 w-4" />,
      onClick: () => {
        // copy link to testimonial
        navigator.clipboard.writeText(
          `<iframe src="${window.location.protocol}//${window.location.host}/testimonial/embed/${testimonial._id}" width="400" height="550" frameborder="0"
          allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>`
        );
        toast.success("Embed code copied to clipboard");
      },
    },
    {
      text: "Download video",
      type: "video",
      bgColor: " bg-purple-100 text-purple-700 hover:bg-purple-200",
      icon: <ArrowDownTrayIcon className="h-4 w-4" />,
      onClick: () => {
        // download video from link
        videoDownload(
          testimonial.video,
          testimonial.name + `${testimonial.video.split(".").pop()}`
        );
      },
    },
    {
      text: "Embed testimonial",
      type: "text",
      bgColor: " bg-green-100 text-green-700 hover:bg-green-200",
      icon: <CodeBracketIcon className="h-4 w-4" />,
      onClick: () => {
        navigator.clipboard.writeText(
          `<iframe src="${window.location.protocol}//${window.location.host}/testimonial/embed/${testimonial._id}" width="400" height="550" frameborder="0"
          allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>`
        );
        toast.success("Embed code copied to clipboard");
      },
    },
  ];
  return buttons
    .filter((e) => e.type === testimonial.type || e.type === "default")
    .map((button) => (
      <button
        variant="default"
        className={`flex gap-2 items-center justify-center rounded-md transition-colors text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-full py-4 border-0 p-4 my-3 ${button.bgColor}`}
        onClick={button.onClick}
      >
        {button.icon} <span>{button.text}</span>
      </button>
    ));
};

const VideoTestimonialSection = ({testimonial}) => {
  const {
    setValue,
    formState: { errors },
      watch
  } = useFormContext();

  const [newThumbnails,setNewThumbnails] = useState(false);

  const { frames, files, getFrames,error,setFrames } = useVideoToFrames();
  const [allFrames, setAllFrames] = useState(watch("allthumbnails") || []);

  const handleExtractFrames = async () => {
    let videoURl = watch("video");
    if (typeof videoURl !== "string"){
      // //New Video is uploaded, uploading video to server + refreshing thumbnails
      // let result = await API.uploadTestimonialFile(
      //     testimonial,
      //     "video",
      //     watch("video")
      // );
      // videoURl = result?.data?.file
      videoURl = URL.createObjectURL(watch("video"));
      // setNewThumbnails(true)
      setValue("allthumbnails", []);
    }
    // if (testimonial.allthumbnails.length === 0){
      // setNewThumbnails(true)
    // }
    //remove extra query params from video url
    getFrames(videoURl.split("?")[1] ? videoURl.split("?")[0] : videoURl, 3, VideoToFramesMethod.fps);
  };
  useEffect(() => {
    if (watch("video")) {
      handleExtractFrames();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("video")]);
  useEffect(() => {
    if (files.length > 0 && watch("allthumbnails").length === 0) {
      setAllFrames(files.map((el, index) => ({
        url: URL.createObjectURL(el),
        isSelected: index === 0,
      })))
      setValue("allthumbnails", files.map((el, index) => ({
        url:el,
        isSelected: index === 0,
      })));
    }
  }, [files]);
  // console.log(files,"files", allFrames)
  // console.log(watch("allthumbnails"),"allthumbnails")
  // useEffect(() => {
  //
  //   if (frames?.length > 0 && watch("allthumbnails").length === 0 && newThumbnails) {
  //     uploadingGeneratedThumbnails(files).then((response) => {
  //       const data = response.paths.map((path) => ({
  //         url: path,
  //         isSelected: response.paths.indexOf(path) === 0,
  //       }));
  //       setAllFrames(data);
  //       setValue("allthumbnails", data);
  //       setFrames([])
  //       setNewThumbnails(false);
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [frames, watch("allthumbnails"),newThumbnails]);

  const uploadingGeneratedThumbnails = async (file) => {
    try {
      return await API.uploadTestimonialFiles(
        testimonial,
        "allthumbnails",
        file
      );
    } catch (error) {
      toast.error("Error uploading thumbnail");
    }
  };
  if (newThumbnails)
    return <Loader loadingText="setting up video and thumbnail  " />;
  return (
    <>
      <div className="col-span-full">
        <div className="grid gap-4">
          <div>
            <label className="block text-left text-xs lg:text-sm font-semibold leading-6 text-gray-700 mb-2">
              Video
            </label>
            <VideoPlaceholder
                video={{
                  file: typeof watch("video") === "string" ? watch("video") : URL.createObjectURL(watch("video")),
                  image: watch("allthumbnails").length !== 0
                      ? typeof watch("allthumbnails").find((e) => e.isSelected)?.url === "string" ? watch("allthumbnails").find((e) => e.isSelected)?.url : URL.createObjectURL(watch("allthumbnails").find((e) => e.isSelected)?.url || "")
                      : "img/videoplaceholder.png"

                      // typeof watch("thumbnail") === "string"
                      // ? watch("thumbnail") : URL.createObjectURL(watch("thumbnail"))
                }}
                key={watch("allthumbnails").find((e) => e.isSelected)?.url}
            />
          </div>
          {/*<div className="w-full h-full">*/}
          {/*  <label className="block text-left text-xs lg:text-sm font-semibold leading-6 text-gray-700 mb-2">*/}
          {/*    Video Thumbnail*/}
          {/*  </label>*/}
          {/*  <VideoThumbnail*/}
          {/*      key={watch("video")}*/}
          {/*      videoUrl={typeof watch("video") === "string" ? watch("video") : URL.createObjectURL(watch("video"))}*/}
          {/*      thumbnailHandler={async (thumbnail) => setValue("thumbnail", await dataUrlToFile(thumbnail, "thumbnail"))}*/}
          {/*  />*/}
          {/*</div>*/}
          <RHFFile
              name="video"
              acceptType="video/*"
              helperText="MP4, MOV, MKV or MPEG (max. 100mb)"
              customContextClassname="!border-0 !p-0"
              customContent={
                <>
                  <div className="h-full w-full flex justify-center items-center">
                    <Button type="button" width="full" variant="secondary-gray">
                      <img src="/img/icons/upload.svg" alt="Upload"/>
                      Upload another video
                    </Button>
                  </div>
                </>
              }
          />
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-full">
              <label className="block text-left text-xs lg:text-sm font-semibold leading-6 text-gray-700">
                Video Thumbnails
              </label>
            </div>
            {allFrames.map((frame, index) => (
                <div
                    key={index}
                    className={`cursor-pointer   rounded-lg ${
                  frame.isSelected ? "border-4 border-brand-600" : " grayscale"
                }`}
                onClick={() => {
                  const data = allFrames.map((e) =>
                    e.url === frame.url
                      ? { ...e, isSelected: !e.isSelected }
                      : { ...e, isSelected: false }
                  );
                  const fileData = files.map((el,i) => {
                    if (i=== index){
                      return{
                        url:el,
                        isSelected:true
                      }
                    }else{
                      return{
                        url:el,
                        isSelected:false
                      }
                    }
                  });
                  // only one frame can be selected at a time


                  setAllFrames(data);
                  setValue("allthumbnails", fileData);
                }}
              >
                <img
                  className="h-auto max-w-full aspect-video object-cover  "
                  src={frame.url}
                  alt=""
                />
              </div>
            ))}
          </div>
          {errors && errors.allthumbnails && (
            <p className="text-red-500">{errors.allthumbnails.message}</p>
          )}
        </div>
      </div>

      {/*<div className="col-span-full">*/}
      {/*  <p className="font-inter font-semibold font-xs">Transcript</p>*/}
      {/*  <RHFTextArea name="transcript"/>*/}
      {/*</div>*/}
    </>
  );
};

const TextTestimonialSection = () => {
  return (
      <>
        <div className="col-span-full">
          {/*<p className="font-inter font-semibold font-xs">Testimonial</p>*/}
          <RHFTextArea name={"testimonial"} label="Testimonial"/>
        </div>
      {/*  <div className="col-span-full">*/}
      {/*    /!*<p className="font-inter font-semibold font-xs">Image</p>*!/*/}
      {/*  <RHFSingleFileUpload name={"thumbnail"} label="Image" />*/}
      {/*</div>*/}
    </>
  );
};
