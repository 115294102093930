import React, {useEffect, useState} from "react";
import Button from "../Button";
import {useForm} from "react-hook-form";
import {FormProvider} from "../common/RHF";
import RHFFile from "../common/RHF/RHFFile";
import ImportCSVTable from "./ImportCSVTable";
import API from "../../API";
import {toast} from "react-toastify";

export default function CSVImportTestimonial() {
    const formMethods = useForm({
        defaultValues:{
            file:undefined,
        }
    })
    const [isLoading,setIsLoading] = useState(false)
    const {
        handleSubmit,
        reset,
        watch
    } = formMethods

    async function onSubmit(data) {
        const formData = new FormData();
        for (const key in data) {
            formData.append(key, data[key]);
        }
        console.log(formData,"here")
        try {
            setIsLoading(true)
            await API.handleCSVTestimonial(formData)
            toast.success("Success")
            reset();
        }catch (e) {
            console.log(e,"error")
        }finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (watch("file")){
            toast.success("File uploaded");
        }
    }, [watch("file")]);

    function downloadFile() {
        const url = "/files/sample-file.csv"
        const a = document.createElement('a')
        a.href = url
        a.download = url.split('/').pop()
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }
    return (
        <FormProvider methods={formMethods} onSubmit={handleSubmit(onSubmit)} className="flex flex-col lg:flex-row mt-6 gap-10 xl:gap-16">
            <div className="w-full max-w-lg">
                <div className="p-8 bg-white shadow-sm rounded-xl border border-gray-200 flex flex-col gap-6">
                    <Button type="button" onClick={downloadFile} size="md" variant="secondary-gray">
                        Download Sample File
                    </Button>
                    <RHFFile
                        name="file"
                        label="Pick A File"
                        acceptType=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        helperText="CSV, XLSX or XLS files are accepted"
                        previewFile
                        required
                    />
                    <Button variant={"primary"} size="lg" isLoading={isLoading}>
                        Confirm Add Testimonial
                    </Button>
                </div>
            </div>
            <ImportCSVTable/>
        </FormProvider>
    );
}
