import FileUpload from "components/FileUpload";
import InputField from "components/InputField";
import Textarea from "components/Textarea";
import Toggle from "components/Toggle";
import { useState } from "react";
import { AVATAR, RATING } from "utils/constant";

const TestimonialEditPanel = ({
  setShowAvatar,
  setShowRating,
  showAvatar,
  showRating,
  testimonial,
}) => {
  const [imageURL, setImageURL] = useState("");

  const handleChange = async (type, value) => {
    if (type === "testimonyText") {
      let testimonialText = document.getElementById("testimonial_text");

      testimonialText.innerHTML = value;
      testimonial.testimonyText = value;
    }

    if (type === "file") {
      const file = value;

      const global_img = document.querySelector(".global_img");

      if (global_img) {
        const img = global_img;

        if (file instanceof File && file.type.startsWith("image/")) {
          const objectURL = URL?.createObjectURL?.(file);
          if (objectURL) {
            img.src = objectURL;
          } else {
            console.error("Failed to create object URL for the file:", file);
          }
        } else {
          console.error("Invalid image file:", file);
        }
      }
    }

    if (type === "author_names") {
      let authorName = document.getElementById("author_name");
      authorName.innerHTML = value;
      testimonial.authorName = value;
    }

    if (type === "label") {
      let authorLabel = document.getElementById("author_label");
      authorLabel.innerHTML = value;
      testimonial.name = value;
    }
  };

  const handleSwitch = (type) => {
    const avatar = document.getElementById("avatar");
    const rating = document.getElementById("rating");

    if (type === AVATAR && avatar) {
      setShowAvatar(!showAvatar);

      avatar.style.display = avatar.style.display === "none" ? "block" : "none";
    } else if (type === RATING && rating) {
      setShowRating(!showRating);

      rating.style.display = rating.style.display === "none" ? "block" : "none";
    }
  };

  return (
    <div className="hidden lg:inset-y-0 lg:z-50 lg:flex lg:w-96 lg:flex-col h-full flex-none">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-l border-gray-200 bg-white px-6">
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7 py-2">
            <li>
              <div className="text-base font-semibold leading-6 mb-2">Edit</div>
              <div className="space-y-8 pt-5">
                <div>
                  <Toggle
                    title="Show Avatar"
                    on={showAvatar}
                    onChange={() => handleSwitch("avatar")}
                  />
                </div>
                <div>
                  <Toggle
                    title="Show Rating"
                    on={showRating}
                    onChange={() => handleSwitch("rating")}
                  />
                </div>
              </div>

              <div className="space-y-5 py-6">
                <FileUpload
                  size="sm"
                  label={"Logo"}
                  buttonText="Replace Image"
                  testimonial={testimonial}
                  setImageURL={setImageURL}
                  onChange={(e) => {
                    handleChange("file", e);
                  }}
                />
                <Textarea
                  id="testimonial"
                  label="Testimony text"
                  className={"inputs"}
                  // value={testimonial.testimonyText || ""}
                  placeholder={
                    "Please leave a testimonial to help me grow my business 🙂"
                  }
                  onChange={(e) =>
                    handleChange("testimonyText", e.target.value)
                  }
                />
                <InputField
                  className={"inputs"}
                  id="authorname"
                  label="Author name"
                  placeholder={"John Doe"}
                  type="text"
                  // defaultValue={testimonial.name || ""}
                  // onChange={(e) =>
                  //   setTestimonial({
                  //     ...testimonial,
                  //     name: e.target.value,
                  //   })
                  // }

                  onChange={(e) => handleChange("author_names", e.target.value)}
                />
                <InputField
                  id="label"
                  className={"inputs"}
                  label="Label"
                  placeholder={"Add label"}
                  type="text"
                  // defaultValue={testimonial.label || ""}
                  onChange={(e) =>
                    // setTestimonial({
                    //   ...testimonial,
                    //   label: e.target.value,
                    // }),
                    handleChange("label", e.target.value)
                  }
                />
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default TestimonialEditPanel;
