import React, { useState, useRef, useEffect } from "react";
import {
  ArrowPathIcon,
  ArrowDownTrayIcon,
  StopIcon,
  PlayIcon,
  PauseIcon,
} from "@heroicons/react/24/solid";
import RecordRTC from "recordrtc";
import IconButton from "./IconButton";
import fixWebmDuration from "webm-duration-fix";
import AudioRecorder2 from "audio-recorder-polyfill";
import {toast} from "react-toastify";
import {createFile} from "../utils/utils";


if (
    typeof window !== undefined &&
    (!window.MediaRecorder ||
        !window.MediaRecorder.isTypeSupported("video/webm"))
) {
  MediaRecorder = AudioRecorder2;
}
const mimeType = 'video/webm; codecs="opus,vp8"';

const VideoRecorder = ({
                         StartCameraButton,
                         RecordButton,
                         videoFile,
                         setVideoFile,
                       }) => {
  const [permission, setPermission] = useState(true);
  const statusRef = useRef(null);
  const recorderRef = useRef(null);
  const cameraRef = useRef(null);
  const fileSizeTimerRef = useRef(null);
  const timerRef = useRef(0);
  const liveVideoFeed = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [stream, setStream] = useState(null);
  const [recordedVideo, setRecordedVideo] = useState(null);
  // const [videoChunks, setVideoChunks] = useState([]);
  const [video,setVideo] = useState("")
  useEffect(() => {
    return () => clearTimeout(fileSizeTimerRef.current);
  }, []);
  //
  // useEffect(() => {
  //   getCameraPermission();
  // }, []);

  const mediaRecorder = useRef(null);

  async function handleRetryVideo() {
    setStream(null);
    setRecordingStatus("inactive");
    setVideo(null);
    await startRecording();
  }

  const getCameraPermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        // navigator.getUserMedia =
        //   navigator.getUserMedia ||
        //   navigator.mozGetUserMedia ||
        //   navigator.webkitGetUserMedia;

        const cm = await navigator.mediaDevices?.getUserMedia({
          audio: true,
          video: true,
        });

        setPermission(true);

        setStream(cm);
        // cameraRef.current = cm;

        //set videostream to live feed player
        liveVideoFeed.current.srcObject = cm;
        return cm
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  const startRecording = async () => {
    const stream = await getCameraPermission();
    if (stream){
      if (recordingStatus === "paused") {
        // recorderRef.current.resumeRecording();
        mediaRecorder.current?.resume();
        setRecordingStatus("recording");
      } else {
        setRecordingStatus("recording");
        mediaRecorder.current = new MediaRecorder(stream, {mimeType: 'video/webm'});
        mediaRecorder.current.start();
        // mediaRecorder.current.ondataavailable = (event) => {
        //   if (typeof event.data === "undefined") return;
        //   if (event.data.size === 0) return;
        // };

        mediaRecorder.current.addEventListener("dataavailable", async (e) => {
          // audio.src = URL.createObjectURL(e.data)
          // //creates a blob file from the audiochunks data
          // const audioBlob = new Blob(audioChunks, { type: mimeType });
          //creates a playable URL from the blob file.
          // console.log(e,"video data")
          const videoUrl = URL.createObjectURL(e.data);
          const file = await createFile(videoUrl,"recording");
          // const videoDuration = calculateVideoDuration(new Blob([e.data]));
          // console.log(await videoDuration,"videoDuration")
          setVideoFile(file)
          toast.success("Video Successfully Recorded");
          setVideo(videoUrl);

        });

        // timerRef.current = 0;
        //
        // console.log(cameraRef.current)
        // var rec = RecordRTC(cameraRef.current, {
        //   mimeType: "video/webm",
        //   timeSlice: 1000, // pass this parameter
        //   onTimeStamp: function (timestamp, timestamps) {
        //     // console.log("Timestamp:");
        //     // console.log(timestamp);
        //     // console.log(timestamps);
        //
        //     if (timestamps.length < 2) return;
        //     timerRef.current += 1; //parseInt((timestamps[timestamps.length - 1] - timestamps[timestamps.length - 2]) / 1000);
        //     // var duration = parseInt((new Date().getTime() - timestamps[0]) / 1000);
        //     var duration = timerRef.current;
        //     if (duration < 0) return;
        //
        //     if (statusRef.current) {
        //       let minutes = Math.floor(duration / 60);
        //       let extraSeconds = duration % 60;
        //       minutes = minutes < 10 ? "0" + minutes : minutes;
        //       extraSeconds =
        //           extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
        //
        //       statusRef.current.innerHTML = minutes + ":" + extraSeconds;
        //     }
        //   },
        // });
        //
        // recorderRef.current = rec;

        // clearTimeout(timerRef.current);

        // (function looper() {
        //     if (!recorderRef.current) {
        //         return;
        //     }

        //     var internal = recorderRef.current.getInternalRecorder();
        //     if (internal && internal.getArrayOfBlobs) {
        //         var blob = new Blob(internal.getArrayOfBlobs(), {
        //             type: 'video/webm'
        //         });

        //         // console.log(bytesToSize(blob.size));
        //         // status.current.innerHTML = 'Recording length: ' + bytesToSize(blob.size);
        //     }
        //     else {
        //         console.log("No internal recorder");
        //     }

        //     timerRef.current = setTimeout(looper, 1000);
        // })();

        // rec.startRecording();
        // rec.camera = cameraRef.current;
        // let blob = await rec.getBlob();
        // console.log(blob,"blob")

      }
    }
  };

  const stopRecordingCallback = async () => {
    liveVideoFeed.srcObject = null;
    const blob = recorderRef.current.getBlob();

    const videoDuration = calculateVideoDuration(blob);

    const fixedFile = await fixWebmDuration(blob, videoDuration);

    setVideoFile(fixedFile);
    setRecordedVideo(URL.createObjectURL(fixedFile));

    recorderRef.current.camera.getTracks().forEach((track) => track.stop());
    if (cameraRef.current) {
      cameraRef.current.getTracks().forEach((track) => track.stop());
    }
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }

    recorderRef.current.camera.stop();
    recorderRef.current = null;
  };

  // Function to calculate video duration (implementation details depend on your environment):
  function calculateVideoDuration(blob) {
    // Example using the browser's Video element (if supported)
    if (window.URL && window.URL.createObjectURL) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(blob);
      return new Promise((resolve, reject) => {
        video.onloadedmetadata = () => {
          resolve(video.duration);
        };
        video.onerror = reject;
      });
    }

    // If browser doesn't support Video element or you need a different approach,
    // consider using a library like ffmpeg.wasm or a server-side video metadata extraction solution.
    console.warn(
        "Video duration calculation not supported in this environment. Using default value."
    );
    return 0; // Or provide an estimated duration in seconds
  }

  const stopRecording = () => {
    setPermission(false);
    setRecordingStatus("inactive");
    // liveVideoFeed.current.srcObject = null;
    //
    // recorderRef.current.stopRecording(stopRecordingCallback);
    mediaRecorder.current?.stop();
    stream?.getTracks().forEach((el) => {
      el.stop();
      el.enabled = false;
    });

  };

  const pauseRecording = () => {
    setRecordingStatus("paused");
    // recorderRef.current.pauseRecording();
    mediaRecorder.current?.pause();
  };

  // if (!stream) return null;
  return (
      <div className="bg-gray-100 rounded-2xl w-full">
        <div className="video-player">
          {!video && (
              // <video
              //     ref={liveVideoFeed}
              //     autoPlay
              //     className="live-player"
              //     muted
              // ></video>
              <img
                  src="/img/recorder-image.jpg"
                  alt="video recorder"
                  className="w-full rounded-2xl"
              />
          )}
          {video && (
              <div className="recorded-player">
                <video className="recorded" src={video} controls></video>
                <div className="flex items-center p-2">
                  <a
                      download
                      href={video}
                      className="font-semibold text-gray-600 flex items-center gap-2 p-2 text-sm hover:bg-gray-200 rounded-lg justify-center flex-1 transition"
                  >
                    <ArrowDownTrayIcon className="w-5 h-5" title="Download Video" />
                    <span>Download</span>
                  </a>
                  <button
                      onClick={handleRetryVideo}
                      type="button"
                      className="font-semibold text-red-600 flex items-center gap-2 p-2 text-sm hover:bg-red-100 rounded-lg justify-center flex-1 transition "
                  >
                    <ArrowPathIcon className="w-5 h-5 " title="Record Again" />
                    <span>Retry</span>
                  </button>
                </div>
              </div>
          )}
        </div>
        {!video && (
            <main>
              <div className="video-controls py-2 flex justify-center relative h-14 items-center">
                {recordingStatus !== "inactive" && (
                    <span
                        className="absolute h-full flex items-center pl-4 text-gray-500 left-0 top-0"
                        ref={statusRef}
                    >
                00:00
              </span>
                )}

                {permission && recordingStatus === "inactive" && (
                    <>
                <span
                    className="absolute h-full flex items-center pl-4 text-gray-500 left-0 top-0"
                    ref={statusRef}
                >
                  00:00
                </span>
                      <button
                          onClick={startRecording}
                          type="button"
                          className="border-2 border-white bg-red-500 w-8 h-8 rounded-full shadow hover:bg-red-600"
                      />
                    </>
                )}
                {permission && recordingStatus === "paused" && (
                    <IconButton buttonType={"button"} icon={PlayIcon} onClick={startRecording} />
                )}
                {recordingStatus === "recording" && (
                    <>
                      <IconButton buttonType={"button"} onClick={pauseRecording} icon={PauseIcon} />
                      <IconButton
                          type="danger"
                          buttonType={"button"}
                          onClick={stopRecording}
                          icon={StopIcon}
                      />
                    </>
                )}
              </div>
            </main>
        )}
      </div>
  );
};

export default VideoRecorder;
