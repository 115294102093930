import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../layouts/Sidebar";

import TextTestimonial from "../components/import-testimonials/TextTestimonial";
import { useSelector } from "react-redux";
import { Tab } from '@headlessui/react'
import VideoTestimonial from "../components/import-testimonials/VideoTestimonial";
import CSVImportTestimonial from "../components/import-testimonials/CSVImportTestimonial";
import Button from "../components/Button";

const importTabs = [
  {
    title: "Text testimonial",
    icon:"/img/icons/pencil.svg",
    selectedIcon:"/img/icons/pencil-selected.svg",
    content:TextTestimonial
  },
  {
    title: "Video testimonial",
    icon:"/img/icons/video-recorder.svg",
    selectedIcon:"/img/icons/video-recorder-selected.svg",
    content:VideoTestimonial
  },
  {
    title: "CSV / XLS",
    icon:"/img/icons/file.svg",
    selectedIcon:"/img/icons/file-selected.svg",
    content:CSVImportTestimonial
  }
]

export default function ImportPage() {
  const { isAuthenticated, status } = useSelector((state) => state);
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!isAuthenticated) navigate("/login");
  }, [isAuthenticated, navigate]);

  return (
    <div className="h-screen flex flex-col lg:flex-row">
      <Sidebar />
      <div className="flex-grow p-8 overflow-auto">
        <h1 className="text-3xl font-semibold flex items-baseline gap-2">
          Import Testimonials
        </h1>

        <Tab.Group>
          <Tab.List className="my-8 flex gap-4 flex-col lg:flex-row lg:flex-wrap">
            {importTabs.map(el => (
                <Tab key={el.title} className="focus-visible:outline-0">
                  {({ selected }) => (
                      <Button size="md" variant="secondary-gray"  iconTail={selected ? el.selectedIcon ?? el.icon : el.icon}
                      className={selected ? "!border-brand-300 !bg-brand-50 !text-brand-700" : ""}>
                        {el.title}
                      </Button>
                  )}
                </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            {importTabs.map((el) => (
                <Tab.Panel key={el.title}>{el.content && <el.content/>}</Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}
