import {Fragment, useState} from 'react';
import {PencilSquareIcon, Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import {Dialog, Transition} from "@headlessui/react";
import {NavLink} from "react-router-dom";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function FormSidebar({ items, setItems, setRightSidebarOpen }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const handleItemClick = (itemName) => {
        const updatedItems = items.map((item) => ({
            ...item,
            current: item.name === itemName,
        }));

        setItems(updatedItems);
    };

    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-50 lg:hidden"
                    onClose={setSidebarOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button
                                            type="button"
                                            className="-m-2.5 p-2.5"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon
                                                className="h-6 w-6 text-white"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </Transition.Child>
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-4 py-6">
                                    <nav className="flex flex-1 flex-col">
                                        <ul className="flex flex-1 flex-col gap-y-7">
                                            <li>
                                                <h6 className="text-xs font-semibold leading-6 text-gray-400 mb-2">
                                                    PAGES
                                                </h6>
                                                <div className="flex flex-col gap-2">
                                                    {items.map((item) => (
                                                        <button
                                                            key={item.name}
                                                            onClick={() =>
                                                                handleItemClick(item.name)
                                                            }
                                                            className={classNames(
                                                                item.current
                                                                    ? "bg-gray-100 text-gray-800"
                                                                    : "text-gray-700 hover:text-gray-800 hover:bg-gray-100",
                                                                "group flex items-center gap-x-3 rounded-md py-2 px-3 text-base leading-6 font-semibold"
                                                            )}
                                                        >
                                                            <item.icon
                                                                className={"text-gray-500 h-6 w-6 shrink-0 stroke-2"}
                                                                aria-hidden="true"
                                                            />
                                                            {item.name}
                                                        </button>
                                                    ))}
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col h-full flex-none">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-4 py-6">
                    <nav className="flex flex-1 flex-col">
                        <ul className="flex flex-1 flex-col gap-y-7">
                            <li>
                                <h6 className="text-xs font-semibold leading-6 text-gray-400 mb-2">
                                    PAGES
                                </h6>
                                <div className="flex flex-col gap-2">
                                    {items.map((item) => (
                                        <button
                                            key={item.name}
                                            onClick={() =>
                                                handleItemClick(item.name)
                                            }
                                            className={classNames(
                                                item.current
                                                    ? "bg-gray-100 text-gray-800"
                                                    : "text-gray-700 hover:text-gray-800 hover:bg-gray-100",
                                                "group flex items-center gap-x-3 rounded-md py-2 px-3 text-base leading-6 font-semibold"
                                            )}
                                        >
                                            <item.icon
                                                className={"text-gray-500 h-6 w-6 shrink-0 stroke-2"}
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </button>
                                    ))}
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div
                className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
                <button
                    type="button"
                    className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                    onClick={() => setSidebarOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                </button>
                <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
                    Form
                </div>
                <button
                    type="button"
                    className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                    onClick={() => setRightSidebarOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <PencilSquareIcon className="h-6 w-6" aria-hidden="true"/>
                </button>
            </div>
        </>
    );
}
