import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";
import API from "API";
import Spinner from "components/Spinner";
import StarRating from "components/StarRating";
import Avatar from "components/Avatar";
import VideoPlaceholder from "components/videoPlaceholder";
import { formatVideoURL } from "utils/utils";
import dayjs from "dayjs";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";

export default function EmbedTestimonial() {
  const [testimonial, setTestimonial] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [error, setError] = useState(null);

  const getTestimonial = async () => {
    try {
      setLoading(true);
      const res = await API.loadTestimonial({ _id: id });
      setLoading(false);
      setTestimonial(res.result);
    } catch (err) {
      setLoading(false);
      setError(err.message);
      toast.error(err.message);
      console.log(err);
    }
  };
  useEffect(() => {
    getTestimonial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) return <Spinner />;

  return (
    <div className="w-full max-w-md relative z-10">
      <div className="text-start flex flex-col items-center mb-5">
        <div className="bg-white md:max-w-sm  sm:max-w-sm  max-w-xs rounded-xl p-8 shadow-xl shadow-gray-200 flex flex-col gap-6 w-full mt-8 text-start items-start">
          <div className="flex-auto flex-row  flex algin-middle ">
            {error && <div className="text-red-500">{error}</div>}
            <div className="h-14 w-14 ">
              <Avatar imageSrc={testimonial?.image} />{" "}
            </div>
            <div className="ml-5 mt-2">
              <div className="flex align-middle   md:flex-row flex-col">
                <h1 className=" text:xl font-semibold font-inter">
                  {testimonial?.name}
                  test
                </h1>
              </div>
              <p className="md:text-md  text-xs font-inter text-gray-600">
                {testimonial?.email}
                test
              </p>
            </div>
          </div>
          <StarRating
            rating={testimonial?.stars}
            interactive={true}
            starClass="w-7 h-7"
          />
          {testimonial?.type.toLowerCase() === "video" && (
            <>
              <p>
                {testimonial?.transcript ||
                  "Please leave a testimonial to help me grow my business 🙂"}
              </p>
              <VideoPlaceholder
                video={{
                  file: formatVideoURL(testimonial?.video),
                  image: testimonial?.allthumbnails?.find((e) => e.isSelected)
                    ?.url,
                }}
              />
            </>
          )}
          {testimonial?.type.toLowerCase() === "text" && (
            <>
              <p>
                {testimonial?.testimonial ||
                  "Please leave a testimonial to help me grow my business 🙂"}
              </p>
              {testimonial?.thumbnail && (
                <img
                  src={"/" + testimonial.thumbnail}
                  alt=""
                  className="rounded-md shadow-lg"
                />
              )}
            </>
          )}
          <p className="text-gray-400 text-xs">
            {dayjs(testimonial.date).format("ll")}
          </p>
        </div>
      </div>
      <div className="w-full h-auto p-1 px-3 bg-[#0C111D] rounded-lg text-white cursor-pointer hover:bg-white hover:text-black animate-bounce duration-150 hover:border-2">
        <div className="flex  justify-evenly items-center">
          <img src="/img/logoIcon.svg" alt="" />
          <p className="text-xs w-full text-center">
            Collect testimonial with Repsquare
          </p>
          <ArrowUpRightIcon className="h-5 w-5 font-bold " />
        </div>
      </div>
    </div>
  );
}
