import axios from "axios";
import { toast } from "react-toastify";

// Create an instance of axios
const axiosInstance = axios.create({});

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code within the range of 2xx causes this function to trigger
    return response;
  },
  (error) => {
    // Any status codes outside the range of 2xx cause this function to trigger
    if (error.response) {
      // Server responded with a status other than 2xx
      if (error.response.status === 401) {
        window.location.href = "/login";
      } else if (error.response.status === 403) {
        // Handle forbidden error
        toast.error(error.response.data.message);
        window.location.href = "/login";
      } else if (error.response.status === 500) {
        toast.error(error.response.data.message);
      } else {
        return Promise.reject(error.response.data);
      }
    } else if (error.request) {
      // Request was made but no response was received
      toast.error(error.request);
      console.error("No response received:", error.request);
    } else {
      // Something happened in setting up the request that triggered an error
      toast.error(error.message);
      console.error("Error setting up request:", error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
