import TestimonialAvatar from "components/Testimonial/testimonialAvatar";
import StarRating from "components/StarRating";
import TestimonialText from "components/Testimonial/testimonialText";
import TestimonialAuthor from "components/Testimonial/TestimonialAuthor";

const TestimonialOne = () => {
  return (
    <div
      id={1}
      className="bg-[url('../public/img/landscape.png')] mx-auto bg-no-repeat bg-cover bg-[center_center] py-1 px-5 selected_template   [box-shadow:0px_4px_4px_0px_rgba(0,_0,_0,_0.25)] drop-shadow-xl rounded-xl border-solid"
    >
      <div className="flex items-center flex-wrap sm:flex-nowrap">
        <div id="avatar">
          <TestimonialAvatar
            src="/img/Avatar1.png"
            className="w-32 h-[150px] test_one_avatar"
            imageClasses="global_img rounded-full object-cover"
          />
        </div>
        <div className="px-4 space-y-8 py-5">
          <div>
            <div id="rating">
              <StarRating rating={5} />
            </div>
            <TestimonialText classes="font-semibold mt-2 text-white text-sm sm:text-base" />
          </div>

          <div id="author_details" className="author_details_one_div">
            <TestimonialAuthor
              name="Muhammad Anas"
              label="Senior UX Designer at Bussfly | Ex-Sony"
              classes="text-base leading-5 text-white test_one_para"
              innerclasses="block text-[#D6BBFB] font-medium"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialOne;
