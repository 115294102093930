import VideoThumbnail from "react-video-thumbnail";
import React from "react";


export default function ThumbnailsSelection({formMethods}) {
    const {getValues, setValue, watch} = formMethods;

    async function thumbnailHandler(thumbnailFile) {
        const currentThumbnails = getValues("allThumbnails");
        setValue("allThumbnails", [...currentThumbnails, thumbnailFile])
    }
    return (
        <div className="w-full h-full">
            <label
                className="block  text-xs lg:text-sm font-semibold leading-6 text-gray-700 mb-2"
            >
                Video Thumbnail
            </label>
            <div className="flex items-center gap-2.5">
                <button type="button" onClick={() => setValue("selectedThumbnail", 0)}
                        className={`relative ${watch("selectedThumbnail") === 0 ? "border-4 border-brand-600" : ""}`}>
                    <VideoThumbnail
                        videoUrl={URL.createObjectURL(watch("video"))}
                        snapshotAtTime={2}
                        thumbnailHandler={thumbnailHandler}
                    />
                </button>
                <button type="button" onClick={() => setValue("selectedThumbnail", 1)}
                        className={`relative ${watch("selectedThumbnail") === 1 ? "border-4 border-brand-600" : ""}`}>
                    <VideoThumbnail
                        videoUrl={URL.createObjectURL(watch("video"))}
                        snapshotAtTime={5}
                        thumbnailHandler={thumbnailHandler}
                    />
                </button>
                <button type="button" onClick={() => setValue("selectedThumbnail", 2)}
                        className={`relative ${watch("selectedThumbnail") === 2 ? "border-4 border-brand-600" : ""}`}>
                    <VideoThumbnail
                        videoUrl={URL.createObjectURL(watch("video"))}
                        snapshotAtTime={15}
                        thumbnailHandler={thumbnailHandler}
                    />
                </button>
            </div>
        </div>
    )
}