import MainCreateFormWrapper from "./main-create-form-wrapper";
import MainCreateFormCardHeader from "./main-create-form-card-header";
import React, {Fragment} from "react";
import {FormProvider, RHFTextArea} from "../common/RHF";
import RHFInputField from "../common/RHF/RHFTextField";
import RHFTextAreaWithMd from "../common/RHF/RHFTextAreaWithMd";
import MdTextPreview from "../common/text/md-text-preview";
import MobileSidebarWrapper from "../../layouts/mobile-sidebar-wrapper";
import ConfettiExplosion from "react-confetti-explosion";

export default function ThanksForm({
    editable,
    cssClasses,
    formMethods,
    setRightSidebarOpen,
    rightSidebarOpen
}) {
    const [isExploding, setIsExploding] = React.useState(false);
    const {watch} = formMethods;
    return (
        <>
            <MainCreateFormWrapper>
                <div className={cssClasses.card}>
                    {!editable && <ConfettiExplosion width={1600} particleCount={250} duration={3000} force={0.7}/>}
                    {/*<img*/}
                    {/*    src={formDetails.logo}*/}
                    {/*    alt="Logo"*/}
                    {/*    className="w-16 h-16 object-fill rounded-full self-center"*/}
                    {/*/>*/}
                    <MainCreateFormCardHeader logo={watch("logo")}/>
                    <div className="flex flex-col gap-2">
                        <h1 className="text-base lg:text-lg text-gray-700 font-semibold">
                            {watch("thanks.title")}
                        </h1>
                        {/*<p className="text-gray-900 text-sm lg:text-base">*/}
                        {/*    {watch("thanks.description")}*/}
                        {/*</p>*/}
                        <MdTextPreview className="!text-center" value={watch("thanks.description")}/>
                    </div>
                </div>
            </MainCreateFormWrapper>
            {editable && (
                <>
                    <MobileSidebarWrapper open={rightSidebarOpen} setOpen={setRightSidebarOpen}>
                        <FormProvider methods={formMethods} className={cssClasses.sidebar}>
                            <RHFInputField
                                id="thanksTitle"
                                label="Title"
                                type="text"
                                name="thanks.title"
                                // value={formDetails.details.title}
                                // onChange={(e) =>
                                //     setFormDetails({
                                //         ...formDetails,
                                //         thanks: {
                                //             ...formDetails.details,
                                //             title: e.target.value,
                                //         },
                                //     })
                                // }
                            />
                            {/*<RHFTextArea*/}
                            {/*    id="thanksDescription"*/}
                            {/*    label="Description"*/}
                            {/*    name="thanks.description"*/}
                            {/*    // value={formDetails.thanks.description}*/}
                            {/*    // onChange={(e) =>*/}
                            {/*    //     setFormDetails({*/}
                            {/*    //         ...formDetails,*/}
                            {/*    //         thanks: {*/}
                            {/*    //             ...formDetails.details,*/}
                            {/*    //             description: e.target.value,*/}
                            {/*    //         },*/}
                            {/*    //     })*/}
                            {/*    // }*/}
                            {/*/>*/}
                            <div className="w-full">
                                <RHFTextAreaWithMd
                                    label="Subtitle"
                                    name="thanks.description"

                                />
                                <p className="text-xs text-gray-600 pt-1.5">
                                    You can use markdown to format the text
                                </p>
                            </div>
                        </FormProvider>
                    </MobileSidebarWrapper>

                    <FormProvider methods={formMethods} className={`hidden ${cssClasses.sidebar}`}>
                        <RHFInputField
                            id="thanksTitle"
                            label="Title"
                            type="text"
                            name="thanks.title"
                            // value={formDetails.details.title}
                            // onChange={(e) =>
                            //     setFormDetails({
                            //         ...formDetails,
                            //         thanks: {
                            //             ...formDetails.details,
                            //             title: e.target.value,
                            //         },
                            //     })
                            // }
                        />
                        {/*<RHFTextArea*/}
                        {/*    id="thanksDescription"*/}
                        {/*    label="Description"*/}
                        {/*    name="thanks.description"*/}
                        {/*    // value={formDetails.thanks.description}*/}
                        {/*    // onChange={(e) =>*/}
                        {/*    //     setFormDetails({*/}
                        {/*    //         ...formDetails,*/}
                        {/*    //         thanks: {*/}
                        {/*    //             ...formDetails.details,*/}
                        {/*    //             description: e.target.value,*/}
                        {/*    //         },*/}
                        {/*    //     })*/}
                        {/*    // }*/}
                        {/*/>*/}
                        <div className="w-full">
                            <RHFTextAreaWithMd
                                label="Subtitle"
                                name="thanks.description"

                            />
                            <p className="text-xs text-gray-600 pt-1.5">
                                You can use markdown to format the text
                            </p>
                        </div>
                    </FormProvider>
                </>
            )}
        </>
    );
}
