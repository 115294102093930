import classNames from "classnames";
import React, {useState} from "react";

const Avatar = ({ imageSrc, imageClasses, ...props }) => {
  const [src,setSrc] = useState(!!imageSrc ? imageSrc.split("http")[1] ? imageSrc : `/${imageSrc}` : "/img/imagePlaceholder.svg");
  return (
    <img
      //   className="w-10 h-10 rounded-full"
      className={`${classNames("w-full h-full rounded-full", imageClasses)} object-contain bg-gray-50`}
      src={src}
      alt={"Avatar"}
      onError={(e) => {
        setSrc("/img/imagePlaceholder.svg")
      }}
      rel="noreferrer"
      props // Remove the spread operator before 'props'
    />
  );
};

export default Avatar;
