// import axios from "axios";
import axios from "utils/axios";

const API_PREFIX = "/api"

class APIClass {
  parseResult(res) {
    if (res.data.success) return res.data;
    else throw Error(res.data.message);
  }

  async register(userData) {
    return this.parseResult(await axios.post(`${API_PREFIX}/auth/register`, userData));
  }

  async login(userData) {
    return this.parseResult(await axios.post(`${API_PREFIX}/auth/login`, userData));
  }

  async updateProfile(userData) {
    return this.parseResult(await axios.post(`${API_PREFIX}/settings/update_user_profile`, userData));
  }
  async handleTextTestimonial(testimonialData) {
    return this.parseResult(await axios.post(`${API_PREFIX}/import/text_testimonial`, testimonialData));
  }
  async handleVideoTestimonial(testimonialData) {
    return this.parseResult(await axios.post(`${API_PREFIX}/import/video_testimonial`, testimonialData));
  }
  async handleCSVTestimonial(testimonialData) {
    return this.parseResult(await axios.post(`${API_PREFIX}/import/bulk_upload_testimonials`, testimonialData));
  }

  async logout() {
    return this.parseResult(await axios.post(`${API_PREFIX}/auth/logout`));
  }

  async forgotPassword(userData) {
    return this.parseResult(
      await axios.post(`${API_PREFIX}/auth/forgot_password`, userData)
    );
  }

  async saveSettings(data) {
    return this.parseResult(await axios.post(`${API_PREFIX}/settings/save_settings`, data));
  }

  async loadSettings(userId) {
    return this.parseResult(
      await axios.post(`${API_PREFIX}/settings/load_settings?id=${userId}`)
    );
  }

  async uploadTestimonialFile(testimonialsData, type, file,onUploadProgress,onDownloadProgress) {
    var formData = new FormData();

    formData.append("file", file);

    return this.parseResult(
      await axios({
        method: "post",
        url: `${API_PREFIX}/testimonials/upload_testimonial_file?owner=${testimonialsData.owner}&type=${type}&id=${testimonialsData._id}`,
        data: formData,
        header: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
        onDownloadProgress,
      })
    );
  }
  async uploadTestimonialFiles(testimonialsData, type, files, onUploadProgress,onDownloadProgress) {
    var formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    return this.parseResult(
      await axios({
        method: "post",
        url: `${API_PREFIX}/testimonials/upload_testimonial_files?owner=${testimonialsData.owner}&type=${type}&id=${testimonialsData._id}`,
        data: formData,
        header: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
        onDownloadProgress,
      })
    );
  }

  async saveTestimonial(testimonialsData) {
    return this.parseResult(
      await axios.post(`${API_PREFIX}/testimonials/save_testimonial`, testimonialsData)
    );
  }

  async updateTestimonialStatus(testimonialsData) {
    return this.parseResult(
      await axios.post(
        `${API_PREFIX}/testimonials/toggle_testimonial_status`,
        testimonialsData
      )
    );
  }

  async updateTestimonial(testimonialsData, formData) {
    return this.parseResult(
      await axios.post(
        `${API_PREFIX}/testimonials/updatetestimonial/${testimonialsData._id}`,
        formData
      )
    );
  }

  async deleteTestimonial(formsData) {
    return this.parseResult(
      await axios.post(`${API_PREFIX}/testimonials/delete_testimonial?id=` + formsData._id)
    );
  }
  async deleteMultipleTestimonials(testimonialIdList) {
    return this.parseResult(
        await axios.delete(`${API_PREFIX}/testimonials/delete_multiple_testimonial?ids=${testimonialIdList}`)
    );
  }
  async sendthankyouemail(testimonialsData) {
    return this.parseResult(
      await axios.post(`${API_PREFIX}/testimonials/send_thankyou_email`, testimonialsData)
    );
  }

  async loadTestimonial(testimonialsData) {
    return this.parseResult(
      await axios.post(
        `${API_PREFIX}/testimonials/load_testimonial?id=` + testimonialsData._id
      )
    );
  }

  async listTestimonials(category, formId) {
    return this.parseResult(
      await axios.post(
        `${API_PREFIX}/testimonials/list_testimonials?category=${category ? category : ""}&formId=${formId ? formId : ""}`
      )
    );
  }

  async saveForm(formsData) {
    return this.parseResult(
      await axios.post(`${API_PREFIX}/testimonials/save_form`, formsData)
    );
  }

  async deleteForm(formsData) {
    return this.parseResult(
      await axios.post(`${API_PREFIX}/testimonials/delete_form?id=` + formsData._id)
    );
  }

  async incrementTestimonialVisitor(formId) {
    return this.parseResult(
        await axios.post(`${API_PREFIX}/testimonials/testimonialVisit/${formId}`)
    );
  }

  async loadForm(formsData) {
    return this.parseResult(
      await axios.post(`${API_PREFIX}/testimonials/load_form`, { id: formsData.id })
    );
  }

  async listForms() {
    return this.parseResult(await axios.post(`${API_PREFIX}/testimonials/list_forms`));
  }
  async sendTestimonialInviteEmail(inviteData) {
    return this.parseResult(await axios.post(`${API_PREFIX}/testimonials/send_testimonial_invite`, inviteData));
  }

  async ensureLogin() {
    return this.parseResult(await axios.post(`${API_PREFIX}/auth/current_session`));
  }

  async getTemplates() {
    return this.parseResult(
      await axios.get(`${API_PREFIX}/testimonials/templates`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
    );
  }

  async saveTemplate(template) {
    return await axios.post(`${API_PREFIX}/testimonials/save_template`, template, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
  }

  async uploadTestimonialLogo(editTestimonialDetails, type) {
    const formData = new FormData();
    let templateID = editTestimonialDetails?.templateId
      ? editTestimonialDetails?.templateId
      : "Default";

    const file = editTestimonialDetails?.image;

    formData.append("file", file);

    return await axios({
      method: "post",
      url: `${API_PREFIX}/testimonials/upload_testimonial_logo?owner=${editTestimonialDetails?.owner}&type=${type}&id=${templateID}`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async getCSRFToken() {
    const response = await axios.get(`${API_PREFIX}/auth/getCSRFToken`);
    axios.defaults.headers.post["X-CSRF-Token"] = response.data.CSRFToken;
  }
}

const API = new APIClass();

export default API;
