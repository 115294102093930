import { Dialog, Transition } from "@headlessui/react";
import { InboxIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import Button from "../Button";

export default function InviteModal({ open, setOpen,testimonialForms }) {
  // const [forms, setForms] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleFormSelection = (e) => {
    navigate(`/forms?selectedForm=${e.target.value}`);
  };


  return (
    <Transition show={open}>
      <Dialog className="relative z-[999]" onClose={setOpen}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              enter="eas.e-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              className="w-full flex items-center justify-center"
            >
              {/*{error && <div className="text-red-900">{error}</div>}*/}

              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8  sm:w-full sm:max-w-[550px]">
                  <button
                    type="button"
                    className=" float-right p-4 box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                    onClick={() => setOpen(false)}
                    aria-label="Close"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="">
                      {testimonialForms.length > 0 &&<div className="text-center sm:text-left">
                        <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-1"
                        >
                          Select form to share
                        </Dialog.Title>
                        <p className="text-sm text-gray-500">
                          Select a form to invite your customers to leave a
                          testimonial.
                        </p>
                        {/* select with icon */}
                        <div className="relative mt-2">
                          <InboxIcon className="w-5 absolute left-3 h-full text-gray-600"/>
                          <select
                              onChange={handleFormSelection}
                              id="form"
                              name="form"
                              className="block w-full pl-10 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          >
                            <option value="" disabled selected>
                              Select a form
                            </option>
                            {testimonialForms.map((form) => (
                                <option key={form._id} value={form._id}>
                                  {form.title}
                                </option>
                            ))}
                          </select>
                        </div>
                      </div>}
                      {testimonialForms.length <= 0 && <div className="text-center sm:text-left">
                        <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-1"
                        >
                          Create Form
                        </Dialog.Title>
                        <p className="text-sm text-gray-500">
                          You need to create a form to send an invite.
                        </p>
                        <div className="flex items-center gap-5 justify-center mt-8">
                          <Button size="lg" variant="default"   onClick={() => setOpen(false)}>
                            Do It Later
                          </Button>
                          <Button size="lg"   onClick={() => navigate("/forms")}>
                            Create Form
                          </Button>
                        </div>
                      </div>}
                    </div>
                  </div>
                </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
