import React from "react";
import {NavLink} from "react-router-dom";
import AuthWrapper from "../../layouts/Auth/AuthWrapper";
import ResetPasswordForm from "../../components/Auth/ResetPasswordForm";

export default function ResetPassword() {
    return (
        <AuthWrapper title="Reset your password" description="Set your new password.">
            <>
                <ResetPasswordForm/>

                <p className="mt-10 text-center text-sm text-gray-600">
                    {`Don’t have an account? `}
                    <NavLink
                        to="/register"
                        className="font-semibold text-brand-700 transition duration-500 hover:opacity-90"
                    >
                        Signup
                    </NavLink>
                </p>
            </>
        </AuthWrapper>
    );
}
