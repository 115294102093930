import React, {useState} from "react";
import Button from "../Button";
import {useForm} from "react-hook-form";
import {FormProvider, RHFTextArea, RHFTextField} from "../common/RHF";
import RHFStarRating from "../common/RHF/RHFStarRating";
import RHFAvatar from "../common/RHF/RHFAvatar";
import PreviewTestimonial from "./PreviewTestimonial";
import API from "../../API";
import {toast} from "react-toastify";

export default function TextTestimonial() {
    const formMethods = useForm({
        defaultValues:{
            name:"",
            position: "",
            email:"",
            stars: 0,
            testimonial:"",
            image:"/img/imagePlaceholder.svg"
        }
    })
    const [isLoading,setIsLoading] = useState(false)
    const {
        handleSubmit,
        watch,
        reset,
        setError
    } = formMethods

    async function onSubmit(data) {
        if (data?.stars <= 0){
            setError("stars", { type: 'custom', message: "Star is required" })
            return;
        }
        const formData = new FormData();
        for (const key in data) {
            formData.append(key, data[key]);
        }
        try {
            setIsLoading(true)
            await API.handleTextTestimonial(formData)
            toast.success("Success")
            reset();
        }catch (e) {
            toast.error(e.message)
        }finally {
            setIsLoading(false)
        }
    }
  return (
    <FormProvider methods={formMethods} onSubmit={handleSubmit(onSubmit)} className="flex flex-col lg:flex-row mt-6 gap-10 xl:gap-16">
      <div className="w-full max-w-lg">
        <div className="p-8 bg-white shadow-sm rounded-xl border border-gray-200 flex flex-col gap-6">
          <RHFTextField
            name="name"
            label="Full name"
            type="text"
            placeholder={"John Doe"}
            required
          />
          <RHFTextField
              name="position"
            label="Job title"
            type="text"
            placeholder={"Marketing Manager at Google"}
          />
          <RHFTextField
              name="email"
            label="Email address"
            type="email"
            autoComplete
            placeholder={"johndoe@corp.com"}
          />
          <RHFTextField
              name="date"
              label="Date"
              type="date"
              autoComplete
          />
            <RHFStarRating
                name="stars"
                label="Rating*"
                starClass="w-7 h-7"
            />
          <RHFTextArea
              name="testimonial"
              label="Testimonial*"
              placeholder="Please leave a testimonial to help me grow my business 🙂"
              required
          />
          <RHFAvatar name="image" size="sm" label={"Photo"}/>
            <Button variant={"primary"} size="lg" isLoading={isLoading}>
              Add Testimonial
          </Button>
        </div>
      </div>
      <PreviewTestimonial name={watch("name")} testimonial={watch("testimonial") || ""} position={watch("position")} avatar={watch("image")}/>
    </FormProvider>
  );
}
