import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Checkbox from './Checkbox'; // Update the import paths as per your project structure
import { useSelector } from 'react-redux';
import API from 'API';
import Spinner from './Spinner';
import TestimonialRow from './TestimonialRow';
import InputField from './InputField';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'; // Assuming you are using Heroicons

const TestimonialsTable = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { isAuthenticated, user } = useSelector((state) => state);
    const [status, setStatus] = useState('loading');
    const [testimonialList, setTestimonialList] = useState([]);
    const [dataIndex, setDataIndex] = useState(0);

    const updateStatus = React.useCallback(async (testimonial, e) => {
        if (testimonial.status !== e) {
            var result = await toast.promise(
                API.updateTestimonialStatus(testimonial),
                {
                    pending: 'Updating Testimonial Status...',
                    success: 'Success',
                    error: {
                        render({ data }) {
                            return `${data.message}`;
                        },
                    },
                }
            );
            setDataIndex((d) => d + 1);
        }
    }, []);

    React.useEffect(() => {
        console.log(isAuthenticated);
        if (!user) navigate('/login');
    }, [isAuthenticated, navigate, user]);

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                setStatus('loading');
                var res = await API.listTestimonials();
                console.log(res);

                setTestimonialList(res.result);
                setStatus('loaded');
            } catch (error) {
                console.log(error);
                error.toString();
                setStatus(error.toString());
            }
        };

        fetchData();
    }, [dataIndex, setTestimonialList, setStatus]);

    return (
        <div className="px-8 w-full">
            <h3 className="text-lg font-semibold flex items-center gap-2 mt-10">
                Testimonials
            </h3>
            <InputField
                icon={MagnifyingGlassIcon}
                size="lg"
                id="search"
                placeholder="Search"
                type="search"
                autoComplete="search"
            />

            <div className="mt-4">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                    <Checkbox />
                                </th>
                                <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                    Name
                                </th>
                                <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                    Testimonial
                                </th>
                                <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                    Date
                                </th>
                                <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {status === 'loading' ? (
                                <>
                                    <Spinner />
                                    Loading...
                                </>
                            ) : status === 'loaded' ? (
                                testimonialList.map((item) => (
                                    <TestimonialRow
                                        key={item.id} // Assuming each item has a unique 'id'
                                        item={item}
                                        updateStatus={(e) =>
                                            updateStatus(item, e)
                                        }
                                    />
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">{status}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TestimonialsTable;
