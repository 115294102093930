import RHFStarRating from "../common/RHF/RHFStarRating";
import React, {useEffect, useState} from "react";


export default function PreviewTestimonial({avatar, name, position, testimonial, video}) {
    const [videoUrl,setVideoUrl] = useState(null)

    useEffect(() => {
        if (video) setVideoUrl(URL.createObjectURL(video))
    }, [video]);
    return (
        <div className="w-full max-w-md lg:mt-6">
            <div className="text-center flex flex-col">
                <div className="text-gray-500 font-semibold text-xl lg:text-2xl">Preview</div>
                <div className="bg-white max-w-md rounded-3xl py-12 px-5 xl:p-12 shadow-xl shadow-gray-200 flex flex-col gap-6 w-full mt-8 text-center items-center">
                    <div className="flex items-center justify-center w-full">
                        <div className="flex items-center justify-center rounded-full bg-gray-100 border border-gray-200 w-14 h-14">
                            <img
                                src={avatar ? typeof avatar === "string" ? avatar : URL.createObjectURL(avatar) : "/img/imagePlaceholder.svg"}
                                alt="User"
                                className="object-contain w-14 h-14 rounded-full"
                            />
                        </div>
                    </div>
                    <RHFStarRating
                        name="stars"
                        label="Rating"
                        starClass="w-7 h-7"
                    />
                    <div className="flex flex-col">
                        <h1 className="text-xl font-bold">
                            {name || "John Doe"}
                        </h1>
                        <p className="text-gray-500 mt-2">
                            {position || "Manager at Google"}
                        </p>
                    </div>
                    {!videoUrl && (!!testimonial || testimonial === "") && <p>
                        {testimonial ||
                            "Please leave a testimonial to help me grow my business 🙂"}
                    </p>}
                    {(videoUrl || (!testimonial && testimonial !== "")) && (
                        <>
                            {!videoUrl && <div
                                className="w-full h-[250px] flex items-center justify-center p-5 max-h-[300px] bg-gray-100 rounded-3xl">
                               <img
                                    src="/img/play-circle.png"
                                    alt="Play Circle"
                                    className="w-20 h-20 object-contain xl:w-32 xl:h-32 max-w-32 max-h-32 rounded-full"
                                />
                            </div>}
                            {videoUrl && <video src={videoUrl} controls className="w-full rounded-3xl">
                                Your browser does not support the video tag.
                            </video>}
                        </>
                    )}
                </div>
            </div>
        </div>

    )
}