import classNames from "classnames";
import React from "react";
import { SOLID } from "utils/constant";

const Backgrounds = ({ background, handleBackground, backgroundItem }) => {
  const commonStyles = {
    height: "100%",
    width: "100%",
    borderRadius: "8px",
    cursor: "pointer",
    boxShadow: "0 7px 9px 0px rgba(0,0,0,0.15)",
  };

  const groupedData = {
    solid: background.colors ?? [],
    images: background.images ?? [],
  };

  return (
    <>
      {Object?.keys(groupedData)?.map?.((key, i) => {
        return (
          <div key={i}>
            <span className="uppercase text-sm text-gray-500 font-semibold">
              {key}
            </span>
            <div className="grid grid-cols-5 gap-2 py-4">
              {groupedData[key].map((backgroundFrame, index) => {
                const itemStyle =
                  key === SOLID
                    ? {
                        ...commonStyles,
                        background: backgroundFrame,
                        borderRadius: "10px",
                      }
                    : {
                        ...commonStyles,
                      };

                return (
                  <div className="relative w-[56px] h-[56px]" key={index}>
                    <div
                      style={itemStyle}
                      className={classNames(
                        backgroundFrame === backgroundItem ? "frame" : ""
                      )}
                      onClick={() => handleBackground(backgroundFrame)}
                    >
                      {key === "images" && (
                        <img
                          src={`${process.env.REACT_APP_API_URL}${backgroundFrame}`}
                          className="w-full h-full object-cover rounded-md"
                          alt="FRAME"
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Backgrounds;
