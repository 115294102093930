import classNames from "classnames";
import Spinner from "./Spinner";
import React from "react";

export default function Button({
  variant = "primary",
  children,
  onClick,
  className,
  size = "md",
  width = "auto",
  disabled = false,
    isLoading,
    iconTail,
    iconLeading,
    type
}) {
  // Define base classes
  const baseClasses =
    `flex gap-2 items-center justify-center relative rounded-md transition-colors font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`;

  // disable classes
  const disabledClasses = "disabled:bg-gray-100 disabled:border-gray-200 disabled:border disabled:cursor-not-allowed disabled:text-gray-400"
  // type-specific classes
  const variantClasses = classNames({
    "bg-brand-600 text-white hover:bg-brand-700 focus-visible:outline-brand-600 shadow-sm":
      variant === "primary",
    "bg-red-600 text-white hover:bg-red-500 focus-visible:outline-red-600 shadow-sm":
      variant === "danger",
    "border bg-white border-gray-200 text-gray-800 hover:bg-gray-100 shadow-sm":
      variant === "default",
    "border bg-brand-50 border-brand-300 text-brand-700 hover:bg-brand-100 shadow-sm":
      variant === "secondary",
    "text-gray-700 border border-gray-300 bg-white hover:bg-gray-50 hover:text-gray-800": variant === "secondary-gray",
    "border border-gray-200 text-gray-800 hover:bg-gray-50 shadow-sm":
      variant === "outlined-default",
  });

  // Width-specific classes
  const widthClass = width === "auto" ? "w-auto" : "w-full";

  // Size-specific classes
  const sizeClasses = classNames({
    "px-4 py-2.5 h-11 text-base": size === "lg",
    "px-[14px] py-2.5 h-10 text-sm": size === "md",
    "h-9 px-3 py-2 text-sm": size === "sm",
  });
  const leftIconSizeClasses= classNames({
    "left-4": size === "lg",
    "left-[14px]": size === "md",
    "left-3": size === "sm",
  });
  const rightIconSizeClasses= classNames({
    "right-4": size === "lg",
    "right-[14px]": size === "md",
    "right-3": size === "sm",
  });

  // Combine all classes
  const buttonClass = classNames(
    baseClasses,
    disabledClasses,
    variantClasses,
    sizeClasses,
    widthClass,
    className
  );

  //
  const leftIconClass = classNames(
      leftIconSizeClasses
  )
  const rightIconClass = classNames(
      rightIconSizeClasses
  )

  return (
      <button type={type} onClick={onClick} className={buttonClass} disabled={disabled}>
        {iconTail && <div className="w-5 mr-1 flex items-center justify-center"><img src={iconTail} alt="icon tail" className={`${leftIconClass} absolute z-10`}/></div>}
        {isLoading && <Spinner/>}
        {!isLoading && children}
        {iconLeading && <div className="w-5 ml-1 flex items-center justify-center"><img src={iconLeading}
                                                                                        alt="icon leading"
                                                                                        className={`${rightIconClass} absolute z-10`}/></div>}
        </button>
          );
        }
