import DummyPreviewTestimonial from "./DummyPreviewTestimonial";
import {VideoCameraIcon} from "@heroicons/react/24/outline";
import React, {useEffect, useState} from "react";

const src = "/img/auth-brand.png"
export default function AuthBrand() {
    const [imgSrc, setImgSrc] = useState("css");
    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setImgSrc(src);
        };
    }, []);
    return (
        <div className="flex-1 m-4 h-full pt-14 pb-24 px-6 bg-brand-900-600 rounded-[20px] min-h-[calc(100vh-32px)]">
            <div className="mb-24 lg:max-w-[60%] mx-auto">
                <h4 className="font-semibold text-4xl text-center text-white pb-2">The Testimonial App Everyone
                    Loves</h4>
                <p className="text-brand-200 font-medium text-base text-center">
                    Powerful, self-serve product and growth analytics to help you convert, engage, and retain more
                    users.
                </p>
            </div>
            <div className="w-full flex items-center justify-center">

                {imgSrc === "css" && <div className="bg-[rgba(255,255,255,0.5)] max-w-[600px] mx-auto w-full h-[600px] animate-pulse rounded-lg"/>}
                {imgSrc !== "css" && <img src={imgSrc} alt="Auth Brand" className="object-contain max-h-[600px]"/>}
            </div>
            {/*<div className="relative w-full flex items-center justify-center">*/}
            {/*    <div*/}
            {/*        className="left-[75px] bg-[rgba(255,255,255,0.9)] absolute top-[-50px]  z-20 p-4 rounded-xl max-w-[300px] drop-shadow-[0_-30px_16px_rgba(0,0,0,0.25)]">*/}
            {/*        <h5 className="text-base lg:text-lg text-gray-700 font-semibold text-center">*/}
            {/*            You’re done!*/}
            {/*        </h5>*/}
            {/*        <p className="text-gray-900 mt-0.5 text-sm lg:text-base text-center ">*/}
            {/*            Thanks a lot for sharing your valuable feedback! I really appreciate it!*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*    <DummyPreviewTestimonial/>*/}
            {/*    <div*/}
            {/*        className="left-0 right-0 bottom-[-90px] sm:left-auto lg:right-[75px] lg:bottom-[-50px] bg-[rgba(255,255,255,0.8)] absolute  z-20 p-6 xl:p-8 rounded-xl max-w-[300px] flex flex-col gap-4">*/}
            {/*        <button type="button"*/}
            {/*                className={`py-2.5 px-3.5 border rounded-lg shadow focus-visible:outline-brand-300 text-sm font-semibold flex justify-center items-center gap-1.5 max-h-10 border-brand-600 bg-brand-600 text-white min-w-[160px] lg:min-w-[200px] xl:min-w-[160px] 2xl:min-w-[200px] transition-all duration-500 hover:opacity-80`}>*/}
            {/*            <VideoCameraIcon className={`h-5 w-5 text-white}`}/>*/}
            {/*            Record a video*/}
            {/*        </button>*/}
            {/*        <button type="button"*/}
            {/*                className={`py-2.5 px-3.5 border rounded-lg shadow focus-visible:outline-brand-300 text-sm font-semibold flex  justify-center items-center gap-1.5 max-h-10 border-brand-300 bg-brand-50 text-brand-700 min-w-[160px] lg:min-w-[200px] xl:min-w-[160px] 2xl:min-w-[200px]  transition-all duration-500 hover:opacity-80`}>*/}
            {/*            <img*/}
            {/*                src={"/img/icons/pencil-selected.svg"}*/}
            {/*                alt={"Review"}*/}
            {/*                className="h-5 w-5"*/}
            {/*            />*/}
            {/*            Write a review*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*    <div*/}
            {/*        className="hidden lg:block absolute bottom-[-10px] left-[150px] 2xl:left-[200px] xl:left-[50px] z-20 w-20 h-20 border border-[rgba(255,255,255,0.3)] bg-[rgba(255,255,255,0.2)] rounded-2xl"/>*/}
            {/*</div>*/}
        </div>
    )
}