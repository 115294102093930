import React, {useEffect, useState} from "react";
import Button from "../Button";
import {useForm} from "react-hook-form";
import {FormProvider,RHFTextField} from "../common/RHF";
import RHFStarRating from "../common/RHF/RHFStarRating";
import RHFAvatar from "../common/RHF/RHFAvatar";
import RHFFile from "../common/RHF/RHFFile";
import PreviewTestimonial from "./PreviewTestimonial";
import API from "../../API";
import {toast} from "react-toastify";
import {dataUrlToFile} from "../../utils/utils";
import ThumbnailsSelection from "../Testimonial/ThumbnailsSelection";


export default function VideoTestimonial() {
    const formMethods = useForm({
        defaultValues:{
            name:"",
            position: "",
            email:"",
            stars: 0,
            video:undefined,
            // thumbnail:"",
            selectedThumbnail:0,
            allThumbnails: [],
            image:"/img/imagePlaceholder.svg"
        }
    })
    const [isLoading,setIsLoading] = useState(false)
    const {
        handleSubmit,
        watch,
        setError,
        reset
    } = formMethods

    async function onSubmit(data) {
        if (data?.stars <= 0){
            setError("stars", { type: 'custom', message: "Star is required" })
            return;
        }
        const formData = new FormData();
        for (const key in data) {
            // if (key === "thumbnail"){
            //     formData.append(key, await dataUrlToFile(getValues("allThumbnails")[selectedThumbnailIndex], "thumbnail"));
            // }
            if (key === "allThumbnails"){
                for (const allThumbnailsKey in data[key]) {
                    formData.append(`${key}`, await dataUrlToFile(data[key][allThumbnailsKey], `thumbnail-${allThumbnailsKey}`));
                }
            }
            else{
                formData.append(key, data[key]);
            }
        }
        try {
            setIsLoading(true)
            await API.handleVideoTestimonial(formData)
            toast.success("Success")
            reset();
        }catch (e) {
            toast.error(e.message)
        }finally {
            setIsLoading(false)
        }
    }

    return (
        <FormProvider methods={formMethods} onSubmit={handleSubmit(onSubmit)} className="flex flex-col lg:flex-row mt-6 gap-10 xl:gap-16">
            <div className="w-full max-w-lg">
                <div className="p-8 bg-white shadow-sm rounded-xl border border-gray-200 flex flex-col gap-6">
                    <RHFTextField
                        name="name"
                        label="Full name"
                        type="text"
                        placeholder={"John Doe"}
                        required
                    />
                    <RHFTextField
                        name="position"
                        label="Job title"
                        type="text"
                        placeholder={"Marketing Manager at Google"}
                    />
                    <RHFTextField
                        name="email"
                        label="Email address"
                        type="email"
                        autoComplete
                        placeholder={"johndoe@corp.com"}
                    />
                    <RHFTextField
                        name="date"
                        label="Date"
                        type="date"
                        autoComplete
                    />
                    <RHFStarRating
                        name="stars"
                        label="Rating*"
                        starClass="w-7 h-7"
                        required
                    />
                    <RHFFile
                        name="video"
                        label="Pick A Video*"
                        acceptType="video/*"
                        required
                        helperText="MP4, MOV, MKV or MPEG (max. 100mb)"
                    />
                    {watch("video") && (
                        <>
                            <video src={URL.createObjectURL(watch("video"))} controls className="w-full rounded-lg">
                                Your browser does not support the video tag.
                            </video>
                            <ThumbnailsSelection formMethods={formMethods}/>
                        </>
                    )}

                    <RHFAvatar name="image" size="sm" label={"Photo"}/>
                    <Button variant={"primary"} size="lg" isLoading={isLoading}>
                        Add Testimonial
                    </Button>
                </div>
            </div>
            <PreviewTestimonial video={watch("video")} name={watch("fullName")} avatar={watch("image")} position={watch("position")}/>
        </FormProvider>
    );
}
