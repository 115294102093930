import React from "react";

const badgeColors = {
  default: "bg-gray-50 text-gray-600 ring-gray-500/10",
  error: "bg-red-50 text-red-700 ring-red-600/10",
  warning: "bg-yellow-50 text-yellow-800 ring-yellow-600/20",
  success: "bg-green-50 text-green-700 ring-green-600/20",
  info: "bg-blue-50 text-blue-700 border-blue-200 border",
}

const badgeDotTheme = {
  default: "bg-gray-500",
  error: "bg-red-500",
  warning: "bg-yellow-500",
  success: "bg-green-500",
  info: "bg-blue-500",
}

export default function Badge({ type, text }) {
  const badgeClass = `inline-flex items-center px-2 py-1 text-xs rounded-full font-medium max-w-[140px] ${badgeColors[type]}`;

  return <span className={badgeClass}><div className={`rounded-full w-2 h-2 ${badgeDotTheme[type]} mr-1`}/>{text}</span>;
}
