import Button from "../Button";
import VideoRecorder from "../VideoRecorder2";
import React, {Fragment, useState} from "react";
import MainCreateFormWrapper from "./main-create-form-wrapper";
import {CREATE_FORM_TESTIMONIAL_TYPE} from "../../utils/constant";
import {FormProvider, RHFTextArea} from "../common/RHF";
import RHFInputField from "../common/RHF/RHFTextField";
import MainCreateFormCardHeader from "./main-create-form-card-header";
import RHFTextAreaWithMd from "../common/RHF/RHFTextAreaWithMd";
import MdTextPreview from "../common/text/md-text-preview";
import RHFStarRating from "../common/RHF/RHFStarRating";
import RHFFile from "../common/RHF/RHFFile";
import MobileSidebarWrapper from "../../layouts/mobile-sidebar-wrapper";
import IconButton from "../IconButton";
import {PauseIcon, StopIcon} from "@heroicons/react/24/solid";

export default function TestimonialForm({
  editable,
  cssClasses,
  items,
  setItems,
  handleCreateFormFlow,
  formMethods,
  testimonialMethods,
  setRightSidebarOpen,
  rightSidebarOpen
}) {
  const {watch} = formMethods;
  const [isRecording,setIsRecording] = useState(false);
  const [isUploading,setIsUploading] = useState(false);
  const {getValues: getTestimonialData, watch: watchTestimonial, setValue} = testimonialMethods;

  return (
    <>
      <MainCreateFormWrapper>
        <FormProvider methods={testimonialMethods} className={cssClasses.card}>
          {/*<div className="relative flex items-center justify-center w-full">*/}
          {/*  <div className="absolute top-1/2 -translate-y-1/2 left-0 w-full">*/}
          {/*    <Button variant="secondary-gray" onClick={() => handleCreateFormFlow(0, items, setItems)}>*/}
          {/*      <ArrowLeftIcon className="w-5 h-5 stroke-2"/>*/}
          {/*    </Button>*/}
          {/*  </div>*/}

            {/*<IconButton*/}
            {/*    className={"absolute top-4 left-4"}*/}
            {/*    onClick={() => handleCreateFormFlow(0, items, setItems)}*/}
            {/*    icon={ChevronLeftIcon}*/}
            {/*/>*/}
          {/*  <img*/}
          {/*      src={typeof watch("logo") === "string" ? watch("logo") : URL.createObjectURL(watch("logo"))}*/}
          {/*      alt="Logo"*/}
          {/*      className="w-16 h-16 object-contain border border-gray-200 rounded-full"*/}
          {/*  />*/}
          {/*</div>*/}
          <MainCreateFormCardHeader logo={watch("logo")} returnBack={!editable} backFunction={() => handleCreateFormFlow(0, items, setItems)}/>
          <div className="flex flex-col gap-2">
            <h1 className="text-base lg:text-lg text-gray-700 font-semibold text-left w-full">{watch('videoForm.title')}</h1>
            {/*<p className="text-gray-700 text-sm lg:text-base w-full text-left">*/}
            {/*  {watch('videoForm.description')}*/}
            {/*</p>*/}
            <MdTextPreview value={watch("videoForm.description")}/>
          </div>
          <div className="w-full">
            <RHFStarRating
                name="stars"
                starClass="w-9 h-9"
                rules={{
                  required: "Rating is required",
                }}
            />
            {/*<StarRating*/}
            {/*    starClass="w-9 h-9"*/}
            {/*    rating={testimonialData.stars}*/}
            {/*    onChange={(e) =>*/}
            {/*        setTestimonialData({*/}
            {/*          ...testimonialData,*/}
            {/*          stars: e,*/}
            {/*        })*/}
            {/*    }*/}
            {/*/>*/}
          </div>

          {(!getTestimonialData("type") || getTestimonialData("type") === CREATE_FORM_TESTIMONIAL_TYPE.VIDEO) && editable && (
              <div className="bg-gray-100 rounded-2xl w-full">
                <img
                    src="/img/recorder-image.jpg"
                    alt="video recorder"
                    className="w-full rounded-2xl"
                />
                <div className="flex items-center justify-center py-2.5 gap-2.5">
                  <IconButton buttonType={"button"} icon={PauseIcon} />
                  <IconButton
                      type="danger"
                      buttonType={"button"}
                      icon={StopIcon}
                  />
                </div>
              </div>
          )}
          {(!getTestimonialData("type") || getTestimonialData("type") === CREATE_FORM_TESTIMONIAL_TYPE.VIDEO) && !editable && (
              <>
                {!isUploading && <VideoRecorder
                    videoFile={getTestimonialData("video")}
                    setVideoFile={(v) => {
                      setIsRecording(true)
                      setIsUploading(false)
                      setValue("video", v);
                    }}
                />}
                {!isRecording && !isUploading && <div
                    className="relative text-base w-full h-7 leading-[30px] text-center before:absolute before:w-[150px] before:h-[1px] before:top-4 before:bg-gray-300 before:content after:absolute after:w-[150px] after:h-[1px] after:top-4 after:bg-gray-300 after:content before:left-0 after:right-0">
                  or
                </div>}
                {!isRecording && <RHFFile
                    name="video"
                    acceptType="video/*"
                    required
                    helperText="MP4, MOV, MKV or MPEG (max. 100mb)"
                    customContextClassname="!border-0 !p-0"
                    onChangeCb={() => {
                      setIsUploading(true)
                      setIsRecording(false)
                    }}
                    customContent={
                      <>
                        <div className="h-full w-full flex justify-center items-center">
                          <Button type="button" width="full" variant="secondary-gray">
                            <img src="/img/icons/upload.svg" alt="Upload"/>
                            {watchTestimonial("video") && !isRecording ? `Re-upload ` : `Upload `} a video
                          </Button>
                        </div>
                      </>
                    }
                    rules={{
                      required: "Video is required",
                    }}
                />}
                {watchTestimonial("video") && !isRecording && (
                    <>
                      <video src={URL.createObjectURL(watchTestimonial("video"))} controls
                             className="w-full rounded-lg max-h-[400px]">
                        Your browser does not support the video tag.
                      </video>
                    </>
                )}
              </>
          )}
          {/*{watchTestimonial("video") && (*/}
          {/*    <div className="w-full h-full">*/}
          {/*      <label className="block text-left text-xs lg:text-sm font-semibold leading-6 text-gray-700 mb-2">*/}
          {/*        Video Thumbnail*/}
          {/*      </label>*/}
          {/*      <VideoThumbnail*/}
          {/*          videoUrl={URL.createObjectURL(watchTestimonial("video"))}*/}
          {/*          thumbnailHandler={async (thumbnail) => setValue("thumbnail", await dataUrlToFile(thumbnail, "thumbnail"))}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*)}*/}
          {getTestimonialData("type") === CREATE_FORM_TESTIMONIAL_TYPE.TEXT &&
              <RHFTextArea name="testimonial" placeholder={'Write a testimonial'} rules={{
                required: "Testimonial is required",
              }}/>
          }
          <div className="flex flex-col gap-3 w-full">
            <Button
                type="button"
                size="lg"
                onClick={() => handleCreateFormFlow(2, items, setItems, false,true)}
            >
              <span>Submit</span>
            </Button>
          </div>
        </FormProvider>
      </MainCreateFormWrapper>
      {editable && (
          <>
            <MobileSidebarWrapper open={rightSidebarOpen} setOpen={setRightSidebarOpen}>
              <FormProvider methods={formMethods} className={cssClasses.sidebar}>
                <RHFInputField
                    id="videoFormTitle"
                    label="Title"
                    type="text"
                    name="videoForm.title"
                    // value={formDetails.videoForm.title}
                    // onChange={(e) =>
                    //   setFormDetails({
                    //     ...formDetails,
                    //     videoForm: {
                    //       ...formDetails.videoForm,
                    //       title: e.target.value,
                    //     },
                    //   })
                    // }
                />
                {/*<RHFTextArea*/}
                {/*    id="videoFormDescription"*/}
                {/*    label="Description"*/}
                {/*    name="videoForm.description"*/}
                {/*    // value={formDetails.videoForm.description}*/}
                {/*    // onChange={(e) =>*/}
                {/*    //   setFormDetails({*/}
                {/*    //     ...formDetails,*/}
                {/*    //     videoForm: {*/}
                {/*    //       ...formDetails.videoForm,*/}
                {/*    //       description: e.target.value,*/}
                {/*    //     },*/}
                {/*    //   })*/}
                {/*    // }*/}
                {/*/>*/}
                <div className="w-full">
                  <RHFTextAreaWithMd
                      label="Description"
                      name="videoForm.description"
                  />
                  <p className="text-xs text-gray-600 pt-1.5">
                    You can use markdown to format the text
                  </p>
                </div>
              </FormProvider>
            </MobileSidebarWrapper>

            <FormProvider methods={formMethods} className={`hidden ${cssClasses.sidebar}`}>
              <RHFInputField
                  id="videoFormTitle"
                  label="Title"
                  type="text"
                  name="videoForm.title"
                  // value={formDetails.videoForm.title}
                  // onChange={(e) =>
                  //   setFormDetails({
                  //     ...formDetails,
                  //     videoForm: {
                  //       ...formDetails.videoForm,
                  //       title: e.target.value,
                  //     },
                  //   })
                  // }
              />
              {/*<RHFTextArea*/}
              {/*    id="videoFormDescription"*/}
              {/*    label="Description"*/}
              {/*    name="videoForm.description"*/}
              {/*    // value={formDetails.videoForm.description}*/}
              {/*    // onChange={(e) =>*/}
              {/*    //   setFormDetails({*/}
              {/*    //     ...formDetails,*/}
              {/*    //     videoForm: {*/}
              {/*    //       ...formDetails.videoForm,*/}
              {/*    //       description: e.target.value,*/}
              {/*    //     },*/}
              {/*    //   })*/}
              {/*    // }*/}
              {/*/>*/}
              <div className="w-full">
                <RHFTextAreaWithMd
                    label="Description"
                    name="videoForm.description"
                />
                <p className="text-xs text-gray-600 pt-1.5">
                  You can use markdown to format the text
                </p>
              </div>
            </FormProvider>
          </>

      )}
    </>
  );
}
