import RHFInputField from "../common/RHF/RHFTextField";
import {NavLink} from "react-router-dom";
import Button from "../Button";
import {FormProvider} from "../common/RHF";
import React from "react";
import {registerUser} from "../../redux/authService";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {AUTH_SOURCE} from "../../utils/constant";
import {GoogleLogin} from "@react-oauth/google";
import {toast} from "react-toastify";

export default function RegisterForm({}) {
    const dispatch = useDispatch();
    const { status } = useSelector((state) => state);
    const methods = useForm()
    const { register, watch, handleSubmit } = methods;
    const windowWith = window.innerWidth
    const submitForm = async (formData) => {
        try {
            const res = await dispatch(
                registerUser({
                    email: formData.email,
                    password: formData.password,
                    fullName: formData.fullName,
                    authSource: AUTH_SOURCE.SELF
                })
            );
            if (typeof res?.payload === "string"){
                toast.error(res.payload)
            }
        } catch (ex) {
            console.log(ex,"gere");
        }
    };
    const responseMessage = (response) => {
        try {
            dispatch(
                registerUser({
                    clientId: response.clientId,
                    googleCredential: response.credential,
                    authSource: AUTH_SOURCE.GOOGLE
                })
            );
        } catch (ex) {
            console.log(ex);
        }
    };
    const errorMessage = (error) => {
        console.log(error,"e");
    };
    return(
        <>
            <FormProvider methods={methods} className="space-y-6" onSubmit={handleSubmit(submitForm)}>

                <RHFInputField
                    name="fullName"
                    label="Full Name"
                    type="text"
                    placeholder="Enter your name"
                    autoComplete="fullName"
                    required
                />
                <RHFInputField
                    name="email"
                    label="Email address"
                    type="email"
                    placeholder="Enter your email address"
                    autoComplete="email"
                    required
                />
                <RHFInputField
                    name="password"
                    label="Password"
                    type="password"
                    placeholder="Enter your password"
                    autoComplete="current-password"
                    required
                />

                <RHFInputField
                    name="confirmPassword"
                    label="Confirm Password"
                    placeholder="Confirm your password"
                    type="password"
                    autoComplete="password"
                    required
                    rules={{
                        validate:(val) => {
                            if (watch('password') !== val) {
                                return 'Your passwords do no match';
                            }
                        }
                    }}
                />

                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <input
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600"
                            {...register("rememberMe")}
                        />
                        <label
                            htmlFor="remember-me"
                            className="ml-3 block text-sm leading-6 text-gray-900"
                        >
                            Remember me
                        </label>
                    </div>

                    <div className="text-sm leading-6">
                        <NavLink
                            to="/forgot-password"
                            className="font-semibold text-brand-700 text-sm transition duration-500 hover:opacity-90"
                        >
                            Forgot password?
                        </NavLink>
                    </div>
                </div>

                <div>
                    <Button size="lg" variant="primary" width={"full"} type="submit" isLoading={status === "loading"}>
                        Get Started
                    </Button>
                </div>
            </FormProvider>
            <div className="mt-4 flex items-center justify-center">
                <GoogleLogin onSuccess={responseMessage} onError={errorMessage} text="signup_with" size="large" shape="square" width={windowWith < 420 ? "250" : "360"} logo_alignment="center" auto_select={false} />
            </div>
        </>
    )
}