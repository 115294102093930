import React from "react";
import { useFormContext, Controller } from "react-hook-form";

export default function RHFTextArea({
  name,
  label,
  placeholder,
  size = "md",
  autoComplete = "off",
  icon: Icon,
  required = false,
  className = "",
  defaultValue = "",
                                      rules={},
                                        rows= 5
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue} // Use defaultValue for initial form state
      render={({ field, fieldState: { error } }) => (
        <div className={`w-full text-left ${className}`}>
          {label && (
            <label
              htmlFor={name}
              className="block  text-xs lg:text-sm font-semibold leading-6 text-gray-700 mb-2"
            >
              {label}
            </label>
          )}
          <div className="relative">
            {Icon && (
              <Icon className="w-5 absolute left-3 h-full text-gray-600" />
            )}
            <textarea
              {...field}
              id={name}
              rows={rows}
              placeholder={placeholder}
              name={name}
              autoComplete={autoComplete}
              required={required}
              className={`block w-full rounded-md border-0 py-2.5 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-brand-300   text-xs lg:text-sm sm:leading-6 ${className} ${
                Icon && "pl-10"
              } ${size === "lg" && "py-3"}
              ${error && `ring-2 ring-red-500 focus:ring-red-500`}`}
              aria-describedby={`${name}-error`}
            >
              {field.value}
            </textarea>

            {error && (
              <p id={`${name}-error`} className="text-left text-red-500 text-xs mt-1">
                {error.message}
              </p>
            )}
          </div>
        </div>
      )}
    />
  );
}
