import Button from "../Button";
import MainCreateFormWrapper from "./main-create-form-wrapper";
import MainCreateFormCardHeader from "./main-create-form-card-header";
import React, {Fragment} from "react";
import {FormProvider, RHFAvatar, RHFTextField} from "../common/RHF";
import RHFInputField from "../common/RHF/RHFTextField";
import RHFCheckbox from "../common/RHF/RHFCheckbox";
import MobileSidebarWrapper from "../../layouts/mobile-sidebar-wrapper";

export default function DetailsForm({
  editable,
  cssClasses,
  items,
  setItems,
  handleCreateFormFlow,
  formMethods,
  isTestimonial,
  testimonialMethods,
  setRightSidebarOpen,
  rightSidebarOpen
}) {
    const {watch} = formMethods;
    return (
    <>
      <MainCreateFormWrapper>
            <FormProvider methods={testimonialMethods} className={cssClasses.card}>
              <MainCreateFormCardHeader logo={watch("logo")} returnBack={!editable} backFunction={() => handleCreateFormFlow(1, items, setItems)}/>
              <h1 className="text-base lg:text-lg text-gray-700 font-semibold w-full text-left">{watch("details.title")}</h1>
                {watch("details.nameEnable") && <RHFTextField
                    label={`Full name${watch("details.nameRequire") ? "*" : ""}`}
                    type="text"
                    name={"name"}
                    placeholder={"John Doe"}
                    className={"w-full"}
                    rules={{
                      required: watch("details.nameRequire"),
                    }}
                    // onChange={(e) =>
                    //     setTestimonialData({
                    //         ...testimonialData,
                    //         name: e.target.value,
                    //     })
                    // }
                />}
              {watch("details.emailEnable") && (
                <RHFTextField
                  label={`Email Address${watch("details.emailRequire") ? "*" : ""}`}
                  type="text"
                  name={"email"}
                  placeholder="johndoe@corp.com"
                  rules={{
                    required: watch("details.emailRequire"),
                  }}
                  // onChange={(e) =>
                  //   setTestimonialData({
                  //     ...testimonialData,
                  //     email: e.target.value,
                  //   })
                  // }
                />
              )}
                {watch("details.jobEnable") && <RHFTextField
                    label={`Job title${watch("details.jobRequire") ? "*" : ""}`}
                    type="text"
                    name="position"
                    placeholder="Marketing Manager at Google"
                    rules={{
                      required: watch("details.jobRequire"),
                    }}
                    // onChange={(e) =>
                    //     setTestimonialData({
                    //         ...testimonialData,
                    //         position: e.target.value,
                    //     })
                    // }
                />}
                {watch("details.photoEnable") && <RHFAvatar
                    label={`Photo${watch("details.photoRequire") ? "*" : ""}`}
                    size="sm"
                    name="image"
                    rules={{
                      required: watch("details.photoRequire"),
                    }}
                    // onChange={(e) => {
                    //     // if (testimonialData.image) {
                    //     //     URL.revokeObjectURL(testimonialData.image);
                    //     // }
                    //     setTestimonialData({
                    //         ...testimonialData,
                    //         image: e,
                    //     });
                    // }}
                />}
              <hr className="w-full" />
              <div className="flex flex-col gap-3 w-full">
                <Button
                  type="button"
                  size="lg"
                  onClick={() => handleCreateFormFlow(3, items, setItems, isTestimonial, true)}
                >
                  <span>Submit</span>
                </Button>
              </div>
              <p className="text-gray-500 text-center text-xs ">
                By submitting, you give us permission to use this testimonial across social channels and other marketing efforts
              </p>
            </FormProvider>
        </MainCreateFormWrapper>
      {editable && (
         <>
             <MobileSidebarWrapper open={rightSidebarOpen} setOpen={setRightSidebarOpen}>
                 <FormProvider methods={formMethods} className={cssClasses.sidebar}>
                     <RHFInputField
                         id="detailsTitle"
                         label="Title"
                         type="text"
                         name="details.title"
                         /*value={formDetails.details.title}
                         onChange={(e) =>
                           setFormDetails({
                             ...formDetails,
                             details: {
                               ...formDetails.details,
                               title: e.target.value,
                             },
                           })
                         }*/
                     />
                     {/*<RHFToggle*/}
                     {/*    title={"Require email address"}*/}
                     {/*    name="details.email"*/}
                     {/*    // on={formDetails.details.email}*/}
                     {/*    // onChange={(newEmailEnabled) => {*/}
                     {/*    //   setFormDetails((prevDetails) => ({*/}
                     {/*    //     ...prevDetails,*/}
                     {/*    //     details: {*/}
                     {/*    //       ...prevDetails.details,*/}
                     {/*    //       email: newEmailEnabled,*/}
                     {/*    //     },*/}
                     {/*    //   }));*/}
                     {/*    // }}*/}
                     {/*/>*/}
                     <div className="flex flex-col gap-3 w-full">
                         <h5 className="text-xs lg:text-sm font-semibold leading-6 text-gray-700">
                             Collect Name
                         </h5>
                         <div className="w-full flex items-center opacity-50">
                             <RHFCheckbox name="details.nameEnable" label="Enable" className="flex-1" disabled/>
                             <RHFCheckbox name="details.nameRequire" label="Required" className="flex-1" disabled/>
                         </div>
                     </div>
                     <div className="flex flex-col gap-3 w-full">
                         <h5 className="text-xs lg:text-sm font-semibold leading-6 text-gray-700">
                             Collect Email Address
                         </h5>
                         <div className="w-full flex items-center">
                             <RHFCheckbox name="details.emailEnable" label="Enable" className="flex-1"/>
                             <RHFCheckbox name="details.emailRequire" label="Required" className="flex-1"/>
                         </div>
                     </div>
                     <div className="flex flex-col gap-3 w-full">
                         <h5 className="text-xs lg:text-sm font-semibold leading-6 text-gray-700">
                             Collect Job Title
                         </h5>
                         <div className="w-full flex items-center">
                             <RHFCheckbox name="details.jobEnable" label="Enable" className="flex-1"/>
                             <RHFCheckbox name="details.jobRequire" label="Required" className="flex-1"/>
                         </div>
                     </div>
                     <div className="flex flex-col gap-3 w-full">
                         <h5 className="text-xs lg:text-sm font-semibold leading-6 text-gray-700">
                             Collect User Photo
                         </h5>
                         <div className="w-full flex items-center justify-between">
                             <RHFCheckbox name="details.photoEnable" label="Enable" className="flex-1"/>
                             <RHFCheckbox name="details.photoRequire" label="Required" className="flex-1"/>
                         </div>
                     </div>
                 </FormProvider>
             </MobileSidebarWrapper>

           <FormProvider methods={formMethods} className={`hidden ${cssClasses.sidebar}`}>
             <RHFInputField
                 id="detailsTitle"
                 label="Title"
                 type="text"
                 name="details.title"
                 /*value={formDetails.details.title}
                 onChange={(e) =>
                   setFormDetails({
                     ...formDetails,
                     details: {
                       ...formDetails.details,
                       title: e.target.value,
                     },
                   })
                 }*/
             />
             {/*<RHFToggle*/}
             {/*    title={"Require email address"}*/}
             {/*    name="details.email"*/}
             {/*    // on={formDetails.details.email}*/}
             {/*    // onChange={(newEmailEnabled) => {*/}
             {/*    //   setFormDetails((prevDetails) => ({*/}
             {/*    //     ...prevDetails,*/}
             {/*    //     details: {*/}
             {/*    //       ...prevDetails.details,*/}
             {/*    //       email: newEmailEnabled,*/}
             {/*    //     },*/}
             {/*    //   }));*/}
             {/*    // }}*/}
             {/*/>*/}
             <div className="flex flex-col gap-3 w-full">
               <h5 className="text-xs lg:text-sm font-semibold leading-6 text-gray-700">
                 Collect Name
               </h5>
               <div className="w-full flex items-center opacity-50">
                 <RHFCheckbox name="details.nameEnable" label="Enable" className="flex-1" disabled/>
                 <RHFCheckbox name="details.nameRequire" label="Required" className="flex-1" disabled/>
               </div>
             </div>
             <div className="flex flex-col gap-3 w-full">
               <h5 className="text-xs lg:text-sm font-semibold leading-6 text-gray-700">
                 Collect Email Address
               </h5>
               <div className="w-full flex items-center">
                 <RHFCheckbox name="details.emailEnable" label="Enable" className="flex-1"/>
                 <RHFCheckbox name="details.emailRequire" label="Required" className="flex-1"/>
               </div>
             </div>
             <div className="flex flex-col gap-3 w-full">
               <h5 className="text-xs lg:text-sm font-semibold leading-6 text-gray-700">
                 Collect Job Title
               </h5>
               <div className="w-full flex items-center">
                 <RHFCheckbox name="details.jobEnable" label="Enable" className="flex-1"/>
                 <RHFCheckbox name="details.jobRequire" label="Required" className="flex-1"/>
               </div>
             </div>
             <div className="flex flex-col gap-3 w-full">
               <h5 className="text-xs lg:text-sm font-semibold leading-6 text-gray-700">
                 Collect User Photo
               </h5>
               <div className="w-full flex items-center justify-between">
                 <RHFCheckbox name="details.photoEnable" label="Enable" className="flex-1"/>
                 <RHFCheckbox name="details.photoRequire" label="Required" className="flex-1"/>
               </div>
             </div>
           </FormProvider>
         </>
      )}
    </>
      );
}
