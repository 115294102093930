import classNames from "classnames";
import { StarIcon } from "@heroicons/react/24/solid";
import React, {useState} from "react";
import {useController, useFormContext} from "react-hook-form";

const RHFStarRating = ({
                        totalStars = 5,
                        starClass = "w-5 h-5 stars",
                        label,
                        starDiv,
                           rules = {},
                           name
                    }) => {
    const { control } = useFormContext();
    const [onHover,setOnHover] = useState(null)
    const {
        field: { value, onChange },
        fieldState: { error },
    } = useController({ name, control, rules });
    const Star = ({ index }) => {
        const filled = index < value;
        const combinedStarClass = classNames(
            starClass,
            "cursor-pointer",
            { "hover:text-[#FDB022]": !!onChange && !filled }, // Apply hover class only if onChange is provided and star is not filled
            { "text-[#FDB022]": filled || index < onHover, "text-gray-300": !filled && index >= onHover }
        );
        return (
            <div className={combinedStarClass} onMouseEnter={() => setOnHover(index + 1)} onMouseLeave={() => setOnHover(null)} onClick={() => onChange(index + 1)}>
                <StarIcon />
            </div>
        );
    };

    return (
        <div>
            {label && (
                <label className="block  text-xs lg:text-sm font-medium leading-6 text-gray-900">
                    {label}
                </label>
            )}
            <div className={`${classNames(`flex mt-2 ${starDiv}`)}`}>
                {[...Array(totalStars)].map((_, i) => (
                    <Star key={i} index={i} />
                ))}
            </div>
            {error && (
                <p id={`${name}-error`} className="text-left text-red-500 text-xs mt-1">
                    {error.message}
                </p>
            )}
        </div>
    );
};

export default RHFStarRating;
