import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {ArrowLeftIcon, ArrowRightIcon, ChevronRightIcon} from "@heroicons/react/24/outline";
import API from "API";
import Loader from "components/Loader";
import classNames from "classnames";
import {useForm } from "react-hook-form";
import {RHFTextField } from "components/common/RHF";
import Button from "components/Button";
import {FormProvider, RHFTextArea} from "../common/RHF";
import {NavLink, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

export default function FormSendInvitePopover({ open, setOpen, formId }) {
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchForm = async () => {
    try {
      setError(null);
      setLoading(true);
      const response = await API.loadForm({ id: formId });
      if (response.error) {
        setError(response.error);
      } else {
        setForm(response.result);
      }
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchForm();
  }, [formId]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="relative z-[999]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-[100vw] lg:max-w-[80vw] 2xl:max-w-[60vw]">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-8 shadow-xl">
                    {/* error */}
                    {error && (
                      <div
                        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                        role="alert"
                      >
                        <strong className="font-bold">Error!</strong>
                        <span className="block sm:inline">{error}</span>
                      </div>
                    )}

                    {loading ? (
                      <Loader loadingText="loading forms" />
                    ) : (
                      <>
                        {/* header */}
                        <div className="flex border-b border-gray-200 items-center gap-5 px-4 pb-4">
                          <button
                              type="button"
                              className="h-9 w-9 flex items-center justify-center rounded-lg hover:bg-gray-50 relative"
                              onClick={() => setOpen(false)}
                          >
                            <span className="absolute -inset-2.5"/>
                            <span className="sr-only">Close panel</span>
                            <ArrowLeftIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                          </button>
                          <div className="">
                            <h1 className="lg:text-3xl text:xl font-semibold text-gray-900">
                              Share Your From
                            </h1>
                            <p className="lg:text-base text-sm text-gray-600">
                              {form?.title || form?.formData?.welcome?.title}
                            </p>
                          </div>
                        </div>
                        {/* body */}
                        <Body formId={formId} setOpen={setOpen}/>
                      </>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}


const Body = ({formId, setOpen}) => {
  const methods = useForm();
  const {handleSubmit} = methods;
  const [currentStep, setCurrentStep] = useState(1);

  const steps = [
    {
      title: "Details",
      body: <DetailForm/>,
      handleNext: () => console.log("next"),
    },
    {
      title: "Send",
      body: <SendInvite formMethods={methods}/>,
      handleNext: () => console.log("next"),
    },
  ];

  // submitting update testimonial
  const onSubmit = async (data) => {
    if (currentStep === steps.length){
      try {
        await API.sendTestimonialInviteEmail({...data, formId});
        toast.success("Invite Sent Successfully");
        setOpen(false)
      } catch (error) {
        console.log(error);
      }
    }else{
      setCurrentStep((prev) => prev + 1);
    }
  };
  return (
    <>
      <FormProvider onSubmit={handleSubmit(onSubmit)} methods={methods} className="grid grid-cols-1 lg:grid-cols-2 gap-6 px-5 lg:pr-0 lg:pl-[72px]">
        <Form
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          steps={steps}
        />
        <EmailView methods={methods} formId={formId}/>
      </FormProvider>
    </>
  );
};
const Form = ({ steps, currentStep, setCurrentStep }) => {
  return (
    <>
      <div className="flex flex-col justify-start">
        <div className="flex justify-start py-6">
          {steps?.map((step, i) => {
            const stepClasses = classNames("step-item cursor-pointer", {
              active: currentStep === i + 1,
              complete: i + 1 < currentStep,
            });

            return (
              <div
                key={i}
                className={stepClasses}
                onClick={() => setCurrentStep(i + 1)}
              >
                <p
                  className={`flex text-sm pr-2  ${
                    currentStep === i + 1
                      ? "text-brand-700 font-semibold"
                      : "text-gray-600 hover:text-gray-800 font-medium"
                  }`}
                >
                  {step.title}
                  {steps.length - 1 !== i && (
                    <ChevronRightIcon className="h6 w-6 pl-2 !text-gray-300" />
                  )}
                </p>
              </div>
            );
          })}
        </div>
        {/* body */}
        {steps[currentStep - 1].body}
      </div>
    </>
  );
};
const EmailView = ({methods,formId}) => {
  const {watch} = methods;
  const navigate = useNavigate();
  return (
    <div className="p-4 md:p-8 bg-brand-50 w-full h-full">
      <div className="shadow-email-preview rounded-xl bg-white ">
        <div className="p-3 border-b border-gray-200 relative">
          <div className="flex items-center gap-2 absolute left-3 top-1/2 -translate-y-1/2">
            <div className="w-3 h-3 rounded-full bg-[#FF5F57]"/>
            <div className="w-3 h-3 rounded-full bg-[#FEBC2E]"/>
            <div className="w-3 h-3 rounded-full bg-[#28C840]"/>
          </div>
          <h5 className="text-gray-500 text-xs font-bold text-center">New message</h5>
        </div>
        <div className="p-3 border-b border-gray-200 flex flex-col gap-1 items-start">
          <p className="text-gray-800 text-[13px]">From: noreply@testimonial.com</p>
          <p className="text-gray-800 text-[13px]">To: {` ${watch("email") || "example@example.com"}`}</p>
        </div>
        <div className="p-3 border-b border-gray-200 flex flex-col gap-1 items-start">
          <h6 className="text-gray-900 font-medium text-[13px]">{watch("subject") || "Leave a testimonial John!"}</h6>
        </div>
        <div className="bg-gray-50 p-4 md:p-12 rounded-b-xl">
          <div className="bg-white shadow-email-preview-message rounded-xl border border-gray-200 p-4 md:p-8">
            <p className="whitespace-pre-wrap text-sm lg:text-base text-gray-900 pb-6">
              {watch("message") || "Hey John 👋\n\nDo you have a moment to spare? We're eager to hear your thoughts! Your feedback is invaluable in ensuring we provide the best experience possible.\n\nClick the link below to share a testimonial with us!\n\nThanks,\nJohn"}
            </p>
            <Button type="button" width="full" onClick={() => navigate(`/testimonial/${formId}`)}>
              Leave a testimonial
            </Button>
            <p className="text-xs text-gray-500 pt-6 overflow-hidden overflow-ellipsis">
              Button not working? Paste this URL in the browser <NavLink className="text-blue-light-600" to={`/testimonial/${formId}`}>
              {` ${window.location.origin}/testimonial/${formId}`}
            </NavLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const DetailForm = () => {
  return (
      <div className="">
        <div className="mb-6">
          <h3 className="pb-6 text-gray-900 text-base lg:text-lg font-semibold">Customer information</h3>
          <div className="flex flex-col gap-4">
            <RHFTextField name="name" label={"Full name"} placeholder="enter name" required/>
            <RHFTextField name="email" label={"Email Address"} placeholder="enter email" required/>
          </div>
        </div>
        <div className="mb-6">
          <h3 className="pb-6 text-gray-900 text-base lg:text-lg font-semibold">Custom message</h3>
          <div className="flex flex-col gap-4">
            <RHFTextField name="subject" label={"Subject"} placeholder="enter subject" required/>
            <RHFTextArea name="message" label={"Message"} placeholder="enter message" rows={10} required/>
          </div>
        </div>
        <Button variant="secondary" type="submit" className={"w-full"} size="lg">
          Next
          <ArrowRightIcon className="h-6 w-6"/>
        </Button>
      </div>
  );
};

const SendInvite = ({formMethods}) => {
  const {handleSubmit, formState:{isSubmitting}, watch} = formMethods;

  return (
      <div className="">
        <div className="mb-6">
          <h3 className="pb-3 text-gray-900 text-base lg:text-lg font-semibold">Ready to request a testimonial?</h3>
          <p className="text-gray-900 text-sm lg:text-base">
            You're about to invite {` ${watch("email")} `} to leave a testimonial with an immediate email invitation.
          </p>
        </div>
        <Button variant="primary" type="submit" className={"w-full"} size="lg" isLoading={isSubmitting}>
          Send Invite
          <ArrowRightIcon className="h-6 w-6"/>
        </Button>
      </div>
  );
};
