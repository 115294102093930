import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
const Dropdown = ({ menus }) => {
  return (
    <div className="w-full">
      <div className="mx-auto w-full max-w-md rounded-2xl bg-white p-2 space-y-5">
        {menus.map((menu, i) => {
          const isLastItem = i === menus.length - 1;
          return (
            <Disclosure key={i}>
              {({ open }) => (
                <div
                  className={`${isLastItem ? "" : "border-b border-gray-200"}`}
                >
                  <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                    <div className="flex items-center space-x-2">
                      <img src={menu.icon} alt="Icon" width={16} height={16} />
                      <span className="text-sm font-semibold flex items-center">
                        {menu.name}
                      </span>
                    </div>
                    <ChevronDownIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="pb-2 pt-4 text-sm text-gray-500 transition-transform">
                    {menu.component}
                  </Disclosure.Panel>
                </div>
              )}
            </Disclosure>
          );
        })}
      </div>
    </div>
  );
};

export default Dropdown;
