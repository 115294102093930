import DefaultTestimonial from "serverTemplates/T0";
import TestimonialHeader from "components/Testimonial/Header";
import TestimonialEditPanel from "layouts/TestimonialEditPanel";
import TestimonialSidebar from "layouts/TestimonialSidebar";
import { useCallback, useEffect, useState } from "react";
import API from "API";
import Testimonial from "layouts/customize/Testimonials";
import Backgrounds from "layouts/customize/Backgrounds";
import Resize from "layouts/customize/Resize";
import { FRAME_0 } from "utils/constant";
import TestimonialOne from "serverTemplates/T1";
import TestimonialFour from "serverTemplates/T4";
import TestimonialThree from "serverTemplates/T3";
import TestimonialTwo from "serverTemplates/T2";
// import TestimonialOne from "serverTemplates/T1";
// import TestimonialOne from "serverTemplates/T1";
// import TestimonialOne from "serverTemplates/T1";
// import TestimonialTwo from "serverTemplates/T2";
// import TestimonialThree from "serverTemplates/T3";
// import TestimonialFour from "serverTemplates/T4";

const TestimonialModifier = () => {
  const [title, setTitle] = useState("Edit Testimonial");
  const [testimonialsTemplates, setTestimonialsTemplates] = useState([]);
  const [backgrounds, setBackgrounds] = useState([]);
  const [resize, setResize] = useState([]);

  const [dimensionTestimonial, setDimensionTestimonial] = useState([]);

  const [loading, setLoadng] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [templateId, setTemplateId] = useState("");
  const [testimonialHeigth, setTestimonialHeight] = useState(undefined);
  const [testimonialWidth, setTestimonialWidth] = useState(undefined);

  const [backgroundItem, setBackgroundItem] = useState(FRAME_0);
  const [showtemplates, setShowTemplates] = useState(false);
  const [selectedResizeId, setSelectedResizeId] = useState(undefined);
  const [resizeWidth, setResizeWidth] = useState(undefined);
  const [resizeHeight, setResizeHeight] = useState(undefined);

  const [showAvatar, setShowAvatar] = useState(true);
  const [showRating, setShowRating] = useState(true);

  const testimonialPlaceholder = {
    testimonyText: "",
    name: "",
    label: "",
    image: "",
  };
  const [testimonial, setTestimonial] = useState(testimonialPlaceholder);

  useEffect(() => {
    API.getTemplates()
      .then((res) => {
        setTestimonialsTemplates(res?.result?.template || []);

        setBackgrounds(res?.result?.background || []);
        setResize(res?.result?.resize || []);
      })
      .catch((err) => {});
  }, []);

  const handleBackground = useCallback((value) => {
    // setImageURL(index);

    setBackgroundItem(value);
    testimonial.background = value;
  }, []);

  const onTestimonialClick = (id) => {
    setTemplateId(id);

    if (showtemplates?.name === id) {
      return;
    } else {
      setShowAvatar(true);
      setShowRating(true);
      const inputs = document.querySelectorAll(".inputs");
      inputs.forEach((input) => {
        input.value = "";
      });
    }
  };

  useEffect(() => {
    if (selectedResizeId) {
      const parentDiv = document.querySelector(".parent_div");
      const selectedTemplate = document.querySelector(".selected_template");
      const mainDiv = document.getElementById("2");

      const ratingIcon = document.querySelector("#rating");

      // console.log(ratingIcon, "ratingIcon");

      // DOM

      const testimonialOne = document.getElementById("1");

      const testoneAvatar = document.querySelector(".test_one_avatar");
      const testimonialText = document.getElementById("testimonial_text");
      const test_one_label = document.querySelector(".test_one_para");
      const authorDetails = document.querySelector(".author_details_one_div");

      //

      const testimonialFour = document.querySelector(".testimonial_four");
      const testimonialFourDiv = document.querySelector(
        ".testimonial_four_div"
      );

      const testimonialFourImg = document.querySelector(
        ".testimonial_four_image"
      );

      const testimonialFourRating = document.querySelector(
        ".testimonial_four_rating"
      );

      const testimonial_four_avatar = document.querySelector(
        ".testimonial_four_avatar"
      );

      // // Get the aspect ratio of the parent div
      const aspectRatio = testimonialWidth / testimonialHeigth;
      const widthDiv = parentDiv?.offsetWidth;
      const heightDiv = parentDiv?.offsetHeight;
      const lowestDimension = Math.min(widthDiv, heightDiv);

      const resizeDivWidth = Math.round(lowestDimension * aspectRatio);
      const resizeDivHeight = Math.round(resizeDivWidth / aspectRatio);

      if (mainDiv) {
        mainDiv.style.width = `${resizeDivWidth}px`;
        mainDiv.style.padding = `0 50px`;
      }
      if (selectedTemplate) {
        selectedTemplate.style.width = `auto`;
        selectedTemplate.style.margin = 0;
      }
      setResizeWidth(resizeDivWidth);
      setResizeHeight(resizeDivHeight);

      if (testimonialWidth === "1280" || testimonialWidth === "1200") {
        // setResizeWidth(prevWidth);
        // setResizeHeight(prevHeight);

        if (testoneAvatar) {
          testoneAvatar.style.width = ""; // Reset width
          testoneAvatar.style.height = ""; // Reset height
          if (test_one_label) {
            test_one_label.style.fontSize = "16px"; // Reset font size
          }
          if (testimonialOne) {
            testimonialOne.children[0].style.flexDirection = ""; // Reset flex direction
            authorDetails.parentElement.style.paddingBottom = ""; // Reset padding
            testimonialOne.children[0].style.marginBlock = ""; // Reset margin
          }

          if (testimonialText) {
            testimonialText.style.fontSize = "16px";
            testimonialText.style.textAlign = "left";
          }

          if (authorDetails) {
            authorDetails.style.textAlign = "left";
          }

          if (ratingIcon) {
            ratingIcon.style.display = "";
            ratingIcon.style.justifyContent = "";
            ratingIcon.style.alignItems = "";
          }
        }

        if (testimonialFour) {
          testimonialFour.style.flexDirection = "";
          testimonialFour.style.justifyContent = "";
          testimonialFour.style.transform = "";
          testimonialFourDiv.style.height = "";
          testimonialFourRating.parentElement.style.borderRadius = "";
          testimonialFourImg.style.objectFit = "";
          testimonial_four_avatar.style.width = "";
          testimonial_four_avatar.style.height = "";
        }
      }
      if (testimonialWidth === "1080" || testimonialWidth === "1920") {
        if (testoneAvatar) {
          testoneAvatar.style.width = "";
          testoneAvatar.style.height = "";
          test_one_label.style.fontSize = "14px";
          // test_one_label?.nextSibling?.style?.fontSize = "10px";
          testimonialOne.children[0].style.flexDirection = "column";
          authorDetails.parentElement.style.paddingBottom = 0;
          testimonialOne.children[0].style.marginBlock = "20px";
          authorDetails.style.textAlign = "center";

          // ICON RATING

          // testimonialOne.nextElementSibling.style.flexDirection = "column";

          if (testimonialText) {
            testimonialText.style.fontSize = "14px";
            testimonialText.style.textAlign = "center";
          }

          if (ratingIcon) {
            ratingIcon.style.display = "flex";
            ratingIcon.style.justifyContent = "center";
            ratingIcon.style.alignItems = "center";
          }
        }

        // testimonialFour

        if (testimonialFour) {
          testimonialFour.style.flexDirection = "column";
          testimonialFour.style.justifyContent = "center";
          testimonialFourRating.parentElement.style.borderRadius = 0;
          testimonialFourDiv.style.height = "230px";
          testimonialFourImg.style.objectFit = "cover";

          testimonial_four_avatar.style.width = "100%";
          testimonial_four_avatar.style.height = "100%";
          // testimonialFour.style.transform = "scale(0.9)";
          // testimonialFourDiv.style.height = "250px";
        }
      }
    }
  }, [
    showtemplates?.name,
    testimonialWidth,
    selectedResizeId,
    resizeWidth,
    resizeHeight,
    testimonialHeigth,
  ]);

  const onDimensionClick = (width, height, id, dimension) => {
    setSelectedResizeId(id);
    setTestimonialWidth(width);
    setTestimonialHeight(height);
    setDimensionTestimonial([dimension]);
  };

  const menus = [
    {
      icon: "/img/template.svg",
      name: "Templates",
      component: (
        <Testimonial
          testimonial={testimonialsTemplates}
          onTestimonialClick={onTestimonialClick}
        />
      ),
    },
    {
      icon: "/img/background.svg",
      name: "Background",
      component: (
        <Backgrounds
          background={backgrounds}
          handleBackground={handleBackground}
          backgroundItem={backgroundItem}
        />
      ),
    },
    {
      icon: "/img/resize.svg",
      name: "Resize",
      component: (
        <Resize
          resize={resize}
          onDimensionClick={onDimensionClick}
          selectedResizeId={selectedResizeId}
        />
      ),
    },
  ];

  useEffect(() => {
    const findTemplate = testimonialsTemplates?.find?.(
      (item) => item.name === templateId
    );
    setLoadng(true);
    setShowTemplates(findTemplate);
  }, [templateId, testimonialsTemplates]);

  const dimensions = selectedResizeId
    ? {
        width: `${resizeWidth}px`,
        // height: `${resizeHeight}px`,
      }
    : {};

  return (
    <>
      <div className="h-screen flex flex-col">
        <TestimonialHeader
          title={title}
          setTitle={setTitle}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          testimonial={testimonial}
          showAvatar={showAvatar}
          showRating={showRating}
          background={backgroundItem}
          dimension={dimensionTestimonial}
          templateId={templateId}
        />
        <div className="flex h-screen justify-between items-center">
          <TestimonialSidebar
            menus={menus}
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
          <div className='w-full parent_div flex justify-center items-center h-full bg-[url("../public/img/dotted.png")] bg-cover bg-no-repeat overflow-hidden '>
            {" "}
            <div
              className={
                "relative w-full flex justify-center items-center px-6"
              }
            >
              <div className="w-[700px] h-[520px]" style={{ ...dimensions }}>
                {backgroundItem?.includes?.("uploads") ? (
                  <img
                    src={`${
                      backgroundItem === FRAME_0
                        ? `${process.env.REACT_APP_API_URL}${FRAME_0}`
                        : `${process.env.REACT_APP_API_URL}${backgroundItem}`
                    }`}
                    alt="FRAME"
                    className="w-full h-full"
                  />
                ) : (
                  <div
                    className="h-full w-full"
                    style={{
                      backgroundColor: `${backgroundItem}`,
                    }}
                  ></div>
                )}
              </div>

              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "auto",
                }}
              >
                <div className="px-4 w-[500px] sm:w-[700px] mx-auto" id={2}>
                  {showtemplates ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: showtemplates?.html || "",
                      }}
                    />
                  ) : (
                    <TestimonialOne />
                  )}
                </div>
              </div>
            </div>
          </div>

          <TestimonialEditPanel
            setShowAvatar={setShowAvatar}
            setShowRating={setShowRating}
            showAvatar={showAvatar}
            showRating={showRating}
            testimonial={testimonial}
          />
        </div>
      </div>
    </>
  );
};

export default TestimonialModifier;
