import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const sampleData = [
    {
        id: 1,
        type: "video",
        text:"",
        rating: 5,
        date: "2023-11-02",
        video_mp4_url:"video url",
        customer_name:"john doe",
        customer_email:"johndoe@exmaple.com",
        customer_avatar:"avatar url",
        customer_tagline:"john manager",
    },
    {
        id: 2,
        type: "text",
        text:"this is testimonial",
        rating: 5,
        date: "2023-11-02",
        video_mp4_url:"",
        customer_name:"john doe",
        customer_email:"johndoe@exmaple.com",
        customer_avatar:"avatar url",
        customer_tagline:"john manager",
    },
]
const ImportCSVTable = () => {
    const navigate = useNavigate();
    const { isAuthenticated, user } = useSelector((state) => state);

    React.useEffect(() => {
        if (!user) navigate('/login');
    }, [isAuthenticated, navigate, user]);


    return (
        <div className="w-full xl:max-w-[50%] lg:mt-6">
            <div className="text-center flex flex-col">
                <div className="text-gray-500 font-semibold text-xl lg:text-2xl">Sample table format</div>
                <div className="mt-4 relative after:left-0 after:absolute after:bottom-0 after:w-full after:z-10 after:h-10 after:bg-table-bottom-gradient">
                    <div className="overflow-auto max-h-64 shadow relative ring-1 ring-black ring-opacity-5 sm:rounded-lg ">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-100">
                            <tr>
                                <th
                                    scope="col"
                                    className="py-4 px-3 text-left font-medium text-xs text-gray-600"
                                >
                                    type
                                </th>
                                <th
                                    scope="col"
                                    className="py-4 px-3 text-left font-medium text-xs text-gray-600"
                                >
                                    text
                                </th>
                                <th
                                    scope="col"
                                    className="py-4 px-3 text-left font-medium text-xs text-gray-600"
                                >
                                    rating
                                </th>
                                <th
                                    scope="col"
                                    className="py-4  px-3 text-left font-medium text-xs text-gray-600"
                                >
                                    date
                                </th>
                                <th
                                    scope="col"
                                    className="py-4  px-3 text-left font-medium text-xs text-gray-600"
                                >
                                    video_mp4_url
                                </th>
                                <th
                                    scope="col"
                                    className="py-4  px-3 text-left font-medium text-xs text-gray-600"
                                >
                                    customer_name
                                </th>
                                <th
                                    scope="col"
                                    className="py-4  px-3 text-left font-medium text-xs text-gray-600"
                                >
                                    customer_email
                                </th>
                                <th
                                    scope="col"
                                    className="py-4  px-3 text-left font-medium text-xs text-gray-600"
                                >
                                    customer_avatar
                                </th>
                                <th
                                    scope="col"
                                    className="py-4  px-3 text-left font-medium text-xs text-gray-600"
                                >
                                    customer_tagline
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {sampleData.map(el => (
                                <tr key={el.id}>
                                    <td className="whitespace-nowrap py-4 px-3 ">
                                        <p className="text-left text-xs font-medium text-gray-400">{el.type}</p>
                                    </td>
                                    <td className="whitespace-nowrap py-4 px-3 ">
                                        <p className="text-left text-xs font-medium text-gray-400">{el.text}</p>
                                    </td>
                                    <td className="whitespace-nowrap py-4 px-3 ">
                                        <p className="text-left text-xs font-medium text-gray-400">{el.rating}</p>
                                    </td>
                                    <td className="whitespace-nowrap py-4 px-3 ">
                                        <p className="text-left text-xs font-medium text-gray-400">{el.date}</p>
                                    </td>
                                    <td className="whitespace-nowrap py-4 px-3 ">
                                        <p className="text-left text-xs font-medium text-gray-400">{el.video_mp4_url}</p>
                                    </td>
                                    <td className="whitespace-nowrap py-4 px-3 ">
                                        <p className="text-left text-xs font-medium text-gray-400">{el.customer_name}</p>
                                    </td>
                                    <td className="whitespace-nowrap py-4 px-3 ">
                                        <p className="text-left text-xs font-medium text-gray-400">{el.customer_email}</p>
                                    </td>

                                    <td className="whitespace-nowrap py-4 px-3 ">
                                        <p className="text-left text-xs font-medium text-gray-400">{el.customer_avatar}</p>
                                    </td>
                                    <td className="whitespace-nowrap py-4 px-3 ">
                                        <p className="text-left text-xs font-medium text-gray-400">{el.customer_tagline}</p>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImportCSVTable;
