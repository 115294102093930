import MDEditor from "@uiw/react-md-editor";
import React from "react";
import {useFormContext, useController} from "react-hook-form";

export default function RHFTextAreaWithMd({
                                        name,
                                        label,
                                        placeholder,
                                        // size = "md",
                                        // autoComplete = "off",
                                        icon: Icon,
                                        required = false,
                                        className = "",
                                        rules = {}
                                    }) {
    const { control } = useFormContext();
    const {
        field: { value, onChange },
        fieldState: { error },
    } = useController({ name, control, rules });
    return (
        <div className={`w-full text-left ${className}`}>
            {label && (
                <label
                    htmlFor={name}
                    className="block  text-xs lg:text-sm font-semibold leading-6 text-gray-700 mb-2"
                >
                    {label}
                </label>
            )}
            <div className="relative">
                {Icon && (
                    <Icon className="w-5 absolute left-3 h-full text-gray-600"/>
                )}
                <div data-color-mode="light" className="w-full">
                    <MDEditor
                        value={value}
                        onChange={onChange}
                        hideToolbar
                        preview={'edit'}
                        className="custom-md-editor" //style in App.css
                        visibleDragbar={false}
                        highlightEnable={false}
                        textareaProps={{
                            name,
                            placeholder,
                            required,
                            className:`!h-full ${error ? `ring-2 ring-red-500 focus:ring-red-500` : ''}`,
                            maxLength: 120
                        }}
                    />
                    {/*<MDEditor.Markdown source={value} style={{whiteSpace: 'pre-wrap'}}/>*/}
                </div>
            {/*    <textarea*/}
            {/*        {...field}*/}
            {/*        id={name}*/}
            {/*        rows={5}*/}
            {/*        placeholder={placeholder}*/}
            {/*        name={name}*/}
            {/*        autoComplete={autoComplete}*/}
            {/*        required={required}*/}
            {/*        className={`block w-full rounded-md border-0 py-2.5 px-3.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-brand-300   text-xs lg:text-sm sm:leading-6 ${className} ${*/}
            {/*            Icon && "pl-10"*/}
            {/*        } ${size === "lg" && "py-3"}*/}
            {/*  ${error && `ring-2 ring-red-500 focus:ring-red-500`}`}*/}
            {/*        aria-describedby={`${name}-error`}*/}
            {/*    >*/}
            {/*  {field.value}*/}
            {/*</textarea>*/}

                {error && (
                    <p id={`${name}-error`} className="text-red-500 text-xs mt-1">
                        {error.message}
                    </p>
                )}
            </div>
        </div>
    );
}
