import React from "react";
import {
    useController, useFormContext,
} from "react-hook-form";
import {CloudArrowUpIcon} from "@heroicons/react/24/outline"
import Button from "../../Button";
const RHFFile = ({
    name,
    rules = {},
    label,
    isMultiple = false,
    customContent,
    wrapperExtraClass,
    acceptType = "images/*",
    required,
    customContextClassname,
    helperText,
                     onChangeCb,
    previewFile
}) => {
    const {control} = useFormContext()
    const {
        field: { value:inputValue, onChange: fieldOnChange },
        fieldState: { error },
    } = useController({ name, control, rules });
    return (
        <div
            className={`flex flex-col items-center w-full ${wrapperExtraClass ?? ""}`}
        >
            <div className={"w-full relative"}>
                {label && (
                    <label
                        htmlFor={name}
                        className="block text-left text-xs lg:text-sm font-semibold leading-6 text-gray-700 mb-2"
                    >
                        {label}
                    </label>
                )}
                <div
                    id="FileUpload"
                    className={`relative mb-5.5 block w-full cursor-pointer appearance-none rounded-xl border border-brand-600 py-4 px-4 sm:py-7.5 ${customContextClassname || ""}`}
                >
                    {customContent && customContent}
                    {!customContent && <div className="h-full w-full flex justify-center items-center">
                        <div className="flex flex-col text-center">
                            <span className="shadow w-10 h-10 border border-gray-200 p-2 rounded-lg mx-auto">
                              <CloudArrowUpIcon className=""/>
                            </span>
                            <span className="text-xs lg:text-sm text-gray-600 mt-3">
                              <b className="text-brand-600">{inputValue ? "Click to re-upload" : 'Click to upload'}</b> or drag and
                              drop the file
                            </span>
                            <span className="text-xs lg:text-sm text-gray-600 pt-1">{helperText}</span>
                        </div>
                    </div>}
                    <>
                        <div>
                            <input
                                ref={null} // Pass the inputRef as a ref to the input element
                                type="file"
                                name={name}
                                required={required}
                                multiple={isMultiple}
                                onChange={(e) => {
                                    onChangeCb?.();
                                    const files = e.target.files;
                                    if (!isMultiple) {
                                        if (files && files.length > 0) {
                                            fieldOnChange(files[0]);
                                        } else {
                                            if (inputValue) {
                                                fieldOnChange(inputValue);
                                            }
                                        }
                                    } else {
                                        if (files && files.length > 0) {
                                            fieldOnChange(Array.from(files).map((el) => el));
                                        } else {
                                            if (inputValue) {
                                                fieldOnChange(inputValue);
                                            }
                                        }
                                    }
                                }}
                                placeholder={`Enter your ${name}`}
                                accept={acceptType}
                                className="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                            />
                        </div>
                        {error && (
                            // absolute -bottom-8 left-0
                            <p className="text-left text-red-500 text-xs mt-1">
                                {error.message || "Error"}
                            </p>
                        )}
                    </>
                </div>
                {inputValue && previewFile && <Button type="button" size="md" variant="secondary-gray" className="cursor-default mt-5" width="full">
                    {acceptType.includes(".csv") && <img src="/img/icons/file-type-csv.svg" alt="Csv" className="w-6 h-6"/>}
                    {inputValue.name}
                </Button>}
            </div>
        </div>
    );
};

export default RHFFile;
