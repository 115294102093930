const TestimonialStatusBadge = ({ status }) => {
  switch (status.toLowerCase()) {
    case "pending":
      return (
        <span className="inline-flex justify-center items-center rounded-full md:mx-3 md:my-auto mx-0 my-1 bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 font-inter">
          {status}
        </span>
      );
    case "approved":
      return (
        <span className="inline-flex justify-center items-center rounded-full md:mx-3 md:my-auto mx-0 my-1 bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 font-inter">
          {status}
        </span>
      );
    case "unapproved":
      return (
        <span className="inline-flex justify-center items-center rounded-full md:mx-3 md:my-auto mx-0 my-1 bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 font-inter">
          {status}
        </span>
      );
    default:
      return (
        <span className="inline-flex justify-center items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 font-inter">
          {status}
        </span>
      );
  }
};

export default TestimonialStatusBadge;
