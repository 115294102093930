import {useEffect, useState} from "react";
import { PlayIcon } from "@heroicons/react/24/solid";
import React from "react";
import VideoModal from "./VideoModal";

export default function VideoPlaceholder({ video, playButton = true }) {
  const [open, setOpen] = useState(false);
  const [imageSrc,setImageSrc] = useState(`/${video.image}`);
  useEffect(() => {
    //if it is a http or https asset dont include a '/' at the start.
    setImageSrc(video.image.split("http")[1] ? video.image : `/${video.image}`);
  },[video.image])
    return (
    <>
      <VideoModal video={video.file} open={open} setOpen={setOpen} />
      <div
        onClick={() => setOpen(true)}
        className={`relative inline-flex w-full rounded-lg overflow-hidden cursor-pointer aspect-video`}
      >
        {playButton && (
          <div className="absolute flex items-center justify-center w-full h-full left-0 top-0 bg-black bg-opacity-40">
            <PlayIcon className="w-6 h-6 text-white" />
          </div>
        )}
        <img
          src={imageSrc}
          alt="Video placeholder"
          onError={() => {
            console.log("here")
              setImageSrc("/img/videoplaceholder.png")
          }}
          className="w-full h-full object-cover"
        />
      </div>
    </>
  );
}
