import {FormProvider} from "../common/RHF";
import RHFInputField from "../common/RHF/RHFTextField";
import {useNavigate} from "react-router-dom";
import Spinner from "../Spinner";
import Button from "../Button";
import React from "react";
import {useForm} from "react-hook-form";
import { useSelector} from "react-redux";

export default function ResetPasswordForm() {
    const navigate = useNavigate()
    const methods = useForm();
    const { handleSubmit, watch } = methods;
    const { status } = useSelector((state) => state);
    const submitForm = () => {
        navigate("/login")
    };
    return(
        <FormProvider methods={methods} className="space-y-6" onSubmit={handleSubmit(submitForm)}>
            <RHFInputField
                name="password"
                label="Password"
                type="password"
                placeholder="Enter your password"
                autoComplete="current-password"
                required
            />
            <RHFInputField
                name="confirmPassword"
                label="Confirm Password"
                placeholder="Confirm your password"
                type="password"
                autoComplete="password"
                required
                validate={(val) => {
                    if (watch('password') !== val) {
                        return 'Your passwords do no match';
                    }
                }}
            />

            <div>
                <Button size="lg" variant="primary" width={"full"} type="submit" isLoading={status === "loading"}>
                    Reset Password
                </Button>
            </div>
        </FormProvider>
    )
}