import React, {useContext} from "react";
import Button from "../Button";
import {EditableFormTraverseContext} from "../../pages/NewForm";


export default function MainCreateFormWrapper({children}) {
    const {handleCreateFormFlow, items, setItems, currentItem, editable} = useContext(EditableFormTraverseContext);
    return(
        <div className={'relative w-full flex flex-col items-center py-14 h-full overflow-y-auto'}>
            <img
                src="/img/testimonail-view-bg.png"
                className="w-full h-[220px] absolute top-0 object-cover"
                alt="bg"
            />
            {children}
            {editable && <div className="h-full flex items-end gap-2 py-8 w-full justify-center">
                <Button size="lg" onClick={() => handleCreateFormFlow(currentItem - 1, items, setItems)} iconTail={"/img/icons/arrow-left.svg"} variant="secondary-gray"
                        disabled={currentItem <= 0}>
                    Back
                </Button>
                {currentItem <= 2 && <Button onClick={() => handleCreateFormFlow(currentItem + 1, items, setItems)} size="lg"
                         iconLeading={"/img/icons/arrow-right.svg"} variant="secondary-gray">
                    Next
                </Button>}
                {currentItem > 2 && <Button size="lg" onClick={() => handleCreateFormFlow(currentItem + 1, items, setItems, true)}>
                    Save Form
                </Button>}
            </div>}
        </div>
    )
}