const Testimonial = ({ testimonial, onTestimonialClick }) => {
  // const data = ["Template T1", "Template T2", "Template T3", "Template T4"];

  return (
    <div>
      <div className="cursor-pointer">
        <img
          src={`${process.env.REACT_APP_API_URL}${testimonial[0]?.thumb}`}
          alt="Template"
          onClick={() => onTestimonialClick("Template T1")}
        />
      </div>
      <div className="flex space-x-2 py-2">
        <div className="cursor-pointer">
          <img
            className=""
            src={`${process.env.REACT_APP_API_URL}${testimonial[1]?.thumb}`}
            alt="Template"
            onClick={() => onTestimonialClick("Template T2")}
          />
        </div>
        <div className="">
          <div className="cursor-pointer">
            <img
              src={`${process.env.REACT_APP_API_URL}${testimonial[2]?.thumb}`}
              alt="Template"
              onClick={() => onTestimonialClick("Template T3")}
            />
          </div>
          <div className="py-2 cursor-pointer">
            <img
              src={`${process.env.REACT_APP_API_URL}${testimonial[3]?.thumb}`}
              alt="Template"
              onClick={() => onTestimonialClick("Template T4")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
