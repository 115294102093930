import { Controller, useFormContext } from "react-hook-form";
import React, { useCallback, useState } from "react";
import Button from "components/Button";
import { isString } from "lodash";

export default function RHFAvatar({
  name,
  label,
  size = "md",
  buttonText = "Upload",
  withImg = true,
  onChange,
                                      required = false,
                                      rules = {},
}) {
  const { control, setValue } = useFormContext();
  const [img, setImg] = useState("/img/imagePlaceholder.svg");

  const updateImage = useCallback(
    (e) => {
      setImg((i) => {
        // Revoke the previous object URL if it exists
        if (i) URL?.revokeObjectURL?.(i);

        var newImg = null;
        if (e) {
          // Check if e is a File object representing an image
          if (e instanceof File && e.type.startsWith("image/")) {
            // Create object URL for the image
            newImg = URL?.createObjectURL?.(e);
          } else {
            console.error("Invalid image file:", e);
          }
        }

        return newImg;
      });
    },
    [setImg]
  );
  const [onHover,setOnHover] = useState(false);
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;
        if (isString(field.value)) {
          setImg(field.value ? !field.value.split("/")[0] || field.value.split("https")[1] ? field.value : `/${field.value}` :  "/img/imagePlaceholder.svg");
        }

        return (
            <div className="text-left w-full">
                <label
                    htmlFor={name}
                    className="block  text-xs lg:text-sm font-semibold leading-6 text-gray-700 mb-2"
                >
                    {label}
                </label>
                <div className="flex items-center gap-4">
                    {withImg && (
                        <div
                            className={`rounded-full bg-gray-100 border border-gray-200 flex items-center justify-center ${size === "md" ? "w-16 h-16" : "w-12 h-12"} ${checkError ? "text-red-500 ring-2 ring-red-500 focus:ring-red-500" : ""}`}>
                            <img
                                src={img}
                                alt="Logo"
                                referrerPolicy="no-referrer"
                                className={`object-contain  ${size === "md" ? "w-16 h-16" : "w-12 h-12"} rounded-full`}
                            />
                        </div>
                    )}
                    <div className="flex flex-col gap-2 items-start cursor-pointer">
                        <div
                            className="relative transition-colors cursor-pointer">
                        <input
                                // {...field}
                                type="file"
                                accept="image/*"
                                className="absolute left-0 top-0 opacity-0 w-full h-full z-10 cursor-pointer"
                                onChange={(e) => {
                                    updateImage(
                                        e.target.files.length ? e.target.files[0] : null
                                    );

                                    setValue(
                                        name,
                                        e.target.files.length ? e.target.files[0] : null
                                    );

                                    if (onChange) onChange(e);
                                }}
                                required={required && !img}
                                onMouseEnter={() => setOnHover(true)}
                                onMouseLeave={() => setOnHover(false)}
                            />
                            <Button
                                size="sm"
                                className={`${
                                    checkError &&
                                    "text-red-500 ring-2 ring-red-500 focus:ring-red-500"
                                } ${onHover ? "!bg-gray-50 !text-gray-800" : ""} cursor-pointer`}
                                variant="secondary-gray"
                            >
                                {`${buttonText}${required ? "*" : ""}`}
                            </Button>
                        </div>
                    </div>
                </div>
                {checkError && <p className="text-red-500">{error.message}</p>}
            </div>
        );
      }}
    />
  );
}
