import React, {Fragment} from "react";
import Button from "../Button";
import {PencilIcon, VideoCameraIcon} from "@heroicons/react/24/outline";
import MainCreateFormWrapper from "./main-create-form-wrapper";
import {CREATE_FORM_TESTIMONIAL_TYPE} from "../../utils/constant";
import {FormProvider} from "../common/RHF";
import RHFInputField from "../common/RHF/RHFTextField";
import RHFAvatar from "../common/RHF/RHFAvatar";
import MainCreateFormCardHeader from "./main-create-form-card-header";
import RHFTextAreaWithMd from "../common/RHF/RHFTextAreaWithMd";
import MdTextPreview from "../common/text/md-text-preview";
import MobileSidebarWrapper from "../../layouts/mobile-sidebar-wrapper";
import ConfettiExplosion from "react-confetti-explosion";

export default function WelcomeForm({
  editable,
  cssClasses,
  items,
  setItems,
  handleCreateFormFlow,
  formMethods,
  testimonialMethods,
  setRightSidebarOpen,
  rightSidebarOpen
}) {
  const {watch} = formMethods;
  const {setValue} = testimonialMethods;
  return (
    <>
      <MainCreateFormWrapper>
          <FormProvider methods={testimonialMethods} className={cssClasses.card}>
              {/*<img*/}
              {/*    src={typeof watch("logo") === "string" ? watch("logo") : URL.createObjectURL(watch("logo"))}*/}
              {/*    alt="Logo"*/}
              {/*    className="w-16 h-16 object-contain border border-gray-200 rounded-full"*/}
              {/*/>*/}
              <MainCreateFormCardHeader logo={watch("logo")}/>
              <div className="flex flex-col gap-2">
                  <h1 className="text-base lg:text-lg text-gray-700 font-semibold w-full text-left">
                      {watch("welcome.title")}
                  </h1>
                  <MdTextPreview value={watch("welcome.description")}/>
              </div>
              <div className="flex flex-col gap-3 w-full">
                  <Button
                      variant="primary"
                      size="lg"
                      type="button"
                      onClick={() => {
                          handleCreateFormFlow(1, items, setItems);
                          // setTestimonialData(() => ({
                          //     ...testimonialData,
                          //     type: CREATE_FORM_TESTIMONIAL_TYPE.VIDEO,
                          // }));
                          setValue("type",CREATE_FORM_TESTIMONIAL_TYPE.VIDEO)
                      }}
                  >
                      <VideoCameraIcon className="h-5 w-5"/>
                      <span>Record a video</span>
                  </Button>
                  {/*{videoInputVisible && (*/}
                  {/*    <input*/}
                  {/*        type="file"*/}
                  {/*        accept="video/*"*/}
                  {/*        capture="camera"*/}
                  {/*        id="recorder"*/}
                  {/*    />*/}
                  {/*)}*/}
                  <Button
                      variant="secondary"
                      size="lg"
                      type="button"
                      onClick={() => {
                          handleCreateFormFlow(1, items, setItems);
                          // setTestimonialData(() => ({
                          //     ...testimonialData,
                          //     type: CREATE_FORM_TESTIMONIAL_TYPE.TEXT,
                          // }));
                          setValue("type",CREATE_FORM_TESTIMONIAL_TYPE.TEXT)
                      }}
                  >
                      <PencilIcon className="h-5 w-5"/>
                      <span>Write a testimonial</span>
                  </Button>
              </div>
          </FormProvider>
      </MainCreateFormWrapper>
        {editable && (
            <>
                <MobileSidebarWrapper open={rightSidebarOpen} setOpen={setRightSidebarOpen}>
                    <FormProvider methods={formMethods} className={cssClasses.sidebar}>
                        <RHFInputField
                            id="welcomeTitle"
                            label="Title"
                            type="text"
                            name="welcome.title"
                            // value={formDetails.welcome.title}
                            // onChange={(e) =>
                            //   setFormDetails({
                            //     ...formDetails,
                            //     welcome: {
                            //       ...formDetails.welcome,
                            //       title: e.target.value,
                            //     },
                            //   })
                            // }
                        />
                        <div className="w-full">
                            <RHFTextAreaWithMd
                                label="Description"
                                name="welcome.description"

                            />
                            <p className="text-xs text-gray-600 pt-1.5">
                                You can use markdown to format the text
                            </p>
                        </div>
                        <RHFAvatar name="logo" size="sm" label={"Logo"}/>
                        {/*<FileUpload img={formDetails.logo} label={"Logo"} />*/}
                    </FormProvider>
                </MobileSidebarWrapper>

                <FormProvider methods={formMethods} className={`hidden ${cssClasses.sidebar}`}>
                    <RHFInputField
                        id="welcomeTitle"
                        label="Title"
                        type="text"
                        name="welcome.title"
                        // value={formDetails.welcome.title}
                        // onChange={(e) =>
                        //   setFormDetails({
                        //     ...formDetails,
                        //     welcome: {
                        //       ...formDetails.welcome,
                        //       title: e.target.value,
                        //     },
                        //   })
                        // }
                    />
                    <div className="w-full">
                        <RHFTextAreaWithMd
                            label="Description"
                            name="welcome.description"

                        />
                        <p className="text-xs text-gray-600 pt-1.5">
                            You can use markdown to format the text
                        </p>
                    </div>
                    <RHFAvatar name="logo" size="sm" label={"Logo"}/>
                    {/*<FileUpload img={formDetails.logo} label={"Logo"} />*/}
                </FormProvider>
            </>
        )}
    </>
  );
}
