import React, { useState } from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";

import IconButton from "../IconButton";
import Button from "../Button";
import { useSelector } from "react-redux";
import API from "API";
import { toast } from "react-toastify";

export default function TestimonialHeader({
  title,
  setTitle,
  saveForm,
  discardForm,
  setSidebarOpen,
  testimonial,
  showAvatar,
  showRating,
  background,
  dimension,
  templateId,
}) {
  const [editing, setEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);

  const { user } = useSelector((state) => state);

  const handleSave = () => {
    setTitle(editedTitle);
    setEditing(false);

    const editTestimonialDetails = {
      ...testimonial,
      showAvatar,
      showRating,
      background,
      dimension,
      owner: user._id,
      templateId: templateId ? templateId : "Default",
    };

    // delete editTestimonialDetails.image;
    delete editTestimonialDetails.label;

    API.saveTemplate(editTestimonialDetails)
      .then((res) => {
        // setSaveTemplateResponse(res.data);
        toast.success("Testimonial saved successfully");
      })
      .catch((err) => {
        console.error("API Error:", err); // Log any API errors
        toast.error(err?.response?.data?.error);
      });

    if (editTestimonialDetails?.image) {
      API.uploadTestimonialLogo(editTestimonialDetails, "logo")
        .then((res) => {
          toast.success("File has been uploaded");
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error);
        });
    }
  };

  const handleDiscard = () => {
    setEditedTitle(title);
    setEditing(false);
  };

  return (
    <div className="px-8 py-4 border-b border-gray-200 bg-white flex justify-between">
      <div className="flex items-center gap-2">
        {/* <Button
          className={"block lg:hidden"}
          onClick={() => setSidebarOpen(true)}
        >
          Show
        </Button> */}
        <IconButton onClick={discardForm} icon={ArrowLeftIcon} />
        <h1 className="text-xl font-semibold">{title}</h1>
      </div>
      <div className="flex gap-2">
        <Button variant="default" onClick={discardForm}>
          Discard
        </Button>
        <Button onClick={handleSave}>Save Template</Button>
        <Button onClick={saveForm}> Share</Button>
      </div>
    </div>
  );
}
