import Button from "../Button";
import {ArrowLeftIcon} from "@heroicons/react/24/outline";
import React from "react";


export default function MainCreateFormCardHeader({returnBack, backFunction, logo}) {
    return (
        <div className="relative flex items-center justify-center w-full">
            {returnBack && <div className="absolute top-1/2 -translate-y-1/2 left-0 w-full">
                <Button variant="secondary-gray" onClick={backFunction}>
                    <ArrowLeftIcon className="w-5 h-5 stroke-2"/>
                </Button>
            </div>}
            {logo && <div className="w-16 h-16 border border-gray-200 rounded-full flex items-center justify-center">
                <img
                    src={typeof logo === "string" ? `/${logo}` : URL.createObjectURL(logo)}
                    alt="Logo"
                    className="w-12 h-12 object-contain"
                />
            </div>}
        </div>
    )
}