import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import Badge from './Badge';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function StatusDropdown({ children, itemSelected }) {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <Menu.Button>{children}</Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item onClick={() => itemSelected("Approved")}>
                            {({ active }) => (
                                <div
                                    className={classNames(
                                        active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                        'block px-4 py-2 text-sm cursor-pointer'
                                    )}
                                >
                                    <Badge type={'success'} text={'Approved'} />
                                </div>
                            )}
                        </Menu.Item>
                        <Menu.Item onClick={() => itemSelected("Unapproved")}>
                            {({ active }) => (
                                <div
                                    className={classNames(
                                        active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                        'block px-4 py-2 text-sm cursor-pointer'
                                    )}
                                >
                                    <Badge
                                        type={'warning'}
                                        text={'Unapproved'}
                                    />
                                </div>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
