import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function RHFDatePicker({
  name,
  label,
  placeholder,
  icon: Icon,
  size = "md",
  className = "",
  defaultValue = "",
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className={`w-full text-left ${className}`}>
          {label && (
            <label
              htmlFor={name}
              className="block text-sm font-medium leading-6 text-gray-900 mb-2"
            >
              {label}
            </label>
          )}
          <div className="relative">
            {Icon && (
              <Icon className="w-5 absolute left-3 h-full text-gray-600 z-10" />
            )}
            <DatePicker
              selected={value}
              onChange={onChange}
              placeholderText={placeholder}
              className={`block w-full rounded-md border-0 py-1.5 px-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                size === "lg" && "py-3"
              }
                ${error && `ring-2 ring-red-500 focus:ring-red-500`}
                `}
            />
            {error && (
              <p id={`${name}-error`} className="text-red-500 text-xs mt-1">
                {error.message}
              </p>
            )}
          </div>
        </div>
      )}
    />
  );
}
