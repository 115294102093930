import { useCallback, useState } from "react";
import Button from "./Button";
export default function FileUpload({
  label,
  size = "md",
  buttonText = "Upload",
  withImg = true,
  helpText,
  onChange,
  setImageURL,
  testimonial,
}) {
  const [img, setImg] = useState("/img/imagePlaceholder.svg");

  const updateImage = useCallback(
    (e) => {
      setImg((i) => {
        // Revoke the previous object URL if it exists
        if (i) URL?.revokeObjectURL?.(i);

        var newImg = null;
        if (e) {
          // Check if e is a File object representing an image
          if (e instanceof File && e.type.startsWith("image/")) {
            // Create object URL for the image
            newImg = URL?.createObjectURL?.(e);
          } else {
            console.error("Invalid image file:", e);
          }
        }

        onChange(e);
        if (testimonial) {
          testimonial.image = e;
        }
        return newImg;
      });
      if (setImageURL) setImageURL(img);
    },
    [setImg, testimonial, onChange, setImageURL]
  );

  return (
    <div className="text-left w-full">
      <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">
        {label}
      </label>
      <div className="flex items-center gap-4">
        {withImg && (
          <img
            src={img}
            alt="Logo"
            className={`object-fill rounded-full ${
              size === "md" ? "w-16 h-16" : "w-12 h-12"
            }`}
          />
        )}
        <div class="flex flex-col gap-2 items-start">
          <div className="relative transition-colors border bg-white border-gray-200 text-gray-800 hover:bg-gray-100 rounded">
            <input
              type="file"
              className="absolute left-0 top-0 opacity-0 w-full h-full z-10 cursor-pointer"
              onChange={(e) =>
                updateImage(e.target.files.length ? e.target.files[0] : null)
              }
            />
            <Button type="transparent" size="sm" variant="default">
              {buttonText}
            </Button>
          </div>
          {helpText && <p className="text-sm text-gray-600">{helpText}</p>}
        </div>
      </div>
    </div>
  );
}
