import { toast } from "react-toastify";

export const copyToClipboard = async (textToCopy) => {
  try {
    await navigator.clipboard.writeText(textToCopy);
    toast.success("Text copied to clipboard");
    // You can handle the success case here (e.g., set a state, show a message)
  } catch (err) {
    console.error("Failed to copy: ", err);
    // Handle the error case here
  }
};

export const handleCreateFormFlow = (itemNumber, items, setItems) => {
  const updatedItems = items.map((item, index) => ({
    ...item,
    current: index === itemNumber,
  }));
  setItems(updatedItems);
};

export const groupByKey = (data, attribute) => {
  return Object.entries(data).reduce((result, [key, value]) => {
    value.forEach((item) => {
      const groupKey = item[attribute];
      result[groupKey] = result[groupKey] || [];
      result[groupKey].push(item);
    });
    return result;
  }, {});
};

export function formatVideoURL(url) {
  url = url.replace(/^.*\/\/?/, "");

  const parts = url.split(/[\\/]+/).filter((part) => part);
  return "/" + parts.join("/");
}
export async function createFile(url,title, type = {
  type: "video/webm",
}) {
  let response = await fetch(url)
  let data = await response.blob();
  return new File ([data],title, type)
}

export const videoDownload = async (url, filename) => {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL(this.response);
    var tag = document.createElement("a");
    tag.href = imageUrl;
    tag.target = "_blank";
    tag.download = filename;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.onerror = (err) => {
    alert("Failed to download picture");
  };
  xhr.send();
};

export async function dataUrlToFile(dataUrl, fileName) {

  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], fileName, { type: 'image/png' });
}
