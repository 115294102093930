import {InboxIcon} from "@heroicons/react/24/outline";
import {NavLink} from "react-router-dom";
import Button from "../Button";
import React from "react";

export default function FormInitialState() {
    return (
        <div className="flex items-center justify-center flex-col h-full">
            <div
                className="bg-brand-100 border-[10px] border-brand-50 w-14 h-14 flex-none rounded-full flex items-center justify-center">
                <InboxIcon className="w-6 h-6 text-brand-600 stroke-2"/>
            </div>
            <h2 className="text-gray-900 text-xl lg:text-3xl font-semibold mt-6 pb-4">
                Create a form
            </h2>
            <p className="text-gray-600 lg:text-lg pb-6">
                Create a form to collect video and text testimonials
            </p>
            <NavLink to="/forms/create-new">
                <Button variant="primary" width="auto" iconTail={"/img/icons/plus.svg"}>
                    Create new form
                </Button>
            </NavLink>
        </div>
    )
}