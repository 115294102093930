import {NavLink, useNavigate} from "react-router-dom";
import Button from "../../Button";
import {ClipboardIcon, PencilIcon, ShareIcon, TrashIcon} from "@heroicons/react/24/outline";
import {copyToClipboard} from "../../../utils/utils";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import API from "../../../API";
import FormSendInvitePopover from "../../forms/FormSendInvitePopover";

export default function FormCard({formData,setDataIndex}) {
    const {title,_id} = formData;
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const [formId, setFormId] = useState(searchParams.get("selectedForm"));
    const [sendPopover, setSendPopover] = useState(false);

    const editForm = React.useCallback(
        (form,e) => {
            e.stopPropagation();
            navigate("/forms/create-new", { state: { form: form } });
        },
        [navigate]
    );
    function shareForm(e) {
        e.stopPropagation();
        setFormId(_id);
        setSendPopover(true);
    }
    const deleteForm = React.useCallback(async (form, e) => {
        e.stopPropagation();
        e.disabled = true;

        await toast.promise(API.deleteForm(form), {
            pending: "Deleting Form...",
            success: "Success",
            error: {
                render({ data }) {
                    return `${data.message}`;
                },
            },
        });
        setDataIndex((d) => d + 1);
    }, []);

    useEffect(() => {
        if (searchParams.get("selectedForm")) {
            setSendPopover(true);
            setFormId(searchParams.get("selectedForm"));
        }
    }, [searchParams.get("selectedForm")]);
    return (
        <>
            <div className="block p-6 bg-white hover:bg-gray-50 transition-colors rounded-2xl border border-gray-200 duration-200">
                <div className="flex-col-reverse items-start flex md:flex-row md:items-center justify-between gap-4">
                    <h5 className="font-semibold text-base lg:text-lg text-gray-900">
                        {title}
                    </h5>
                    <div className="flex flex-wrap items-start gap-2 sm:items-center">
                        <Button
                            variant="secondary-gray"
                            size="sm"
                            onClick={async () => {
                                await copyToClipboard(`${window.location.host}/testimonial/${_id}`)
                            }}
                        >
                            <ClipboardIcon className="w-4 h-4" />{" "}
                            <span>Copy URL</span>{" "}
                        </Button>
                        <Button
                            variant="secondary-gray"
                            size="sm"
                            onClick={(e) => deleteForm(formData, e)}
                        >
                            <TrashIcon className="w-4 h-4 text-red-700"/>{" "}
                            <span>Delete</span>{" "}
                        </Button>
                        <Button
                            variant="secondary-gray"
                            size="sm"
                            onClick={shareForm}
                        >
                            <ShareIcon className="w-4 h-4 "/>{" "}
                            <span>Share</span>{" "}
                        </Button>
                        <Button
                            variant="secondary-gray"
                            size="sm"
                            onClick={(e) => editForm(formData,e)}
                        >
                            <PencilIcon className="w-4 h-4"/> <span>Edit</span>{" "}
                        </Button>
                    </div>
                </div>

                <div className="flex flex-col sm:flex-row items-start gap-3 sm:items-center mt-8">
                    <div className="sm:border-r pr-3 flex items-center gap-2">
                        <span className="text-base lg:text-lg text-gray-900 font-bold">{formData?.totalUniqueVisitIps?.length || 0}</span>
                        <span className="text-sm lg:text-base text-gray-600 font-medium">Unique Visits</span>
                    </div>
                    <div className="sm:border-r pr-3 flex items-center gap-2">
                        <span className="text-base lg:text-lg text-gray-900 font-bold">{formData?.totalTestimonialsSent || 0}</span>
                        <span className="text-sm lg:text-base text-gray-600 font-medium">Testimonials Requested</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <span className="text-base lg:text-lg text-gray-900 font-bold">{formData?.totalTestimonialsFilled || 0}</span>
                        <span className="text-sm lg:text-base text-gray-600 font-medium">Testimonials Filled</span>
                    </div>
                </div>
            </div>
            <FormSendInvitePopover
                open={sendPopover}
                setOpen={setSendPopover}
                formId={formId}
            />
        </>
    )
}