import { useState, useCallback } from "react";

export const VideoToFramesMethod = {
  fps: "fps",
  totalFrames: "totalFrames",
};

const useVideoToFrames = () => {
  const [frames, setFrames] = useState([]);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);

  const getVideoFrame = useCallback((video, context, canvas, time) => {
    return new Promise((resolve, reject) => {
      const eventCallback = () => {
        video.removeEventListener("seeked", eventCallback);
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        const dataUrl = canvas.toDataURL();
        const file = dataURLtoFile(dataUrl, `frame-${time}.png`);
        resolve({ dataUrl, file });
      };
      video.addEventListener("seeked", eventCallback);
      video.currentTime = time;
    });
  }, []);

  const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const getFrames = useCallback(
    (videoUrl, amount) => {
      setFrames([]);
      setFiles([]);
      setError(null);

      let frames = [];
      let files = [];
      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");
      if (!context) {
        setError("Failed to get canvas context");
        return;
      }
      let video = document.createElement("video");
      video.crossOrigin = "anonymous";
      video.preload = "auto";

      video.addEventListener("loadedmetadata", async () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const duration = video.duration;

        try {
          for (let i = 0; i < amount; i++) {
            const time = (i / amount) * duration;
            const { dataUrl, file } = await getVideoFrame(
              video,
              context,
              canvas,
              time
            );
            frames.push(dataUrl);
            files.push(file);
          }
          setFrames(frames);
          setFiles(files);
        } catch (e) {
          setError("Failed to extract frames");
        }
      });

      video.addEventListener("error", () => {
        setError("Failed to load video");
      });

      video.src = videoUrl;
      video.load();
    },
    [getVideoFrame]
  );

  return { frames,setFrames, files, getFrames, error };
};

export default useVideoToFrames;
