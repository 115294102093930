import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import authReducer from './authSlice';

const persistConfig = {
    key: 'auth',
    storage,
}

const store = configureStore({
    reducer: persistReducer(persistConfig, authReducer),
    devTools: process.env.NODE_ENV !== 'production'
});

export default store;