import classNames from 'classnames';

export default function IconButton({
    icon: Icon,
    onClick,
    className = '',
    type = 'default',
    title,
                                       buttonType
}) {
    const baseClasses =
        'p-2 transition-colors rounded-lg hover:bg-opacity-30 flex items-center justify-center cursor-pointer';

    // type-specific classes
    const typeClasses = classNames({
        'text-gray-600 hover:bg-gray-300  active:bg-indigo-100 active:text-indigo-600':
            type === 'default',
        'text-red-600 hover:bg-red-300 active:bg-red-500 active:text-red-600 active:bg-opacity-30':
            type === 'danger',
    });

    const buttonClass = classNames(baseClasses, typeClasses, className);

    return (
        <button type={buttonType} onClick={onClick} className={buttonClass} title={title}>
            <Icon className="w-5 h-5 " />
        </button>
    );
}
