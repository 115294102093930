import React from 'react';

export default function CardButton({
    href = '#',
    extraClass,
    icon: Icon,
    title,
    description,
}) {
    return (
        <a href={href} className={`flex items-center gap-4 border bg-white rounded-xl shadow-xs p-5 ${extraClass}`}>
            <div className="bg-brand-100 border-8 border-brand-50 min-w-12 min-h-12 rounded-full flex items-center justify-center">
                <Icon className="w-6 h-6 text-brand-600" />
            </div>
            <div>
                <h3 className="font-semibold text-gray-700 text-base">{title}</h3>
                <p className="text-sm text-gray-600">
                    {description}
                </p>
            </div>
        </a>
    );
}
