import React, { useState } from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { PencilIcon } from "@heroicons/react/24/outline";
import IconButton from "../IconButton";
import Button from "../Button";
import RHFInputField from "../common/RHF/RHFTextField";
import {useForm} from "react-hook-form";
import {FormProvider} from "../common/RHF";

export default function NewFormHeader({
  title,
  setTitle,
  saveForm,
  discardForm,
}) {
  const methods = useForm({
    defaultValues:{
      title: title
    }
  })
  const {handleSubmit, setValue} = methods;

  const [editing, setEditing] = useState(false);

  const handleSave = (data) => {
    setTitle(data.title ? data.title : title);
    setValue("title",data.title ? data.title : title)
    setEditing(false);
  };

  const handleDiscard = () => {
    console.log(title,"title")
    setValue("title", title);
    setEditing(false);
  };

  return (
    <div className="px-8 pt-6 pb-4 border-b border-gray-200 bg-white flex-col gap-2.5 sm:flex-row flex justify-between">
      {editing ? (
        <FormProvider methods={methods} onSubmit={handleSubmit(handleSave)} className="flex flex-col sm:flex-row gap-2">
          <RHFInputField
              name="title"
              type="text"
              size="lg"
              placeholder="Enter Title"
              inputClassName="rounded-lg border-gray-300 h-[44px]"
          />
          <Button size="lg" variant="secondary-gray" onClick={handleDiscard}>
            Discard
          </Button>
          <Button size="lg" type="submit">Save</Button>
        </FormProvider>
      ) : (
        <>
          <div className="flex items-center gap-2">
            <ArrowLeftIcon onClick={discardForm} className="w-5 h-5 cursor-pointer stroke-2 mr-2"/>
            <h1 className="text-xl font-semibold">{title}</h1>
            <IconButton icon={PencilIcon} onClick={() => setEditing(true)} />
          </div>
          <div className="flex gap-2">
            <Button size="lg" variant="secondary-gray" onClick={discardForm}>
              Discard
            </Button>
            <Button size="lg" onClick={() => saveForm(true)} iconTail={"/img/icons/save.svg"}>Save</Button>
          </div>
        </>
      )}
    </div>
  );
}
