import Button from '../../components/Button';
import { useSelector } from 'react-redux'
import {useForm} from "react-hook-form";
import {FormProvider, RHFAvatar, RHFTextField} from "../../components/common/RHF";
import {toast} from "react-toastify";
import API from "../../API";
import {useState} from "react";

export default function AccountSettings() {
    const { user } = useSelector((state) => state);
    const profileMethods = useForm({
        defaultValues:{
            fullName: user.fullName,
            email: user.email,
            image: user.profile,
        }
    });
    const [status,setIsStatus] = useState("idle")
    const changePasswordMethods = useForm();
    const {handleSubmit: updateProfileHandleSubmit} = profileMethods;
    const {handleSubmit: changePasswordHandleSubmit} = changePasswordMethods;

    const updateProfileSubmit = async (data) => {
        const formData = new FormData()
        for (const key in data) {
            formData.append(key, data[key]);
        }
        try {
            setIsStatus("profile")
            await toast.promise(
                API.updateProfile(formData),
                {
                    pending: 'Updating your profile...',
                    success: {
                        render({ data }) {
                            API.ensureLogin()
                            return `${data.message}`;
                        },
                    },
                    error: {
                        render({ data }) {
                            return `${data.message}`;
                        },
                    },

                }
            )
        }catch (e) {
            console.log(e,"error")
        }finally {
            setIsStatus("idle")
        }
    }
    return (
        <div className="space-y-12">
            {/*border-b border-gray-900/10*/}
            <FormProvider methods={profileMethods} onSubmit={updateProfileHandleSubmit(updateProfileSubmit)} className="grid grid-cols-1 gap-x-8 gap-y-10  pb-12 md:grid-cols-3">
                <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Personal information
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        This information will be displayed publicly so be
                        careful what you share.
                    </p>
                </div>

                <div className="flex flex-col gap-6">
                    <div className="sm:col-span-4">
                        <RHFTextField type="text" name="fullName" label={'Full name'} placeholder={'Full name'}
                                      required/>
                    </div>
                    <div className="sm:col-span-4">
                        <RHFTextField disabled type="email" name="email" label={'Email address'}
                                      placeholder={'Email address'} required/>
                    </div>
                    <div>
                        <RHFAvatar
                            buttonText="Change avatar"
                            name="image"
                            helpText={'JPG, GIF or PNG. 1MB max.'}
                            withImg
                            required
                        />
                    </div>
                    <div>
                        <Button type="submit" size="md" width="full" variant="primary" isLoading={status === "profile"}>
                            Save
                        </Button>
                    </div>
                </div>
            </FormProvider>
            {/*<FormProvider methods={changePasswordMethods} onSubmit={changePasswordHandleSubmit(() => {*/}
            {/*})} className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">*/}
            {/*    <div>*/}
            {/*        <h2 className="text-base font-semibold leading-7 text-gray-900">*/}
            {/*            Change password*/}
            {/*        </h2>*/}
            {/*        <p className="mt-1 text-sm leading-6 text-gray-600">*/}
            {/*            Update your password associated with your account.*/}
            {/*        </p>*/}
            {/*    </div>*/}

            {/*    <div className="flex flex-col gap-6">*/}
            {/*        <div className="sm:col-span-4">*/}
            {/*            <InputField*/}
            {/*                label={'Current Password'}*/}
            {/*                type={'password'}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*        <div className="sm:col-span-4">*/}
            {/*            <InputField*/}
            {/*                label={'New Password'}*/}
            {/*                type={'password'}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*        <div className="sm:col-span-4">*/}
            {/*            <InputField*/}
            {/*                label={'Confirm Password'}*/}
            {/*                type={'password'}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <Button>Save</Button>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</FormProvider>*/}
            {/*<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">*/}
            {/*    <div>*/}
            {/*        <h2 className="text-base font-semibold leading-7 text-gray-900">*/}
            {/*            Delete account*/}
            {/*        </h2>*/}
            {/*        <p className="mt-1 text-sm leading-6 text-gray-600">*/}
            {/*            No longer want to use our service? You can delete*/}
            {/*            your account here. This action is not reversible.*/}
            {/*            All information related to this account will be*/}
            {/*            deleted permanently.*/}
            {/*        </p>*/}
            {/*    </div>*/}

            {/*    <div className="flex flex-col gap-6">*/}
            {/*        <div>*/}
            {/*            <Button type="danger">Delete account</Button>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}
