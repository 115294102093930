import classNames from "classnames";
import { StarIcon } from "@heroicons/react/24/solid";
import React from "react";

const StarRating = ({
  rating,
  totalStars = 5,
  starClass = "w-6 h-6 stars",
  label,
  onChange,
  starDiv,
}) => {
  const Star = ({ index }) => {
    const filled = index < rating;
    const combinedStarClass = classNames(
      starClass,
      "cursor-pointer",
      { "hover:text-gray-400": !!onChange && !filled }, // Apply hover class only if onChange is provided and star is not filled
      { "text-[#FDB022]": filled, "text-gray-300": !filled }
    );

    const handleClick = () => {
      if (onChange) {
        onChange(index + 1);
      }
    };

    return (
      <div className={combinedStarClass} onClick={handleClick}>
        <StarIcon />
      </div>
    );
  };

  return (
    <div>
      {label && (
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
      )}
      <div className={`${classNames(`flex mt-2 ${starDiv}`)}`}>
        {[...Array(totalStars)].map((_, i) => (
          <Star key={i} index={i} />
        ))}
      </div>
    </div>
  );
};

export default StarRating;
