import Logo from "../../components/Logo";
import React from "react";
import AuthBrand from "../../components/Auth/AuthBrand";

export default function AuthWrapper({children,title,description}) {
    return(
        <>
            <div className="absolute left-8 top-8">
                <Logo className={"mx-auto"}/>
            </div>
            <div className="flex flex-col lg:flex-row items-center justify-center pt-10 lg:pt-0">
                <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8 py-10 w-full">
                    <div className="max-w-[360px] mx-auto w-full">
                        <h2 className="mt-6 pb-3 text-xl lg:text-3xl font-semibold ext-gray-900">
                            {title}
                        </h2>
                        <p className="text-gray-600 text-sm lg:text-base">
                            {description}
                        </p>
                    </div>

                    <div className="mt-8 max-w-[360px] mx-auto w-full">
                        {children}
                    </div>
                </div>
                <AuthBrand/>
            </div>
        </>
    )
}