// InputField.js
export default function Textarea({
    id,
    label,
    placeholder,
    size = 'md',
    type,
    autoComplete,
    icon: Icon,
    required,
    className,
    value,
    onChange,
}) {
    return (
        <div className="w-full">
            {label && <label
                htmlFor={id}
                className="block text-sm font-medium leading-6 text-gray-900 mb-2"
            >
                {label}
            </label>}
            <div className="relative">
                {Icon && (
                    <Icon className="w-5 absolute left-3 h-full text-gray-600" />
                )}
                <textarea
                    id={id}
                    rows={5}
                    placeholder={placeholder}
                    name={id}
                    type={type}
                    autoComplete={autoComplete}
                    required={required}
                    className={`block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${className} ${
                        Icon && 'pl-10'
                    } ${size === 'lg' && 'py-3'}`}
                    onChange={onChange}
                >
                    {value}
                </textarea>
            </div>
        </div>
    );
}
