import React, { useEffect, useState } from "react";
import {NavLink, useNavigate} from "react-router-dom";
import Sidebar from "../layouts/Sidebar";
import Button from "../components/Button";
import { useSelector } from "react-redux";
import API from "API";
import Spinner from "components/Spinner";
import FormCard from "../components/common/cards/form-card";
import FormInitialState from "../components/forms/form-initial-state";

export default function Forms() {
  const { isAuthenticated } = useSelector((state) => state);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [dataIndex, setDataIndex] = useState(0);
  const [formsList, setFormsList] = useState([]);

  React.useEffect(() => {
    if (!isAuthenticated) navigate("/login");
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    void (async () => {
      try {
        setLoading(true);
        const res = await API.listForms();
        setFormsList(res.result);
      }catch (e) {
        console.log(e,"error")
      }finally {
        setLoading(false);
      }
    })()
  }, [dataIndex]);
  return (
      <div className="h-screen flex flex-col lg:flex-row">
        <Sidebar />
        <div className="flex-grow p-8">
          {loading && <Spinner />}
          {(!loading && formsList && formsList.length > 0) && (
              <>
                <div className="flex items-center justify-between">
                  <h1 className="text-3xl font-semibold flex items-baseline gap-2">
                    My Forms{" "}
                    <span className="text-lg opacity-60">({formsList.length})</span>
                  </h1>
                  <NavLink to="/forms/create-new">
                    <Button variant="primary" width="auto" iconTail={"/img/icons/plus.svg"}>
                      Create new form
                    </Button>
                  </NavLink>
                </div>

                <div className="mt-16 w-full flex flex-col gap-2">
                  {formsList.map((form) => (
                      <FormCard
                          formData={{
                            ...form,
                            title: form.title || form.formData.welcome.title
                          }}
                          setDataIndex={setDataIndex} key={form._id}
                      />
                  ))}
                </div>
              </>
          )} {!loading && (!formsList || formsList.length <= 0) && <FormInitialState/>}
        </div>
      </div>
  );
}