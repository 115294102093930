import classNames from "classnames";
import { groupByKey } from "utils/utils";

const Resize = ({ resize, onDimensionClick, selectedResizeId }) => {
  const groupedData = groupByKey(resize, "key");
  return (
    <>
      <div>
        {Object.keys(groupedData)?.map?.((key, i) => {
          return (
            <div className="pb-4 cursor-pointer" key={i}>
              <div className="py-2">
                <span className="text-gray-500 font-semibold text-sm uppercase leading-6">
                  {key}
                </span>
              </div>
              {groupedData[key]?.map?.((resize, index) => {
                return (
                  <div
                    key={index}
                    className={classNames("flex space-y-2 py-2")}
                    onClick={() =>
                      onDimensionClick(
                        resize.width,
                        resize.height,
                        resize.id,
                        resize?.dimension
                      )
                    }
                  >
                    <div
                      className={classNames(
                        resize.id === selectedResizeId
                          ? "frame transition-none border-none"
                          : "",
                        "relative"
                      )}
                    >
                      <img
                        src={`${process.env.REACT_APP_API_URL}${resize?.path}`}
                        alt="Screens"
                      />
                    </div>

                    <div className={"pl-5"}>
                      <p className="text-black text-[16px] font-semibold leading-6">
                        {resize.text}
                        <span className="text-sm block text-gray-500 font-medium">
                          {resize.dimension}
                        </span>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Resize;
