import React, {useState} from "react";
import { useFormContext, Controller } from "react-hook-form";

export default function RHFInputField({
  name,
  label,
  placeholder,
  size = "md",
  type = "text",
  autoComplete = "off",
  icon: Icon,
  required = false,
  className = "",
  defaultValue = "",
  rules = {},
    inputClassName = "",
    disabled
}) {
  const { control } = useFormContext();
  const [inputType, setInputType] = useState(type);
  const toggleShow = () => {
    inputType === "password" ? setInputType("text") : setInputType("password");
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      disabled={disabled}
      defaultValue={defaultValue} // Use defaultValue for initial form state
      render={({ field, fieldState: { error, invalid } }) => (
        <div className={`w-full text-left ${className}`}>
          {label && (
              <label
              htmlFor={name}
              className="block text-xs lg:text-sm font-semibold leading-6 text-gray-700 mb-2"
            >
              {`${label}${required ? "*" : ""}`}
            </label>
          )}
          <div className="relative">
            {Icon && (
              <Icon className="w-5 absolute left-3 h-full text-gray-600" />
            )}
            {type === "password" && (
                <span
                    onClick={() => toggleShow()}
                    className={`absolute right-1 z-10 top-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer`}
                >
                {inputType === "password" && <img src="/img/icons/eye.png" alt="Eye"/>}
                  {inputType !== "password" && <img src="/img/icons/eye-off.png" alt="Eye Off"/>}
              </span>
            )}
            <input
                {...field}
              id={name} // Use name for consistent ID
              placeholder={placeholder}
              type={inputType}
              autoComplete={autoComplete}
              required={required}
              className={`block w-full rounded-md border-0 py-2.5 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-brand-300 disabled:bg-gray-50 disabled:text-gray-500 disabled:cursor-not-allowed text-xs lg:text-sm sm:leading-6 ${
                size === "lg" && "py-3"}
              ${error && `ring-2 ring-red-500 focus:ring-red-500`}
              ${inputClassName} ${Icon && "pl-10"}`}
              aria-describedby={`${name}-error`} // Add aria-describedby for error handling
            />
          </div>
          {error && (
              <p id={`${name}-error`} className="text-red-500 text-xs mt-1">
                {error.message}
              </p>
          )}
        </div>
      )}
    />
  );
}
