import React, {createContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams, useLocation, NavLink} from "react-router-dom";
import FormSidebar from "../layouts/FormSidebar";
import { toast } from "react-toastify";
import {
  ChatBubbleLeftRightIcon,
  CheckCircleIcon,
  FlagIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";

import NewFormHeader from "../components/create-form/NewFormHeader.js";
import WelcomeForm from "../components/create-form/WelcomeForm";
import TestimonialForm from "../components/create-form/testimonial-form";
import DetailsForm from "../components/create-form/DetailsForm";
import ThanksForm from "../components/create-form/ThanksForm";
import { handleCreateFormFlow } from "../utils/utils";
import { useSelector } from "react-redux";
import API from "API";
import Spinner from "components/Spinner";
import {useForm} from "react-hook-form";
let formInfo = {
  logo: "../img/logoIcon.svg",
  welcome: {
    title: "Did you like working with me?",
    description: "Please leave a testimonial to help me grow my business 🙂",
  },
  videoForm: {
    title: "Record a testimonial",
    description:
        "What do you like most about working with me? Would you recommend me to anyone else?",
  },
  textForm: {
    title: "Write a testimonial",
    description:
        "What do you like most about working with me? Would you recommend me to anyone else?",
  },
  details: {
    title: "You’re almost done!",
    nameEnable: true,
    nameRequire: true,
    emailEnable:true,
    emailRequire:true,
    jobEnable:true,
    jobRequire:true,
    photoEnable:true,
    photoRequire:true,
  },
  thanks: {
    title: "You’re done! ✅",
    description:
        "Thanks a lot for sharing your valuable feedback! I really appretiate it!",
  },
};

const cssClasses = {
  card: "bg-white rounded-3xl p-8 shadow-xl shadow-gray-200 items-center text-center flex flex-col gap-6 max-w-[412px] w-full relative",
  sidebar:
      "z-50 w-80 h-full flex-none lg:flex flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 py-8 overflow-auto",
};

const initialItems = [
  {
    name: "Welcome",
    icon: FlagIcon,
    current: true,
  },
  {
    name: "Testimonial",
    icon: ChatBubbleLeftRightIcon,
    current: false,
  },
  // {
  //   name: "Text Testimonial",
  //   icon: PencilIcon,
  //   current: false,
  // },
  {
    name: "Customer details",
    icon: UserCircleIcon,
    current: false,
  },
  {
    name: "Thank you ",
    icon: CheckCircleIcon,
    current: false,
  },
  // ... other items ...
];

const formComponents = [
  WelcomeForm,
  TestimonialForm,
  DetailsForm,
  ThanksForm,
];

export const EditableFormTraverseContext = createContext(null);

export default function NewForm({ editable = true, testimonial = false }) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isAuthenticated, user } = useSelector((state) => state);
  const [status, setStatus] = useState("loading");
  const [form, setForm] = useState(state ? state.form : null);
  const { id, testimonialId } = useParams();
  const [rightSidebarOpen,setRightSidebarOpen] = useState(false);
  const windowWidth = window.innerWidth;
  const methods = useForm({
    defaultValues: id ? async () => {
      const result = await API.loadForm({ id: id })
      return {
        ...result.result.formData,
        logo: result.result.formData?.logo ? result.result.formData.logo : "img/logoIcon.svg"
      }
    } : form ? {
      ...form.formData,
      logo: form.formData?.logo ? form.formData.logo : "img/logoIcon.svg"
    } : formInfo
  })
  const testimonialMethods = useForm()
  const {getValues} = methods;
  const {getValues: getTestimonialValues, trigger} = testimonialMethods;

  const [sidebarItems, setSidebarItems] = useState(initialItems);

  const [title, setTitle] = useState(form ? form.title : "Untitled Form");

  useEffect(() => {
    (async ()=>{
        if (id) {
          try {
            setStatus("");
            const result = await API.loadForm({ id: id });
            setForm(result.result);
            setTitle(result.result.title);
            setStatus("loaded");
          } catch (error) {
            setStatus(error.toString());
          }
        } else {
          setStatus("loaded");
        }
    })()
  }, [id, setStatus]);

  const onlyOneRender = useRef(true);
  useEffect(() => {
    (async () => {
      if (!!id && onlyOneRender.current && !editable){
        try {
          onlyOneRender.current = false;
          await API.incrementTestimonialVisitor(id);

        }catch (e) {
          console.log(e,"error")
        }
      }
    })()
  }, [id]);

  const saveData = async (goBack) => {
    if (!editable && id && testimonial) {
      const testimonialData = getTestimonialValues();
      const imageToSave = testimonialData.image;
      const videoToSave = testimonialData.video;
      const thumbnailToSave = testimonialData.thumbnail;
      testimonialData.image = null;
      testimonialData.video = null;
      testimonialData.thumbnail = null;
      let tResult;
      if (testimonialId){
        tResult = await toast.promise(
            API.updateTestimonial({
              _id:testimonialId,
            }, testimonialData),
            {
              pending: "Saving Testimonial...",
              success: "Success",
              error: {
                render({ data }) {
                  return `${data.message}`;
                },
              },
            }
        );
      }else{
        tResult = await toast.promise(
            API.saveTestimonial({
              user: user,
              testimonial: testimonialData,
              form: form,
            }),
            {
              pending: "Saving Testimonial...",
              success: "Success",
              error: {
                render({ data }) {
                  return `${data.message}`;
                },
              },
            }
        );
      }

      if (imageToSave) {
        await toast.promise(
            API.uploadTestimonialFile(tResult.result, "image", imageToSave),
            {
              pending: "Uploading Picture ...",
              success: "Success",
              error: {
                render({ data }) {
                  return `${data.message}`;
                },
              },
            }
        );
      }

      if (videoToSave) {
        await toast.promise(
            API.uploadTestimonialFile(tResult.result, "video", videoToSave),
            {
              pending: "Uploading Video ...",
              success: "Success",
              error: {
                render({ data }) {
                  return `${data.message}`;
                },
              },
            }
        );
      }
      if (thumbnailToSave) {
        await toast.promise(
            API.uploadTestimonialFile(tResult.result, "thumbnail", thumbnailToSave),
            {
              pending: "Uploading Thumbnail...",
              success: "Success",
              error: {
                render({ data }) {
                  return `${data.message}`;
                },
              },
            }
        );
      }

      // setTestimonialData(tResult.result);
    } else if (editable) {
      const formDetails = getValues();
      const formData = new FormData();
      const result= {
        user: user,
        formData: formDetails,
        title,
        _id:form?._id,
      }

      // Appending the resultant object into a formData.
      for (const mainKey in result) {
        if (mainKey === "user"){
          for (let userKey in result[mainKey]) {
            formData.append(`user[${userKey}]`, result[mainKey][userKey]);
          }
        }else if (mainKey === "formData"){
          for (let formDataKey in result[mainKey]) {
            if (formDataKey === "logo"){
              if (typeof result[mainKey][formDataKey] !== "string"){
                formData.append(`formData[${formDataKey}]`, result[mainKey][formDataKey]);
              }
            }else{
              for (let innerFormDataKey in result[mainKey][formDataKey]) {
                formData.append(`formData[${formDataKey}][${innerFormDataKey}]`, result[mainKey][formDataKey][innerFormDataKey]);
              }
            }
          }
        }else{
          if (result[mainKey]){
            formData.append(mainKey, result[mainKey]);
          }
        }
      }
      const fResult = await toast.promise(
          API.saveForm(formData),
          {
            pending: "Saving Form...",
            success: "Success",
            error: {
              render({ data }) {
                return `${data.message}`;
              },
            },
          }
      );

      if (goBack) navigate("/forms");
      else setForm(fResult.result);
    }
  }

  const discard = () => {
    navigate("/forms");
  }

  const handleCreateFormFlowClick = async (itemNumber, items, setItems, saveTheData, isValidation) => {
    try {
      if (!!isValidation){
        // console.log(validationFields,"validationFields")
        const noError = await trigger()
        if (!noError){
          return;
        }
      }
      if (saveTheData) {
        await saveData(!testimonial);
      }
      if (itemNumber <= 3){
        handleCreateFormFlow(itemNumber, items, setItems);
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  React.useEffect(() => {
    if (!user && editable) navigate("/login");
  }, [isAuthenticated, navigate, user]);



  return (
    <div className="h-screen flex flex-col">
      {editable && windowWidth < 1024 && (
          <FormSidebar items={sidebarItems} setItems={setSidebarItems} setRightSidebarOpen={setRightSidebarOpen}/>
      )}
      {status === "loading" ? (
        <>
          <Spinner />
          Loading ...{" "}
        </>
      ) : status === "loaded" ? (
          <>
            {editable && (
                <NewFormHeader
                    title={title}
                    setTitle={setTitle}
                    saveForm={saveData}
                    discardForm={discard}
                />
            )}
            <div className="flex flex-row flex-1 overflow-hidden">
              {editable && windowWidth >= 1024 && (
                  <FormSidebar items={sidebarItems} setItems={setSidebarItems} setRightSidebarOpen={setRightSidebarOpen}/>
              )}
              {sidebarItems.map((item, index) => {
                const FormComponent = formComponents[index];
                return (
                    item.current &&
                    FormComponent && (
                        <EditableFormTraverseContext.Provider key={index} value={{
                          handleCreateFormFlow:handleCreateFormFlowClick,
                          items:sidebarItems,
                          setItems:setSidebarItems,
                          currentItem: index,
                          editable
                        }}>
                          <FormComponent
                              editable={editable}
                              items={sidebarItems}
                              setItems={setSidebarItems}
                              cssClasses={cssClasses}
                              // testimonialData={testimonialData}
                              // setTestimonialData={setTestimonialData}
                              handleCreateFormFlow={handleCreateFormFlowClick}
                              formMethods={methods}
                              testimonialMethods={testimonialMethods}
                              isTestimonial={testimonial}
                              setRightSidebarOpen={setRightSidebarOpen}
                              rightSidebarOpen={rightSidebarOpen}
                          />
                        </EditableFormTraverseContext.Provider>
                    )
                );
              })}
            </div>
          </>
      ) : (
          <>{status}</>
      )}
      {!editable && <NavLink to="/register"
                className="animate-bounce duration-150 absolute right-6 bottom-6 py-1.5 px-2 bg-[#0C111D] flex items-center gap-1.5 rounded-md">
        <img src="/img/logoIcon.svg" alt="Logo Icon"/>
        <p className="text-white text-xs font-medium">Collect testimonial with Repsquare</p>
        <img src="/img/icons/arrow-up-right.svg" alt="Arrow Up Right"/>
      </NavLink>}
    </div>
  );
}
