// Background TYPES

export const COLOR_BACKGROUND = "color";
export const IMAGE_BACKGROUND = "image";

// Toggle

export const AVATAR = "avatar";
export const RATING = "rating";

// COLOR TYPE SOLID

export const SOLID = "solid";

// Images

export const FRAME_0 = "uploads/backgrounds/images/frame-0.png";

export const dimensions = ["1080"];

export const AUTH_SOURCE = Object.freeze({
    SELF: "self",
    GOOGLE: "google"
})

export const CREATE_FORM_TESTIMONIAL_TYPE = Object.freeze({
    TEXT: "text",
    VIDEO: "video"
})