import React, { useEffect, useState } from "react";
import {NavLink, useParams} from "react-router-dom";

import { toast } from "react-toastify";
import API from "API";
import StarRating from "components/StarRating";
import Avatar from "components/Avatar";
import VideoPlaceholder from "components/videoPlaceholder";
import dayjs from "dayjs";
import PageLoader from "../components/common/loaders/page-loader";

export default function ViewTestimonial() {
  const [testimonial, setTestimonial] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [error, setError] = useState(null);
  const getTestimonial = async () => {
    try {
      setLoading(true);
      const res = await API.loadTestimonial({ _id: id });
      setLoading(false);
      setTestimonial(res.result);
    } catch (err) {
      setLoading(false);
      setError(err.message);
      toast.error(err.message);
      console.log(err);
    }
  };
  useEffect(() => {
    getTestimonial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const cssClasses = {
    centerContent:
      "flex-grow p-8  flex items-center justify-center pt-16 overflow-hidden",
  };

  if (loading) return <PageLoader />;

  return (
    <div className="h-screen flex flex-col bg-[#F9F5FF] overflow-hidden">
      <div className="flex flex-row flex-1 overflow-hidden">
        <div className={cssClasses.centerContent}>
          <div className="w-full max-w-md ml-6 sm:ml-16 relative z-10">
            <div className="text-start flex flex-col items-center">
              <div className="bg-white md:max-w-sm  sm:max-w-sm  max-w-xs rounded-xl p-8 shadow-xl shadow-gray-200 flex flex-col gap-6 w-full mt-8 text-start items-start">
                <div className="flex-auto flex-row  flex algin-middle ">
                  {error && <div className="text-red-500">{error}</div>}
                  <div className="h-14 w-14 ">
                    <Avatar imageSrc={testimonial?.image} />{" "}
                  </div>
                  <div className="ml-5 mt-2">
                    <div className="flex align-middle   md:flex-row flex-col">
                      <h1 className=" text:xl font-semibold font-inter">
                        {testimonial?.name}
                      </h1>
                    </div>
                    <p className="md:text-md  text-xs font-inter text-gray-600">
                      {testimonial?.email}
                    </p>
                  </div>
                </div>
                <StarRating
                  rating={testimonial?.stars}
                  interactive={true}
                  starClass="w-7 h-7"
                />
                {testimonial?.type.toLowerCase() === "video" && (
                  <>
                    <p>{testimonial?.transcript}</p>
                    {testimonial?.video && (
                      <VideoPlaceholder
                        video={{
                          file: `/${testimonial?.video}`,
                          // image: testimonial?.allthumbnails?.find(
                          //   (e) => e.isSelected
                          // )?.url,
                          image: testimonial?.thumbnail,
                        }}
                      />
                    )}
                  </>
                )}
                {testimonial?.type.toLowerCase() === "text" && (
                  <>
                    <p>
                      {testimonial?.testimonial ||
                        "Please leave a testimonial to help me grow my business 🙂"}
                    </p>
                    {testimonial?.thumbnail && (
                      <img
                        src={"/" + testimonial.thumbnail}
                        alt=""
                        className="rounded-md shadow-lg"
                      />
                    )}
                  </>
                )}
                <p className="text-gray-400 text-xs">
                  {dayjs(testimonial.date).format("ll")}
                </p>
              </div>
            </div>
          </div>
          <NavLink to="/register"
                   className="animate-bounce duration-150 absolute right-6 bottom-6 py-1.5 px-2 bg-[#0C111D] flex items-center gap-1.5 rounded-md">
            <img src="/img/logoIcon.svg" alt="Logo Icon"/>
            <p className="text-white text-xs font-medium">Collect testimonial with Repsquare</p>
            <img src="/img/icons/arrow-up-right.svg" alt="Arrow Up Right"/>
          </NavLink>
          <div className="absolute w-[100vw] h-[50vh] top-0">
            <img
              src="/img/testimonail-view-bg.png"
              className="w-full h-full object-cover"
              alt="bg"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
