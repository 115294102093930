import classNames from "classnames";
import React, { useState } from "react";

const ButtonGroup = ({ btnData = [] }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="max-w-lg">
      <div
        className="flex items-center gap-1 rounded-xl border border-gray-200 bg-gray-100 p-1.5"
        role="group"
      >
        {
          // Loop through the data and display the buttons
          btnData.map((btn, index) => {
            const isActive = activeIndex === index;
            const buttonClass = classNames(
              "text-sm lg:text-base font-medium px-3 py-2 text-gray-500",
              {
                "rounded-md min-w-14 shadow-sm bg-white text-md font-semibold text-gray-700":
                  isActive,
              }
            );

            return (
              <button
                key={index}
                type="button"
                className={buttonClass}
                onClick={() => {
                  btn?.onClick();
                  setActiveIndex(index);
                }}
              >
                {btn?.text}
              </button>
            );
          })
        }
      </div>
    </div>
  );
};

export default ButtonGroup;
