import classNames from "classnames";

const TestimonialText = ({ classes }) => {
  return (
    <p
      className={`${classNames(classes, "break-words")}`}
      id="testimonial_text"
    >
      Asad knows how to provide value, I found him way back through a group
      recommendation and has been inspired by the way he teaches.
    </p>
  );
};

export default TestimonialText;
