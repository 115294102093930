// InputField.js
export default function InputField({
  id,
  label,
  placeholder,
  size = "md",
  type,
  autoComplete,
  icon: Icon,
  required,
  className,
  value,
  onChange,
  onClick,
  register,
  validate,
}) {
  return (
    <div className="w-full text-left">
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-medium leading-6 text-gray-900 mb-2"
        >
          {label}
        </label>
      )}
      <div className="relative">
        {Icon && <Icon className="w-5 absolute left-3 h-full text-gray-600" />}
        <input
          id={id}
          placeholder={placeholder}
          type={type}
          autoComplete={autoComplete}
          required={required}
          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${className} ${
            Icon && "pl-10"
          } ${size === "lg" && "py-3"}`}
          value={value}
          defaultValue={value}
          onChange={onChange}
          onClick={onClick}
          {...(register && register(id, { validate: validate }))}
        />
      </div>
    </div>
  );
}
