export default function Logo({
    className,
}) {
    return (
        <img
            className={`w-36 h-8 ${className}`}
            src="/img/logo.svg"
            alt="Your Company"
        />
    );
}

