import React from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../layouts/Sidebar';

import AccountSettings from './Profile/AccountSettings';
import { useSelector } from 'react-redux';

export default function Profile() {
    const { isAuthenticated } = useSelector((state) => state);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!isAuthenticated) navigate('/login');
    }, [isAuthenticated, navigate]);

    // const [tabs, setTabs] = useState([
    //     {
    //         name: 'Account settings',
    //         href: '#',
    //         component: <AccountSettings />,
    //         current: true,
    //     },
    //     {
    //         name: 'Billing',
    //         href: '#',
    //         component: <BillingSection />,
    //         current: false,
    //     },
    // ]);

    // function classNames(...classes) {
    //     return classes.filter(Boolean).join(' ');
    // }
    //
    // const handleTabClick = (clickedTabName) => {
    //     const newTabs = tabs.map((tab) => ({
    //         ...tab,
    //         current: tab.name === clickedTabName,
    //     }));
    //     setTabs(newTabs);
    // };

    // const activeTab = tabs.find((tab) => tab.current);

    return (
        <div className="h-screen flex flex-col lg:flex-row">
            <Sidebar />
            <div className="flex-grow p-8 overflow-auto">
                <h1 className="text-3xl font-semibold flex items-baseline gap-2">
                    Profile
                </h1>
                {/*<nav*/}
                {/*    className="-mb-px flex space-x-8 mt-8 border-b"*/}
                {/*    aria-label="Tabs"*/}
                {/*>*/}
                {/*    {tabs.map((tab) => (*/}
                {/*        <a*/}
                {/*            key={tab.name}*/}
                {/*            href={tab.href}*/}
                {/*            onClick={() => handleTabClick(tab.name)}*/}
                {/*            className={classNames(*/}
                {/*                tab.current*/}
                {/*                    ? 'border-indigo-500 text-indigo-600'*/}
                {/*                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',*/}
                {/*                'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'*/}
                {/*            )}*/}
                {/*            aria-current={tab.current ? 'page' : undefined}*/}
                {/*        >*/}
                {/*            {tab.name}*/}
                {/*        </a>*/}
                {/*    ))}*/}
                {/*</nav>*/}
                {/*<div className="py-10">{activeTab && activeTab.component}</div>*/}
                <div className="py-10">
                    <AccountSettings />
                </div>
            </div>
        </div>
    );
}
