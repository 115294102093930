import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import FormSidebar from "../layouts/FormSidebar";
import {
  ChatBubbleBottomCenterTextIcon,
  PaintBrushIcon,
} from "@heroicons/react/24/outline";

import NewFormHeader from "../components/create-form/NewFormHeader.js";
import WelcomeForm from "../components/create-form/WelcomeForm";
import TestimonialForm from "../components/create-form/testimonial-form";
import TextForm from "../components/create-form/TextForm";
import DetailsForm from "../components/create-form/DetailsForm";
import ThanksForm from "../components/create-form/ThanksForm";
import Spinner from "components/Spinner";
import TestimonialsTable from "components/TestimonialsTable";

export default function WallOfLove({ editable = true, testimonial = false }) {
  const { state } = useLocation();
  const [status, setStatus] = useState("loading");
  const [form, setForm] = useState(state ? state.form : null);

  const initialItems = [
    {
      name: "Select testimonials",
      icon: ChatBubbleBottomCenterTextIcon,
      current: true,
    },
    {
      name: "Design",
      icon: PaintBrushIcon,
      current: false,
    },
    // ... other items ...
  ];

  const [sidebarItems, setSidebarItems] = useState(initialItems);
  const [testimonialData, setTestimonialData] = useState({});
  const [title, setTitle] = useState(form ? form.title : "Untitled Widget");

  const cssClasses = {
    centerContent:
      "flex-grow p-8 bg-indigo-50 flex items-start justify-center pt-16 overflow-auto",
    card: "bg-white max-w-md rounded-3xl p-12 shadow-xl shadow-gray-200 items-center text-center flex flex-col gap-6 w-96 relative",
    sidebar:
      "z-50 w-80 h-full flex-none flex flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 py-8 overflow-auto",
  };

  const widgetComponents = [TestimonialsTable];

  return (
    <div className="h-screen flex flex-col">
      {editable && <NewFormHeader title={title} setTitle={setTitle} />}
      <div className="flex flex-row flex-1 overflow-hidden">
        {editable && (
          <FormSidebar items={sidebarItems} setItems={setSidebarItems} />
        )}
        {sidebarItems.map((item, index) => {
          const WidgetComponent = widgetComponents[index];
          return (
            item.current &&
            WidgetComponent && (
              <WidgetComponent
                key={index}
                items={sidebarItems}
                setItems={setSidebarItems}
                cssClasses={cssClasses}
              />
            )
          );
        })}
      </div>
    </div>
  );
}
