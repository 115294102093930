import {FormProvider} from "../common/RHF";
import RHFInputField from "../common/RHF/RHFTextField";
import {useNavigate} from "react-router-dom";
import Spinner from "../Spinner";
import Button from "../Button";
import React from "react";
import {useForm} from "react-hook-form";
import {useSelector} from "react-redux";

export default function ForgotPasswordForm() {
    const methods = useForm();
    const {  handleSubmit } = methods;
    const { status } = useSelector((state) => state);
    const navigate = useNavigate();
    const submitForm = () => {
        navigate("/reset-password")
    };
    return(
        <FormProvider methods={methods} className="space-y-6" onSubmit={handleSubmit(submitForm)}>
            <RHFInputField
                name="email"
                label="Email address"
                type="email"
                placeholder="Enter your email address"
                autoComplete="email"
                required
            />

            <div>
                <Button size="lg" variant="primary" width={"full"} type="submit" isLoading={status === "loading"}>
                    Send Code To Email
                </Button>
            </div>
        </FormProvider>
    )
}