export default function Checkbox() {
    return (
        <input
            id="comments"
            aria-describedby="comments-description"
            name="comments"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0 cursor-pointer"
        />
    );
}
