import MDEditor from "@uiw/react-md-editor";
import React from "react";

export default function MdTextPreview({value, className}) {
    return(
        <MDEditor.Markdown
            source={value}
            className={`[&_p]:!text-gray-700 [&_p]:!text-sm [&_p]:lg:!text-base whitespace-pre-wrap  w-full text-left !font-inter ${className || ""}`}
            wrapperElement={{
                "data-color-mode":"light"
            }}
        />
    )
}