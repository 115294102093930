import Badge from "../components/Badge";
import StarRating from "../components/StarRating";
import VideoPlaceholder from "../components/videoPlaceholder";
import StatusDropdown from "./StatusDropdown";
import dayjs from "dayjs";
import {
  ArrowDownTrayIcon,
  CodeBracketIcon,
  LinkIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import IconButton from "./IconButton";
import Avatar from "./Avatar";
import TestimonialStatusBadge from "./Testimonial/TestimonialStatusBadge";
import { fToNow } from "utils/dateTimeFormat";
import { toast } from "react-toastify";
import { videoDownload } from "utils/utils";
import RHFCheckbox from "./common/RHF/RHFCheckbox";

dayjs.extend(require("dayjs/plugin/localizedFormat"));

export default function TestimonialRow({ item, updateStatus, handleOpen }) {
  if (item.type.toLowerCase() === "video") {
    return (
      <tr key={item.email} valign="top">
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          <RHFCheckbox name={`checkbox.${item._id}`} />
        </td>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          <div className="flex-auto flex-row  flex algin-middle ">
            <div className="h-10 w-10 ">
              <Avatar imageSrc={item?.image} />{" "}
            </div>
              <div className="ml-5">
                  <div className="flex align-middle  md:flex-row flex-col">
                      <h1 className=" text:lg  font-inter">{item?.name}</h1>
                  </div>
                  <p className="  text-xs font-inter text-gray-600">
                      {item?.email}
                  </p>
                  <p className="  text-xs font-inter text-gray-600">
                      {item?.position}
                  </p>
              </div>
          </div>
        </td>
          <td className="px-3 py-4 text-sm text-gray-500">
              <div className="max-w-[180px] gap-2 flex flex-col">
                {/*{item.video || item.star || item.transcript && (*/}
                {/*  <>*/}
                {/*    */}
                {/*  </>*/}
                {/*)}*/}
                  {item.video && (
                      <VideoPlaceholder
                          video={{
                              file: item.video,
                              image: item.allthumbnails.length !== 0
                                ? item.allthumbnails.find((e) => e.isSelected)?.url
                                : "img/videoplaceholder.png",
                                  // item.thumbnail
                                  //     ?  item.thumbnail
                                  //     : "img/videoplaceholder.png",
                                  //
                          }}
                      />
                  )}
                {item.status.toLowerCase() === "pending" && <Badge text="Pending response" type={"info"} />}
                <StarRating rating={item.stars} />
                <div className="truncate">{item.testimonial}</div>
              </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
          {item.status.toLowerCase() === "pending" ? (
            <>
              invited <b>{fToNow(item.date)}</b>
            </>
          ) : (
            dayjs(item.date).format("ll")
          )}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 pr-10">
          {item.status.toLowerCase() === "pending" ? (
            <TestimonialStatusBadge status={item.status} />
          ) : (
            <StatusDropdown type={item.status} itemSelected={updateStatus}>
              <TestimonialStatusBadge status={item.status} />
            </StatusDropdown>
          )}

          {item?.category.toLowerCase() === "invited" && (
            <span className="inline-flex justify-center items-center rounded-full md:my-auto mx-0 my-1 bg-gray-50 px-5 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 font-inter">
              Invited
            </span>
          )}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 pr-10">
          <button title="Edit Testimonial" type="button" onClick={() => handleOpen(item._id)}>
            <IconButton buttonType="button" icon={PencilIcon} />
          </button>
          <button
              type="button"
              title="Copy Link"
              onClick={() => {
              // copy link to clipboard
              navigator.clipboard.writeText(
                window.location.host + "/testimonial/view/" + item._id
              );
              toast.success("Link copied to clipboard");
            }}
          >
            <IconButton buttonType="button" icon={LinkIcon} />
          </button>
          <button
              type="button"
              title="Copy Embed Code"
              onClick={() => {
              navigator.clipboard.writeText(
                `<iframe src="${window.location.protocol}//${window.location.host}/testimonial/embed/${item._id}" width="400" height="550" frameborder="0"
          allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>`
              );
              toast.success("Embed code copied to clipboard");
            }}
          >
            <IconButton buttonType="button" icon={CodeBracketIcon} />
          </button>
          {item.video && (
            <button
                type="button"
                title="Download Testimonial Video"
                onClick={() =>
                videoDownload(
                  item.video,
                  item.name + `${item.video.split(".").pop()}`
                )
              }
            >
              <IconButton buttonType="button" icon={ArrowDownTrayIcon} />
            </button>
          )}
        </td>
      </tr>
    );
  }

  return (
    <tr key={item.email} valign="top">
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <RHFCheckbox name={`checkbox.${item._id}`}/>
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <div className="flex-auto flex-row  flex algin-middle ">
          <div className="h-10 w-10 ">
            <Avatar imageSrc={item?.image} />{" "}
          </div>
          <div className="ml-5">
            <div className="flex align-middle  md:flex-row flex-col">
              <h1 className=" text:lg  font-inter">{item?.name}</h1>
            </div>
            <p className="  text-xs font-inter text-gray-600">{item?.email}</p>
          </div>
        </div>
      </td>
      <td className="px-3 py-4 text-sm text-gray-500">
        <div className="max-w-xl gap-2 flex flex-col">
          {item.testimonial || item.stars ? (
            <>
              <StarRating rating={item.stars} />
              <div>{item.testimonial}</div>
            </>
          ) : (
            <Badge text="Pending response" type={"info"} />
          )}
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {item.status.toLowerCase() === "pending" ? (
          <>
            invited <b>{fToNow(item.date)}</b>
          </>
        ) : (
          dayjs(item.date).format("ll")
        )}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 pr-10">
        {item.status.toLowerCase() === "pending" ? (
          <TestimonialStatusBadge status={item.status} />
        ) : (
          <StatusDropdown type={item.status} itemSelected={updateStatus}>
            <TestimonialStatusBadge status={item.status} />
          </StatusDropdown>
        )}

        {item?.category.toLowerCase() === "invited" && (
          <span className="inline-flex justify-center items-center rounded-full md:my-auto mx-0 my-1 bg-gray-50 px-5 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 font-inter">
            Invited
          </span>
        )}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 pr-10">
        <button type="button"  title="Edit Testimonial" onClick={() => handleOpen(item._id)}>
          <IconButton buttonType="button" icon={PencilIcon} />
        </button>
        <button
            type="button"
            title="Copy Link"
            onClick={() => {
            // copy link to clipboard
            navigator.clipboard.writeText(
              window.location.host + "/testimonial/view/" + item._id
            );
            toast.success("Link copied to clipboard");
          }}
        >
          <IconButton buttonType="button" icon={LinkIcon} />
        </button>
        <button
            type="button"
            title="Copy Embed Code"
            onClick={() => {
            navigator.clipboard.writeText(
              `<iframe src="${window.location.protocol}//${window.location.host}/testimonial/embed/${item._id}" width="400" height="550" frameborder="0"
          allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>`
            );
            toast.success("Embed code copied to clipboard");
          }}
        >
          <IconButton buttonType="button" icon={CodeBracketIcon} />
        </button>
      </td>
    </tr>
  );
}
