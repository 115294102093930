import classNames from "classnames";

const TestimonialAuthor = ({ name, label, classes, innerclasses }) => {
  return (
    <div>
      <p className={classNames(classes, "break-words")} id="author_name">
        {name}
      </p>
      <small className={`${innerclasses} break-words`} id="author_label">
        {label}
      </small>
    </div>
  );
};

export default TestimonialAuthor;
