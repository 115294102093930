import {useController, useFormContext} from "react-hook-form";
import React from "react";

export default function RHFCheckbox({ label, rules, name, className, disabled }) {
    const { control } = useFormContext();
    const {
        field: { value, onChange },
        fieldState: { error },
    } = useController({ name, control, rules });
    return (
        <div className={`flex items-center ${disabled ? "cursor-not-allowed" : ""} ${className || ""}`}>
            <input
                id={name}
                name={name}
                disabled={disabled}
                onChange={e => onChange(e.target.checked)}
                checked={value}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600"
            />
            <label
                htmlFor={name}
                className="ml-3 block text-sm leading-6 text-gray-900"
            >
                {label}
            </label>
            {error && (
                <p id={`${name}-error`} className="text-red-500 text-xs mt-1">
                    {error.message}
                </p>
            )}
        </div>
    );
}
